const voltageOptions = [
  {
    value: "110",
    label: "110v",
  },
  {
    value: "220",
    label: "220v",
  },
  {
    value: "380",
    label: "380v",
  },
  {
    value: "bivolt",
    label: "Bivolt",
  },
  {
    value: "unknown",
    label: "Indefinido",
  },
];

export default voltageOptions;
