import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import firebase from "config/firebase";
import { useHistory } from "react-router-dom";

const NoRole = () => {
  const history = useHistory();
  useEffect(() => {}, [firebase.auth().signOut()]);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="12" lg="5">
            <Card className="p-4">
              <CardBody>
                <p>Você ainda não possui privilégios para acessar o Ecoblu.</p>
                <p className="text-muted">
                  Entre em contato com seu administrador
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              color="link"
              className="px-0"
              onClick={() => {
                history.push("/login");
              }}
            >
              voltar
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoRole;
