import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

interface ItemDeleteModalPropsInterface {
  closeModal: any;
  itemToBeDeleted: any;
  deleteItem: any;
}

const ItemDeleteModal = (props: ItemDeleteModalPropsInterface) => {
  const { closeModal, itemToBeDeleted, deleteItem } = props;

  const productName = () => {
    if (itemToBeDeleted) {
      const { product } = itemToBeDeleted;
      return `${product.brand.name}, ${product.name} - ${product.info} ${
        product.unitOfMeasurement.name
      }
      ${itemToBeDeleted.totalUnits} unidade${
        itemToBeDeleted.totalUnits > 1 ? "s" : ""
      }`;
    }
    return "";
  };

  return (
    <Modal isOpen={itemToBeDeleted ? true : false} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Aviso</ModalHeader>
      <ModalBody>{`Deseja excluir o item "${productName()}"?`}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteItem(itemToBeDeleted.id);
            closeModal();
          }}
        >
          Sim
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ItemDeleteModal;
