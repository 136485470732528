import React from "react";
import { Table } from "reactstrap";
import idx from "idx";
import "./style.css";

const PrintPLDeliveryItems = ({ pawn, items, relatedProducts, values }) => {
  const formatItems = () => {
    let formattedItems = [];
    if (items) {
      for (let [key, value] of Object.entries(items)) {
        if (pawn.pawnItemsQuantity[value.id] > 0) formattedItems.push(value);
      }
    }
    return formattedItems;
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      if (values?.packingListItemsQuantity[row.id] > 0) {
        rows.push(
          <tr className="print-table-text">
            <td title="Modelo">
              {idx(relatedProducts, (_) => _[row.product.id].name)}
            </td>
            <td title="Marca">
              {idx(relatedProducts, (_) => _[row.product.id].brand?.name)}
            </td>
            <td title="Capacidade">
              {idx(relatedProducts, (_) => _[row.product.id].info)}
            </td>
            <td title="Unidade">
              {idx(
                relatedProducts,
                (_) => _[row.product.id].unitOfMeasurement?.name
              )}
            </td>
            <td title="Instalado?">{row?.installed?.label}</td>
            <td title="Voltagem">{row?.voltage?.label}</td>
            <td title="Quantidade">
              {values?.packingListItemsQuantity[row.id]}
            </td>
          </tr>
        );
      }
    });
    return rows;
  };

  return (
    <>
      <Table bordered className="print-text my-0 d-none d-print-table">
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Capacidade</th>
            <th>Unidade</th>
            <th>Instalado?</th>
            <th>Voltagem</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
    </>
  );
};

export default PrintPLDeliveryItems;
