import React from "react";
import { CustomReactSelect } from "components";
import { Col, Row, ButtonToggle } from "reactstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
// import { tradingTypes } from "utils/general";

const Filters = (props: any) => {
  const {
    company,
    setCompany,
    companiesOptions,
    brand,
    setBrand,
    brandsOptions,
    product,
    setProduct,
    productsOptions,
    voltage,
    setVoltage,
    voltagesOptions,
    tradingTypesOptions,
    setTradingType,
    tradingType,
  } = props;

  return (
    <div className="mt-4">
      <Row>

        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Empresa"
            field="company"
            value={company}
            onChange={(field: any, newValue: any) => {
              setCompany(newValue);
            }}
            options={companiesOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Marca"
            field="brand"
            value={brand}
            onChange={(field: any, newValue: any) => {
              setBrand(newValue);
            }}
            options={brandsOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Produto"
            field="product"
            value={product}
            onChange={(field: any, newValue: any) => {
              setProduct(newValue);
            }}
            options={productsOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Tipo Licitação"
            field="tradingtype"
            value={tradingType}
            onChange={(field: any, newValue: any) => {
              setTradingType(newValue);
            }}
            options={tradingTypesOptions}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md="6" className="mb-3">
        <BootstrapSwitchButton
    checked={false}
    onlabel='Admin User'
    offlabel='Regular User'
    onChange={(checked: boolean) => {
    console.log(checked);
    }}
/>
        </Col>
      </Row> */}
    </div>
  );
};

export default Filters;
