import React, { useState, useEffect, Fragment } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useGetPawns } from "hooks";
import PawnListTable from "./PawnListTable";
import PawnListFilters from "./PawnListFilters";
import { useSelector } from "react-redux";

import dispatch, { FilterActions } from "actions";

import moment from "moment";
import "moment/locale/pt-br";

interface PawnProps {
  auction: {
    company: {
      name: string;
    };
    governmentAgency: {
      name: string;
      state: {
        value: string;
        name: string;
      };
      city: string;
    };
    number: number;
  }
  deliveryStatus: number;
}

const PawnList = () => {
  moment.locale("pt-br");
  const [count, setCount] = useState(0);
  const [checkedButton, setCheckedButton] = useState<boolean>(false);

  const statusValue = (status: string) => {
    if (status === "Pendente/Parcial") return 0;
    if (status === "Concluído") return 2;
    if (status === "Todos") return 3;
  };

  const [filteredPawns, setFilteredPawns] = useState<any>(null);
  const filters = useSelector((state: any) => state.filter.filters);
  const receivedAtDates = useSelector((state: any) => state.filter.receivedAtDates);
  
  const user = useSelector((state: any) => state.user);
  const isRestricted = ["collection", "financial"].includes(user.role);
  
  const switchOptions = ["Pendente/Parcial", "Em trânsito", "Concluído", "Todos"]
  const [optionSelected, setSelected] = useState(switchOptions[0]);
  const { loadingPawns, pawns } = useGetPawns(count, undefined, undefined, undefined, filters?.deliveryStatus?.value);

  const addCount = () => {
    setCount(count + 1);
  };

  const setFilterField = (field: string, value: any) => {
    dispatch(FilterActions.savePawnListFilters, { ...filters, [field]: value })
    if (field === "deliveryStatus" && value.label === switchOptions[0]) setSelected(switchOptions[0])
    if (field === "deliveryStatus" && value.label === switchOptions[1]) setSelected(switchOptions[1])
    if (field === "deliveryStatus" && value.label === switchOptions[2]) setSelected(switchOptions[2])
  };

  const clearFilters = () => {
    setSelected(switchOptions[2])
    dispatch(FilterActions.savePawnListFilters, {})
    dispatch(FilterActions.savePawnListReceivedAtDates, {})
  };

  useEffect(() => {
    if (filters["deliveryStatus"] && filters["deliveryStatus"]?.label !== optionSelected) setSelected(switchOptions[filters["deliveryStatus"].value])
  }, [filters])

  useEffect(() => {
    if (optionSelected === switchOptions[0]) dispatch(FilterActions.savePawnListFilters, { ...filters, ["deliveryStatus"]: { value: 0, label: switchOptions[0] } })
    else if (optionSelected === switchOptions[1]) dispatch(FilterActions.savePawnListFilters, { ...filters, ["deliveryStatus"]: { value: 3, label: switchOptions[1] } })
    else if (optionSelected === switchOptions[2]) dispatch(FilterActions.savePawnListFilters, { ...filters, ["deliveryStatus"]: { value: 2, label: switchOptions[2] } })
    else if (optionSelected === switchOptions[3]) {
      let new_filters = filters
      new_filters["deliveryStatus"] = { value: '', label: 'Todos' };

      dispatch(FilterActions.savePawnListFilters, { ...new_filters })
    }
  }, [optionSelected])

  useEffect(() => {
    let isFiltered: boolean = false;
    let filteredPawns = [...pawns];

    for (const key in filters) {
      isFiltered = true;
      const filterValue = filters[key].value;

      switch (key) {
        case "company":
          filteredPawns = filteredPawns.filter(
            (pawn: PawnProps) => pawn.auction.company.name === filterValue
          );
          break;

        case "auction":
          filteredPawns = filteredPawns.filter(
            (pawn: PawnProps) => pawn.auction.number === filterValue
          );
          break;

        case "governmentAgency":
          filteredPawns = filteredPawns.filter(
            (pawn: PawnProps) => pawn.auction.governmentAgency.name === filterValue
          );
          break;

        case "state":
          filteredPawns = filteredPawns.filter(
            (pawn: PawnProps) => pawn.auction.governmentAgency.state.value === filterValue)
          break;

        case "city":
          filteredPawns = filteredPawns.filter(
            (pawn: PawnProps) => pawn.auction.governmentAgency.city === filterValue
          );
          break;

        case "deliveryStatus":
          if (filterValue === 0 || filterValue === 1) {
            filteredPawns = filteredPawns.filter(
              (pawn: PawnProps) => pawn.deliveryStatus === 0 || pawn.deliveryStatus === 1
            );
          } else if (filterValue === 2) {
            filteredPawns = filteredPawns.filter(
              (pawn: PawnProps) => pawn.deliveryStatus === 2
            );
          } else if (filterValue === 3) {
            filteredPawns = filteredPawns.filter(
              (pawn: PawnProps) => pawn.deliveryStatus === 3
            );
          } else {
            filteredPawns = filteredPawns;
          }
          break;

        default:
          filteredPawns = filteredPawns.filter(
            (pawn: any) => pawn[key] === filterValue
          );
          break;
      }
    }
    if (receivedAtDates.startDate && receivedAtDates.endDate) {
      isFiltered = true;
      const start = moment(receivedAtDates.startDate)
        .startOf("day")
        .toDate();
      const end = moment(receivedAtDates.endDate)
        .endOf("day")
        .toDate();

      filteredPawns = filteredPawns.filter((pawn: any) => {
        const date = moment(pawn.receivedAt).toDate();
        return date >= start && date <= end;
      });
    }

    setFilteredPawns(isFiltered ? filteredPawns : null);
  }, [filters, pawns, receivedAtDates]);

  return (
    <Fragment>
      <div className="animated">
        <Card>
          <CardHeader>Empenhos</CardHeader>
          <CardBody>
            <PawnListTable
              loadingPawns={loadingPawns}
              pawns={filteredPawns ? filteredPawns : pawns}
              addCount={addCount}
              isRestricted={isRestricted}
            >
              <PawnListFilters
                pawns={pawns}
                filters={filters}
                setFilterField={setFilterField}

                switchOptions={switchOptions}
                optionSelected={optionSelected}
                handleChangeSelected={(option: any) => setSelected(option)}

                clearFilters={clearFilters}
                receivedAtDates={receivedAtDates}
                setReceivedAtDates={(dates: any) => dispatch(FilterActions.savePawnListReceivedAtDates, dates)}
              />
            </PawnListTable>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default PawnList;