import styled from 'styled-components';

export const Switch = styled.div`
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  position: relative;
  height: 35px;
  width: 440px;
  background-color: #f0f3f5;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-around;
`;

export const SwitchRadio = styled.input`
  display: none;
`;

export const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 120px;
  height: 35px;
  background: ${props => props.color};
  border-radius: 10px;
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`
  position: relative;
  z-index: 2;
  float: left;
  width: 110px;
  line-height: 35px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  white-space: nowrap;
  padding-left: 2px;
  text-align: center;

  ${SwitchRadio}:checked + &{
    transition: 0.15s ease-out;
    color: ${props => props.color};
  }
`;