import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import firebase from "config/firebase";
import { notify } from "services";
import { useHistory } from "react-router-dom";
import { CenteredSpinner } from "components";

const SignUp = () => {
  const history = useHistory();
  const db = firebase.firestore();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const user = firebase.auth().currentUser;

  const save = () => {
    !user && validateSignUp();
    user && validadeName();
  };

  const validadeName = () => {
    if (!name) {
      notify({
        description: "Por favor insira seu nome.",
        type: "danger",
      });
      return;
    }
    saveUser(user.uid, user.email);
  };

  const validateSignUp = () => {
    if (!name) {
      notify({
        description: "Por favor insira seu nome.",
        type: "danger",
      });
      return;
    }
    if (!email) {
      notify({
        description: "Por favor insira seu e-mail.",
        type: "danger",
      });
      return;
    }
    if (!password) {
      notify({
        description: "Por favor insira sua senha.",
        type: "danger",
      });
      return;
    }
    if (!passwordConfirmation) {
      notify({
        description: "Por favor insira sua confirmação de senha.",
        type: "danger",
      });
      return;
    }
    if (password != passwordConfirmation) {
      notify({
        description: "A senha não é igual à confirmação de senha.",
        type: "danger",
      });
      return;
    }

    signUp();
  };

  const signUp = (_) => {
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        setLoading(false);
        const { uid, email } = user.user;
        saveUser(uid, email);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage;
        switch (error.code) {
          case "auth/email-already-in-use":
            errorMessage =
              "E-mail já cadastrado. Por favor registre outro email ou tente recuperar sua senha.";
            break;

          case "auth/invalid-email":
            errorMessage = "E-mail inválido, tente novamente.";
            break;

          case "auth/weak-password":
            errorMessage =
              "Esta senha é muito fraca. Insira pelo menos 6 caracteres.";
            break;

          default:
            errorMessage = "Erro ao registrar. Por favor tente novamente.";
        }

        notify({
          description: errorMessage,
          type: "danger",
        });
      });
  };

  const saveUser = (uid, email) => {
    setLoading(true);
    const user = {
      name,
      email,
      uid,
      role: "",
      status: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    db.collection("users")
      .doc(uid)
      .set(user)
      .then(() => {
        setLoading(false);
        history.push("/nenhum-privilegio");
      })
      .catch((error) => {
        setLoading(false);
        console.log("erro: ", error);
        notify({
          description:
            "Não foi possível salvar seu usuário. Por favor tente novamente.",
          type: "danger",
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      save();
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="12" lg="5">
            <Card className="p-4">
              <CardBody>
                <Form>
                  <h1>Registro</h1>
                  <p className="text-muted">
                    {user ? "Confirme seu nome" : "Crie sua conta"}
                  </p>
                  <div className="mb-4">
                    <label htmlFor="name" style={{ display: "block" }}>
                      Nome
                    </label>
                    <input
                      id="name"
                      placeholder="Nome"
                      type="text"
                      value={name}
                      onChange={(evt) => {
                        setName(evt.target.value);
                      }}
                    />
                  </div>
                  {!user && (
                    <div>
                      <div className="mb-4">
                        <label htmlFor="email" style={{ display: "block" }}>
                          E-mail
                        </label>
                        <input
                          id="email"
                          placeholder="E-mail"
                          type="email"
                          value={email}
                          onChange={(evt) => {
                            setEmail(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="password" style={{ display: "block" }}>
                          Senha
                        </label>
                        <input
                          id="password"
                          placeholder="Senha"
                          type="password"
                          value={password}
                          onChange={(evt) => {
                            setPassword(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="passwordConfirmation"
                          style={{ display: "block" }}
                        >
                          Confirmação
                        </label>
                        <input
                          id="passwordConfirmation"
                          placeholder="Senha"
                          type="password"
                          value={passwordConfirmation}
                          onChange={(evt) => {
                            setPasswordConfirmation(evt.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  )}
                  <Row>
                    {loading ? (
                      <CenteredSpinner />
                    ) : (
                      <Col xs="12" className="text-right">
                        <Button color="primary" className="px-4" onClick={save}>
                          registrar
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              color="link"
              className="px-0"
              onClick={() => {
                history.goBack();
              }}
            >
              voltar
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
