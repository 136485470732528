import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetAuctions = (props?: any, startDate?: any, endDate?: any, onlyByDate?: boolean, dropdown?: boolean) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [auctions, setAuctions] = useState([] as any);
  const [loadingAuctions, setLoadingAuctions] = useState(false);


  useEffect(() => {
    if (dropdown) {
      db.collection("auctions")
      .where("statusAuction", "in", [0, 1])
      .orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          setAuctions(querySnapshot.docs.map((doc) => doc.data()));

        })
        .catch((error) => {

          console.log("erro: ", error);
        });

      return () => {
        const timeout = setTimeout(() => controller.abort(), 5000);
        clearTimeout(timeout);
      };  
    }
  }, []);

  useEffect(() => {
    if (!onlyByDate) {
      setLoadingAuctions(true);
      db.collection("auctions")
      .orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          setAuctions(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingAuctions(false);
        })
        .catch((error) => {
          setLoadingAuctions(false);
          console.log("erro: ", error);
        });

      return () => {
        const timeout = setTimeout(() => controller.abort(), 5000);
        clearTimeout(timeout);
      };  
    }
  }, [props]);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      const start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
      const end = firebase.firestore.Timestamp.fromDate(new Date(endDate))
      setLoadingAuctions(true);
      db.collection("auctions")
      .orderBy("createdAt", "desc")
        .where("createdAt", ">=", start)
        .where("createdAt", "<=", end)
        .get()
        .then((querySnapshot) => {
          setAuctions(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingAuctions(false);
        })
        .catch((error) => {
          setLoadingAuctions(false);
          console.log("erro: ", error);
        });
    }
  }, [startDate, endDate]);

  return { loadingAuctions, auctions };
};

export default useGetAuctions;
