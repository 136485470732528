import React, { Fragment, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { voltageTranslation } from "utils/general";
import { QuickViewModal } from "components";
import ReportDetails from "./Details/ReportDetails";
import { money } from "utils/mask";
import { promises } from "fs";

const PawnReport = ({ data, filteredAuctionsItems, packingLists, installed }) => {

  const [quickViewPawn, setQuickViewItem] = useState(undefined)

  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell, row) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "voltage",
      text: "Voltagem",
      formatter: (cell, row) => voltageTranslation(cell),
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
    {
      sort: true,
      dataField: "middlePrice",
      text: "Preço Médio",
      formatter: (cell, row) => money(cell),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Romaneios",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setQuickViewItem(row)
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <Fragment>
      <QuickViewModal
        closeModal={() => setQuickViewItem(undefined)}
        isOpen={quickViewPawn}
        title={`Produto`}
        size={"xl"}
      >
        <ReportDetails
          product={quickViewPawn}
          packingLists={packingLists}
          filteredAuctionsItems={filteredAuctionsItems}
          installed={installed}
        />
      </QuickViewModal>
      <BootstrapTable
        keyField={["id","model","brand","info","voltage"]}
        data={data}
        columns={columns}
        bordered={false}
        hover
      />
    </Fragment>
  );
};

export default PawnReport;
