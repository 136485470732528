import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetCompanies = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [companies, setCompanies] = useState([] as any);
  const [companiesCnpj, setCompaniesCnpj] = useState([] as any);
  const [personCPF, setPersonCPF] = useState([] as any);

  const [loadingCompanies, setLoadingCompanies] = useState(false);

  useEffect(() => {
    setLoadingCompanies(true);
    db.collection("companies")
      .get()
      .then((querySnapshot) => {
        const companies = querySnapshot.docs.map((doc) => doc.data());
        setCompanies(companies);
        setCompaniesCnpj(companies.map((company) => company.cnpj));
        setPersonCPF(companies.map((company) => company.cpf))
        setLoadingCompanies(false);
      })
      .catch((error) => {
        setLoadingCompanies(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingCompanies, companies, companiesCnpj, setPersonCPF };
};

export default useGetCompanies;
