import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { voltageTranslation } from "utils/general";
import moment from "moment";
import { CenteredSpinner, QuickViewModal } from "components";
// import PawnDetails from "../../PawnDetails/PawnDetails";
import { useHistory } from "react-router-dom";
import PackingListListDetails from "../../PackingListDetails/PackingListDetails";

const ReportDetails = ({
  product,
  packingLists,
  filteredAuctionsItems,
  installed,
}: {
  product: any;
  packingLists: any;
  filteredAuctionsItems: any;
  installed: any;
}) => {
  const isQuickView = !!product;
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [packingListsShow, setPackingListShow] = useState<any[]>([]);
  const [packingListDetail, setPackingListDetail] = useState<any>(undefined);
  const [state_, setState] = useState<{ row: any; packingLists: any }>({
    row: "",
    packingLists: "",
  });

  const load = async () => {
    setLoading(true);
    let packingListRelated: any[] = [];

    let promise_values = packingLists.map(async (packingList: any) => {
      let add = false;
      let sub_promisses = packingList.packingListArray.map(
        async (item: any) => {
          if (add) return;
          Object.keys(item.packingListItemsQuantity).forEach(
            (keyItemsQuantity) => {
              if (item.packingListItemsQuantity[keyItemsQuantity] === 0 || add)
                return;
              Object.keys(item.pawn.auction.items).forEach((keyPawnsItems) => {
                if (add) return;
                let item_prod = item.pawn.auction.items[keyPawnsItems];
                try {
                  item_prod = item_prod.product.get().data();
                } catch (error) {}
                item_prod = item_prod.product;
                if (item_prod.id === product.id) add = true;
              });
            }
          );
        }
      );
      if (add) packingListRelated = [...packingListRelated, { ...packingList }];
      await Promise.all(sub_promisses);
    });
    await Promise.all(promise_values);
    setPackingListShow(packingListRelated);
    setLoading(false);
  };

  useEffect(() => {
    if (product && packingLists) {
      load();
    }
  }, [product, packingLists]);

  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell: any, row: any) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "voltage",
      text: "Voltagem",
      formatter: (cell: any, row: any) => voltageTranslation(cell),
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
  ];

  const columnsPackingLists = [
    {
      sort: true,
      dataField: "number",
      text: "Romaneio nº",
    },
    {
      sort: true,
      dataField: "driver",
      text: "Motorista",
    },
    {
      sort: true,
      dataField: "createdAt.seconds",
      text: "Criado em",
      formatter: (cell: any, row: any) => {
        return <p>{moment(cell * 1000).format("DD/MM/YYYY, HH:mm")}</p>;
      },
    },
    {
      sort: true,
      dataField: "status",
      text: "Situação da entrega",
      formatter: (cell: any, row: any) => {
        return (
          <span className={`${["red", "yellow", "green"][cell]}-pill pill`}>
            {["Pendente", "Parcial", "Concluído"][cell]}
          </span>
        );
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
      headerAlign: "center",
      formatter: (cell: any, row: any) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setPackingListDetail(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <div className="animated">
      {!!packingListDetail && (
        <QuickViewModal
          closeModal={() => setPackingListDetail(undefined)}
          isOpen={!!packingListDetail}
          title={`Romaneio #${packingListDetail?.number}`}
          size={"xl"}
        >
          <PackingListListDetails data={packingListDetail} />
        </QuickViewModal>
      )}
      {isQuickView && (
        <Fragment>
          <div className="animated">
            <Card>
              <BootstrapTable
                keyField="model"
                data={[product]}
                columns={columns}
                bordered={false}
                hover
              />
            </Card>
            {isLoading && <CenteredSpinner />}
            {!isLoading && packingListsShow && (
              <Card>
                <CardHeader>Romaneios</CardHeader>
                <BootstrapTable
                  keyField="number"
                  data={packingListsShow}
                  columns={columnsPackingLists}
                  bordered={false}
                  hover
                />
              </Card>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ReportDetails;
