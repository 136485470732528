import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetLogs = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [logs, setLogs] = useState([] as any);
  const [loadingLogs, setLoadingLogs] = useState(false);

  useEffect(() => {
    setLoadingLogs(true);
    db.collection("logs")
    .orderBy("createdAt", "desc")
    .limit(5000)
      .get()
      .then((querySnapshot) => {
        let logList = querySnapshot.docs.map((doc) => {
          const data = { ...doc.data(), id: doc.id };
          return data;
        });
        setLogs(logList);
        setLoadingLogs(false);
      })
      .catch((error) => {
        setLoadingLogs(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingLogs, logs };
};

export default useGetLogs;
