import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetDeliveries = (count?: any, startDate?: any, endDate?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [deliveries, setDeliveries] = useState([] as any);
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);

  useEffect(() => {
    setLoadingDeliveries(true);
    db.collection("deliveries")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        setDeliveries(querySnapshot.docs.map((doc) => doc.data()));
        setLoadingDeliveries(false);
      })
      .catch((error) => {
        setLoadingDeliveries(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [count]);

  useEffect(() => {
    if (startDate && endDate) {
      setLoadingDeliveries(true);
      db.collection("deliveries")
        .where("deliveryDate", ">=", startDate.format("YYYY-MM-DD"))
        .where("deliveryDate", "<=", endDate.format("YYYY-MM-DD"))
        .get()
        .then((querySnapshot) => {
          setDeliveries(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingDeliveries(false);
        })
        .catch((error) => {
          setLoadingDeliveries(false);
          console.log("erro: ", error);
        });
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [startDate, endDate]);

  return { loadingDeliveries, deliveries };
};

export default useGetDeliveries;
