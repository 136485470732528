import React from "react";
import { Col, Spinner } from "reactstrap";

const CenteredSpinner = () => {
  return (
    <Col className="text-center">
      <Spinner color="primary" />
    </Col>
  );
};

export default CenteredSpinner;
