import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { CustomReactSelect } from "components";
import { useGetPackingLists } from "hooks";
import { formatOptions } from "utils/general";
import PLDeliveryCard from "./PLDeliveryCard";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import dispatch, { DeliveryActions } from "actions";

const DeliveryPackingListSelection = () => {
  const history = useHistory();

  const packingList = useSelector((state: any) => state.delivery.packingList);
  const { loadingPackingLists, packingLists } = useGetPackingLists();
  const [filteredPackingLists, setFilteredPackingLists] = useState([]);

  useEffect(() => {
    if (packingLists.length > 0 && !loadingPackingLists) {
      const filteredPackinglist = packingLists.filter(
        (packingLists: { status: number }) =>
          packingLists.status === 0 || packingLists.status === 1
      );
      setFilteredPackingLists(filteredPackinglist);
    }
  }, [packingLists, loadingPackingLists]);

  const deliverPackingList = (
    packingListSelected: any,
    pawn: any,
    relatedProducts: any
  ) => {
    history.push({
      pathname: "./romaneios/detalhes",
      state: {
        packingList: packingList.data,
        packingListSelected,
        pawn,
        relatedProducts,
      },
    });
  };

  const packingListList = () => {
    const packingListArray = packingList.data?.packingListArray
      ? packingList.data.packingListArray
      : [];
    return packingListArray.map((item: any, index: any) => (
      <PLDeliveryCard
        packingList={item}
        deliverPackingList={deliverPackingList}
      />
    ));
  };

  return (
    <div className="animated">
      <Card>
        <CardHeader>Nova entrega - Romaneio</CardHeader>
        <CardBody>
          <Row>
            <Col md="12" className="mb-3">
              <CustomReactSelect
                label="Romaneio | Motorista | Romaneios | Cidades"
                field="pawn"
                value={packingList}
                onChange={(field: any, newValue: any) => {
                  dispatch(DeliveryActions.savePackingList, newValue);
                }}
                options={formatOptions(filteredPackingLists, "packingList")}
                autoFocus
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {packingList?.data?.packingListArray && (
        <Card>
          <CardBody>{packingListList()}</CardBody>
        </Card>
      )}
    </div>
  );
};

export default DeliveryPackingListSelection;
