import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetProductFamily = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [productFamily, setProductFamily] = useState([] as any);
  const [loadingProductFamily, setLoadingProductFamily] = useState(false);

  useEffect(() => {
    setLoadingProductFamily(true);
    db.collection("productFamily")
      .get()
      .then((querySnapshot) => {
        const families = querySnapshot.docs.map((doc) => doc.data());
        setProductFamily(families);
        setLoadingProductFamily(false);
      })
      .catch((error) => {
        setLoadingProductFamily(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingProductFamily, productFamily };
};

export default useGetProductFamily;
