import React, { Fragment, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { QuickViewModal } from "components";
import ReportDetails from "./Details/ReportDetails";
import { money } from "utils/mask";

const PawnReport = ({ data, filteredAuctionsItems, auctions }) => {
  const [quickViewPawn, setQuickViewItem] = useState(undefined);

  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell, row) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
    {
      sort: true,
      dataField: "middlePrice",
      text: "Preço Médio",
      formatter: (cell, row) => money(cell),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Pregões",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setQuickViewItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <Fragment>
      <QuickViewModal
        closeModal={() => setQuickViewItem(undefined)}
        isOpen={quickViewPawn}
        title={`Produto`}
      >
        <ReportDetails
          product={quickViewPawn}
          auctions={auctions}
          filteredAuctionsItems={filteredAuctionsItems}
        />
      </QuickViewModal>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={false}
        hover
      />
    </Fragment>
  );
};

export default PawnReport;
