import React, { Fragment, useEffect, useState } from "react";
import { QuickViewModal } from "components";
import PawnDetails from "../../PawnDetails/PawnDetails";
import { useHistory } from "react-router-dom";

const FilteredPawnReport = (
  { pawnDetail, 
    isEditPawn, 
    setIsEditPawn 
  }: { pawnDetail: any, isEditPawn: boolean, setIsEditPawn: any }) => {

  const history = useHistory();
  const pawn = pawnDetail[0];

  return (
    <div className="animated">
      { isEditPawn &&
      <QuickViewModal
        closeModal={() => setIsEditPawn(false)}
        isOpen={isEditPawn}
        title={`Empenho #${pawn?.number}`}
      >
        <PawnDetails quickViewPawn={pawn} history={history} />
      </QuickViewModal>
      }
    
    </div>
  );
};

export default FilteredPawnReport;
