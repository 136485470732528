const typeAuction = [
  {
    value: "acquisition",
    label: "Aquisição",
  },
  {
    value: "priceRegister",
    label: "Registro de preços",
  },
];

export default typeAuction;
