import React, { useState, Fragment } from "react";
import { CenteredSpinner } from "components";
import { useSendFile } from "hooks";

interface FileInputProps {
  path: string;
  name: string;
  fileUrl: string;
  onComplete?: any;
  onRemove?: () => void;
  isQuickView?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  path,
  name,
  fileUrl,
  onComplete,
  onRemove,
  isQuickView,
}) => {
  const { sendFile } = useSendFile();
  const [loading, setLoading] = useState(false);

  const handleComplete = (downloadURL: string, name: string) => {
    setLoading(false);
    onComplete(downloadURL, name);
  };

  return (
    <Fragment>
      {fileUrl ? (
        <div className="d-flex flex-row mb-2">
          <a
            href={fileUrl}
            target="_blank"
            rel="noreferrer"
            className="action-button"
          >
            {name}
          </a>

          {!isQuickView && (
            <span className="ml-auto">
              <i className="icon-trash action-button" onClick={onRemove} />
            </span>
          )}
        </div>
      ) : (
        <Fragment>
          {loading ? (
            <CenteredSpinner />
          ) : (
            <input
              id="nfeUrl"
              type="file"
              accept="application/pdf, image/png, image/jpeg"
              onChange={(evt: any) => {
                const name = evt.target.files[0].name;
                setLoading(true);
                sendFile({
                  path: path,
                  file: evt.target.files[0],
                  onComplete: (url: string) => handleComplete(url, name),
                });
              }}
              style={{ padding: 0, border: "none" }}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default FileInput;
