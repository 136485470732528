import React, { useEffect, createRef, Fragment } from "react";
import ReactDOM from 'react-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactToPrint from 'react-to-print';

interface QuickViewModalProps {
  closeModal: any;
  isOpen: boolean;
  title: string;
  size?: string
  print?: boolean

  // col: number;
  // name: string;
  // label: string;
  // type: string;
  // placeHolder?: string;
  // value: string | number;
  // onChange?: (evt: any) => void;
  // onBlur?: () => void;
  // error?: string;
  // disabled?: boolean;
  // quickView?: boolean;
}

const QuickViewModal: React.FC<QuickViewModalProps> = (props) => {
  const { closeModal, isOpen, title, children, size, print } = props;

  const pageStyle = `
    @page {
      size: 80mm 50mm;
      margin-top: 5rem;
    }

    @media all {
      .pagebreak {
        display: none;
      }
    }

    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `;

  const modalRef = createRef<HTMLDivElement>()
  useEffect(() => {
    ReactDOM.findDOMNode(modalRef.current)
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size={size ? size : "lg"}>
      <ModalHeader toggle={closeModal}>{title}</ModalHeader>
      <div ref={modalRef} id="printThis">
        <ModalBody className="small-text">{children}</ModalBody>
      </div>
      {print && <ReactToPrint
        trigger={() =>
          <ModalFooter>
            <Button color="info" onClick={() => {
            }}>
              Imprimir
            </Button>
          </ModalFooter>
        }
        content={() => modalRef.current}
        pageStyle={pageStyle}
      />}
      {/* <ModalFooter>
    {!verified && (
      <Button color="secondary" onClick={closeModal}>
        Não
      </Button>
    )}
    <Button color="primary" onClick={handleConfirm}>
      {verified ? "Ok" : "Sim"}
    </Button>
  </ModalFooter> */}
    </Modal>
  );
};

export default QuickViewModal;
