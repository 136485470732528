import { money } from "utils/mask";
const sumPawnItems = ({ auctionItems, pawnItems }: any) => {
  let error = "";
  let sum = 0;

  for (const key in pawnItems) {
    const auctionItem = auctionItems[key];

    if (!auctionItem) {
      console.log("missingKey", key);
      console.log("auctionItems", auctionItems);
      console.log("pawnItems", pawnItems);
    }

    const quantity = pawnItems[key];
    const itemPrice = auctionItem?.unitPrice;

    if (!!auctionItem && !!itemPrice) {
      sum += quantity * itemPrice;
    } else {
      error = "erro ao calcular valor";
    }
  }

  return {
    sum: error ? "" : money(sum),
    error: error,
  };
};

export { sumPawnItems };
