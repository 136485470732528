import React from 'react';
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from './styles';

interface ToogleSwitchProps {
  values: string[];
  selected: string;
  handleChange: any;
  height: any;
}

const titleCase = (str: any) =>
  str.split(/\s+/).map((w: any) => w[0].toUpperCase() + w.slice(1)).join(' ');

const ClickableLabel = ({ title, onChange, id }: { title: any, onChange: any, id: any }) =>
  <SwitchLabel 
    onClick={() => onChange(title)} 
    className={id}
    color={'#000'}
  >
    {titleCase(title)}
  </SwitchLabel>;

const ConcealedRadio = ({ value, selected }: { value: any, selected: any }) =>
  <SwitchRadio type="radio" name="switch" checked={selected === value} />;

const ToggleSwitch: React.FC<ToogleSwitchProps> = (props) => {
  const { values, selected, handleChange, height } = props

  const selectionStyle = () => {
    return {
      left: `${values.indexOf(selected) / 4 * 100}%`,
    };
  };

  return (
    <Switch
    >
      {values.map((val: any, key: any) => {
        return (
          <span key={key}>
            <ConcealedRadio 
              value={val} 
              selected={selected} 
            />
            <ClickableLabel 
              id={key} 
              title={val} 
              onChange={handleChange} 
            />
          </span>
        );
      })}
      <SwitchSelection
        style={selectionStyle()}
        color={
          selected === values[0] ? '#ff4640' :
          selected === values[1] ? '#1ae17d' : 
          selected === values[2] ? '#20a8d8':
          selected === values[3] ? '#b616b6': '#b616b6'
        }
      />
    </Switch>
  );
}

export default ToggleSwitch