import { useState, useEffect } from "react";
import firebase from "config/firebase";
import { useGetOpenPackingLists } from "hooks";

const useGetPawnsFromOpenPackingLists = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [pawnsInPL, setPawnsInPL] = useState([] as any);
  const [loadingPawns, setLoadingPawns] = useState(false);
  const [
    pawnsFromOpenPackingListsError,
    setPawnsFromOpenPackingListsError,
  ] = useState(false);

  const {
    loadingPackingLists,
    getOpenPackingLists,
    openPackingListsError,
  } = useGetOpenPackingLists();

  useEffect(() => {
    setPawnsFromOpenPackingListsError(openPackingListsError);
  }, [openPackingListsError]);

  useEffect(() => {
    try {
      setLoadingPawns(true);

      getOpenPackingLists().then((packingLists) => {
        createPawnArray(packingLists);
        setLoadingPawns(false);
      });
    } catch (error) {
      setPawnsFromOpenPackingListsError(true);
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, []);

  const createPawnArray = (packingLists: any[]) => {
    try {
      let openPackingListsArray: any = [];
      packingLists.forEach((packingList: any) => {
        packingList.packingListArray.forEach((singlePL: any) => {
          if (!singlePL.status) {
            openPackingListsArray.push(singlePL);
          }
        });
      });
      groupPawnItems(openPackingListsArray);
    } catch (error) {
      setPawnsFromOpenPackingListsError(true);
    }
  };

  const groupPawnItems = (openPackingListsArray: any[]) => {
    try {
      let pawnsInPL: any = {};
      openPackingListsArray.forEach((pl: any) => {
        const pawnId: string = pl.pawn.id;
        if (!pawnsInPL[pawnId]) {
          pawnsInPL[pawnId] = {};
        }
        for (const itemId in pl.packingListItemsQuantity) {
          const quantity = pl.packingListItemsQuantity[itemId];
          if (!pawnsInPL[pawnId][itemId]) {
            pawnsInPL[pawnId][itemId] = 0;
          }
          pawnsInPL[pawnId][itemId] += quantity;
        }
      });

      setPawnsInPL(pawnsInPL);
    } catch (error) {
      setPawnsFromOpenPackingListsError(true);
    }
  };

  return { loadingPawns, pawnsInPL, pawnsFromOpenPackingListsError };
};

export default useGetPawnsFromOpenPackingLists;
