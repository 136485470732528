import React, { useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetLogs } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { actionTranlate, collectionTranlate } from "utils/general";
import { CenteredSpinner } from "components";

const LogList = () => {
  const { loadingLogs, logs } = useGetLogs();
  const { SearchBar } = Search;

  const columns = [
    {
      sort: true,
      dataField: "action",
      text: "Ação",
      formatter: (cell, row) => {
        return <p>{actionTranlate(cell)}</p>;
      },
    },
    {
      sort: true,
      dataField: "collection",
      text: "Local",
      formatter: (cell, row) => collectionTranlate(cell),
    },
    {
      sort: true,
      dataField: "createdAt.seconds",
      text: "Data",
      formatter: (cell, row) => {
        return <p>{moment(cell * 1000).format("DD/MM/YYYY, HH:mm")}</p>;
      },
    },
    {
      sort: true,
      dataField: "reference",
      text: "Documento",
    },
    {
      sort: true,
      dataField: "user.name",
      text: "Usuário",
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "createdAt.seconds",
      order: "desc",
    },
  ];

  const searchBarRef = useRef()
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus()
  }, [])

  return (
    <div className="animated">
      <Card>
        <CardHeader>Logs</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <ToolkitProvider
                keyField="id"
                data={logs}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Row>
                      <Col sm={12}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Buscar na tabela"
                          style={{ width: "100%" }}
                          ref={searchBarRef}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      {loadingLogs ? (
                        <CenteredSpinner />
                      ) : (
                        <Col>
                          <BootstrapTable
                            {...props.baseProps}
                            bordered={false}
                            hover
                            pagination={pagination}
                            defaultSorted={defaultSorted}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LogList;
