import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import firebase from "config/firebase";
import { notify } from "services";

const RecoverPassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const sendMail = () => {
    if (!email) {
      notify({
        description: "Por favor insira seu e-mail.",
        type: "danger",
      });
      return;
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.log("erro", error);

        notify({
          description: "Erro ao enviar e-mail.",
          type: "danger",
        });
      });
  };

  const form = () => {
    return (
      <Form>
        <h1>Recuperar senha</h1>
        <p className="text-muted">
          Informe o e-mail cadastrado para receber um link de recuperação de
          senha:
        </p>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="cui-envelope-closed"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="E-mail"
            autoComplete="email"
            value={email}
            onChange={(evt) => {
              setEmail(evt.target.value);
            }}
          />
        </InputGroup>
        <Row>
          <Col xs="12" className="text-right">
            <Button color="primary" className="px-4" onClick={sendMail}>
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const success = () => {
    return (
      <Form>
        <h1>Pronto!</h1>
        <p className="text-muted">
          Enviamos um link para recuperação de senha no seu e-mail
        </p>
        <Row>
          <Col xs="12" className="text-right">
            <Button
              color="link"
              className="px-0"
              onClick={() => {
                history.push("/login");
              }}
            >
              Ir para login
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="12" lg="5">
            <Card className="p-4">
              <CardBody>{emailSent ? success() : form()}</CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              color="link"
              className="px-0"
              onClick={() => {
                history.goBack();
              }}
            >
              voltar
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RecoverPassword;
