import React, { useState, useEffect, Fragment } from "react";
import PLDeliveryCardDisplay from "./PLDeliveryCardDisplay";
import PLDeliveryCardPrint from "./PLDeliveryCardPrint";
import PLDeliveryCardResumePrint from "./PLDeliveryCardResumePrint";
import PLDeliveryData from "./PLDeliveryData";

import "./style.css";

const PLDeliveryCard = ({
  typeOfButtonPrint,
  packingList,
  editPackingListModal,
  deletePackingList,
  isLast,
  position,
  setTotal,
  objectTotal,
}: any) => {
  const [pawn, setPawn] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState<any>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!packingList?.pawn?.auction) priorLoadPawn();
    else {
      setPawn(packingList.pawn);
      priorLoadProducts(packingList.pawn.auction.items);
    }
  }, [typeOfButtonPrint]);

  const priorLoadPawn = () => {
    packingList.pawn.get().then((pawn: any) => {
      const pawnData = pawn.data();
      if (pawnData) {
        setPawn(pawnData);
        priorLoadProducts(pawnData.auction.items);
      } else {
        setError(true);
        console.log("error pawnData", packingList);
      }
    });
  };

  const priorLoadProducts = (items: any) => {
    loadProducts(items).then((prodSnapshots: any) => {
      let products: any = {};
      prodSnapshots.forEach((prodSnap: any) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setRelatedProducts(products);
    });
  };

  const loadProducts = (itemsWithProducts: any) => {
    const promises: any = [];
    let packingListItemsQuantity: any = {};

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      try {
        promises.push(item.product.get());
      } catch (error) {}
      packingListItemsQuantity[item.id] = 0;
    });

    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  return (
    <Fragment>
      <PLDeliveryCardDisplay
        setTotal={setTotal}
        objectTotal={objectTotal}
        pawn={pawn}
        pawnError={error}
        packingList={packingList}
        relatedProducts={relatedProducts}
        editPackingListModal={editPackingListModal}
        deletePackingList={deletePackingList}
        position={position}
      />
      {typeOfButtonPrint === "padrao" && (
        <PLDeliveryCardPrint
          pawn={pawn}
          packingList={packingList}
          relatedProducts={relatedProducts}
          isLast={isLast}
        />
      )}

      {typeOfButtonPrint === "resumido" && (
        <PLDeliveryCardResumePrint
          pawn={pawn}
          packingList={packingList}
          relatedProducts={relatedProducts}
          isLast={isLast}
        />
      )}
    </Fragment>
  );
};

export default PLDeliveryCard;
