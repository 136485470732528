import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { voltageTranslation } from "utils/general";
import moment from "moment";
import { CenteredSpinner, QuickViewModal } from "components";
import PawnDetails from "../../PawnDetails/PawnDetails";
import { useHistory } from "react-router-dom";

const ReportDetails = ({
  product,
  pawns,
  filteredAuctionsItems,
}: {
  product: any;
  pawns: any;
  filteredAuctionsItems: any;
}) => {
  const isQuickView = !!product;
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [pawn, setPawn] = useState<any>(undefined);

  const loadProducts = (product: any) => {
    const promises: any[] = [];
    try {
      promises.push(product.get());
    } finally {
      return Promise.all(promises).catch((error) => {
        alert("Erro ao acessar empenhos");
        console.log("erro: ", error);
      });
    }
  };

  const load = async () => {
    setLoading(true);
    let auctionsRelated: any[] = [];

    let promise_values = pawns.map(async (pawn: any) => {
      let add = false;
      let items: any[] = [];
      let pawnItemsQuantity: any[] = [];
      let promise_keys_1 = Object.keys(pawn.auction.items).map((key: any) => {
        let item = pawn.auction.items[key];
        if (
          item?.voltage?.value === product.voltage &&
          item?.product?.id === product.id
        ) {
          items[key] = pawn.auction.items[key];
        }
      });
      await Promise.all(promise_keys_1);
      let promise_keys_2 = Object.keys(pawn.pawnItemsQuantity).map(
        (key: any) => {
          if (items[key] && pawn.pawnItemsQuantity[key] > 0) {
            pawnItemsQuantity[key] = pawn.pawnItemsQuantity[key];
            add = true;
          }
        }
      );
      await Promise.all(promise_keys_2);
      if (add) {
        auctionsRelated = [
          ...auctionsRelated,
          {
            auction: {
              company: pawn.auction.company,
              createAt: pawn.auction.createAt,
              governmentAgency: pawn.auction.governmentAgency,
              id: pawn.auction.id,
              items: pawn.auction.items,
              number: pawn.auction.number,
              tradingType: pawn.auction.tradingType,
            },
            createAt: pawn.createAt,
            customPawnNumber: pawn.customPawnNumber,
            deliveryDays: pawn.deliveryDays,
            deliveryStatus: pawn.deliveryStatus,
            id: pawn.id,
            number: pawn.number,
            observations1: pawn.observations1,
            obervations2: pawn.obervations2,
            pawnItemsQuantity: pawnItemsQuantity,
            receivedAt: pawn.receivedAt,
          },
        ];
      }
    });
    await Promise.all(promise_values);
    setAuctions(auctionsRelated);
    setLoading(false);
  };

  useEffect(() => {
    if (product && pawns) {
      load();
    }
  }, [product, pawns]);

  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell: any, row: any) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "voltage",
      text: "Voltagem",
      formatter: (cell: any, row: any) => voltageTranslation(cell),
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
  ];

  const columnsAuction = [
    {
      sort: true,
      dataField: "number",
      text: "Pedido",
    },
    {
      sort: true,
      dataField: "customPawnNumber",
      text: "Número",
    },
    {
      sort: true,
      dataField: "auction.company.name",
      text: "Empresa",
    },
    {
      sort: true,
      dataField: "auction.number",
      text: "Pregão",
    },
    {
      sort: true,
      dataField: "auction.governmentAgency.name",
      text: "Órgão",
    },
    {
      sort: true,
      dataField: "auction.governmentAgency.city",
      text: "Cidade",
      formatter: (cell: any, row: any) => {
        return `${cell}, ${row.auction.governmentAgency.state.value}`;
      },
    },
    {
      sort: true,
      dataField: "receivedAt",
      text: "Recebido em",
      formatter: (cell: any, row: any) => {
        return moment(row.receivedAt).format("DD/MM/YYYY");
      },
    },
    {
      sort: true,
      dataField: "deliveryDays",
      text: "Prazo",
      formatter: (cell: any, row: any) => {
        return moment(row.receivedAt)
          .add(row.deliveryDays, "days")
          .format("DD/MM/YYYY");
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
      headerAlign: "center",
      formatter: (cell: any, row: any) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setPawn(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <div className="animated">
      {!!pawn && (
        <QuickViewModal
          closeModal={() => setPawn(undefined)}
          isOpen={!!pawn}
          title={`Empenho #${pawn?.number}`}
        >
          <PawnDetails quickViewPawn={pawn} history={history} />
        </QuickViewModal>
      )}
      {isQuickView && (
        <Fragment>
          <div className="animated">
            <Card>
              <BootstrapTable
                keyField="model"
                data={[product]}
                columns={columns}
                bordered={false}
                hover
              />
            </Card>
            {isLoading && <CenteredSpinner />}
            {!isLoading && auctions && (
              <Card>
                <CardHeader>Empenhos</CardHeader>
                <BootstrapTable
                  keyField="number"
                  data={auctions}
                  columns={columnsAuction}
                  bordered={false}
                  hover
                />
              </Card>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ReportDetails;
