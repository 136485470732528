import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "./style.css";

interface DeleteModalPropsInterface {
  name: string;
  closeModal: () => void;
  component: boolean;
  size?: string;
  children: React.ReactChild;
}

const DeleteModal = (props: DeleteModalPropsInterface) => {
  const { name, closeModal, component, size, children } = props;

  return (
    <Modal isOpen={!!component} toggle={closeModal} size={size}>
      <ModalHeader toggle={closeModal}>{name}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default DeleteModal;
