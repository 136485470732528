interface MoneyInterface {
  (input: any): number | string
}

const money: MoneyInterface = (input) => {
  input = input || 0

  let formatedValue = (input / 100).toFixed(2).replace('.', ',').replace(/\d{3}(?=(\d{3})*,)/g, function (s) {
    return '.' + s
  });
  if (formatedValue.substring(0, 1) === '.') {
    formatedValue = formatedValue.substring(1);
  }

  return 'R$ ' + formatedValue;
}

export default money
