import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { money } from "utils/mask";
import ItemModal from "./ItemModal/ItemModal";
import ItemDeleteModal from "./ItemModal/ItemDeleteModal";

const ItemList = ({
  isEdit,
  items,
  addItem,
  deleteItem,
  deliveredItems,
  notEdit,
}) => {
  const [itemsModal, setItemsModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(undefined);

  useEffect(() => {}, [deliveredItems]);

  const toggleItemsModal = () => {
    setItemsModal(!itemsModal);
    if (itemsModal) setEditItem(null);
  };

  const formatItems = () => {
    let formattedItems = [];
    if (items)
      for (let [key, value] of Object.entries(items)) {
        formattedItems.push(value);
      }
    return formattedItems;
  };

  const columns = [
    {
      sort: true,
      text: "Modelo",
      formatter: (cell, row) => (row.product.name ? row.product.name : ""),
    },
    {
      sort: true,
      // dataField: "product.brand.name",
      text: "Marca",
      formatter: (cell, row) =>
        row.product.brand ? row.product.brand.name : "",
    },
    {
      sort: true,
      text: "Capacidade",
      formatter: (cell, row) =>
        `${row.product.info ? row.product.info : ""} ${
          row.product.unitOfMeasurement
            ? row.product.unitOfMeasurement.name
            : ""
        }`,
    },
    {
      sort: true,
      dataField: "installed.label",
      text: "Instalado?",
    },
    {
      sort: true,
      dataField: "totalUnits",
      text: isEdit ? "Entregue/Qtd" : "Quantidade",
      formatter: (cell, row) => {
        if (isEdit) {
          const deliveredItemsCount = Object.entries(deliveredItems).length;
          const deliveredQuantity = deliveredItemsCount
            ? deliveredItems[row.id]
            : 0;

          let color = "yellow";
          if (deliveredQuantity === 0) color = "red";
          if (deliveredQuantity === cell) color = "green";
          return (
            <span
              className={`${color}-pill pill`}
            >{`${deliveredQuantity} / ${cell}`}</span>
          );
        } else {
          return cell;
        }
      },
    },
    {
      sort: true,
      text: "Valor unitário",
      formatter: (cell, row) => money(row.unitPrice),
    },
    {
      sort: true,
      text: "Subtotal",
      formatter: (cell, row) => money(row.totalUnits * row.unitPrice),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-note action-button"
              onClick={() => {
                setItemsModal(true);
                setEditItem(row);
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setItemToBeDeleted(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  return (
    <>
      <ItemModal
        itemsModal={itemsModal}
        toggleItemsModal={toggleItemsModal}
        addItem={addItem}
        editItem={editItem}
      />
      <ItemDeleteModal
        itemToBeDeleted={itemToBeDeleted}
        deleteItem={deleteItem}
        closeModal={() => setItemToBeDeleted(undefined)}
      />

      <div className="animated">
        <Row className="mt-4">
          <Col>
            <h3>Itens do Pregão</h3>
          </Col>
          {!notEdit && (
            <Col className="text-right">
              <Button color="success" onClick={toggleItemsModal}>
                Adicionar item
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <ToolkitProvider
              keyField="id"
              data={formatItems()}
              columns={columns}
              search
            >
              {(props) => (
                <Row className="mt-4">
                  <Col>
                    <BootstrapTable
                      {...props.baseProps}
                      bordered={false}
                      hover
                      pagination={pagination}
                      defaultSorted={defaultSorted}
                    />
                  </Col>
                </Row>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ItemList;
