import React, { Fragment, useState } from "react";
import { FileInput } from "components";

interface InputAndLabelProps {
  filesArray: any[];
  path: string;
  onChange: (filesArray: any) => any;
}

const InputAndLabel: React.FC<InputAndLabelProps> = ({
  filesArray,
  path,
  onChange,
}) => {
  const [addFile, setAddFile] = useState(false);

  const removeItem = (i: number) => {
    filesArray.splice(i, 1);
    onChange(filesArray);
  };

  const filesList = () => {
    return filesArray.map((file: any, i: number) => (
      <FileInput
        path={path}
        name={file.name}
        fileUrl={file.url}
        onRemove={() => {
          removeItem(i);
        }}
      />
    ));
  };

  return (
    <Fragment>
      {filesList()}
      {addFile ? (
        <FileInput
          path={"pregoes/outros"}
          name={""}
          fileUrl={""}
          onComplete={(url: string, name: string) => {
            onChange([...filesArray, { name, url }]);
            setAddFile(false);
          }}
        />
      ) : (
        <div className="text-center">
          <span
            className="action-button text-center"
            onClick={() => {
              setAddFile(true);
            }}
          >
            <i className="icon-plus action-button" /> Adicionar arquivo
          </span>
        </div>
      )}
    </Fragment>
  );
};

export default InputAndLabel;
