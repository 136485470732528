import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

const CubageForm = (props: any) => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = props;

  const [formattedProductData, setFormattedProductData] = useState<any>({
    totalWeight: 0,
    totalCubedWeight: 0,
    totalVolume: 0,
    totalCubed: 0,
    totalCubedVolume1: 0,
    totalCubedVolume2: 0,
  });

  function truncateToDecimals(num: number, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }

  const totalCubed = () => {
    let totalCubed = 0;
    if (
      values.volume1?.height &&
      values.volume1?.width &&
      values.volume1?.depth
      ) {
      const cubedVolume1 = values.volume1.height * values.volume1.depth * values.volume1.width
      if (
        values.volume2?.height &&
        values.volume2?.width &&
        values.volume2?.depth
      ) {
        const cubedVolume2 = values.volume2.height * values.volume2.depth * values.volume2.width
        totalCubed =
        truncateToDecimals(cubedVolume1) +
        truncateToDecimals(cubedVolume2);
      } else {
        totalCubed =
        truncateToDecimals(cubedVolume1)
      }
    }
    values.total.totalCubed = totalCubed.toFixed(2);
  };

  const setVolume1 = (volumeType: any, id: string, params: number) => {
    setFieldValue(volumeType, {
      ...values.volume1,
      [id]: params,
    });
  };

  const setVolume2 = (volumeType: any, id: string, params: number) => {
    setFieldValue(volumeType, {
      ...values.volume2,
      [id]: params,
    });
  };

  const totalVolume = () => {
    let totalVolume = 0;
    if (values.volume1?.volume) {
      if (values.volume2?.volume) {
        totalVolume = truncateToDecimals(values.volume1.volume + values.volume2.volume);
      } else {
        totalVolume = truncateToDecimals(values.volume1.volume);
      }
    }
    values.total.totalVolume = totalVolume.toFixed(2);
  };

  const totalCubedVolume1 = () => {
    let totalCubedVolume1 = 0;
    if (
      values.volume1?.height &&
      values.volume1?.width &&
      values.volume1?.depth
    ) {
      totalCubedVolume1 =
      truncateToDecimals(values.volume1.height * values.volume1.depth * values.volume1.width);
    }
    return totalCubedVolume1.toFixed(2);
  };

  const totalCubedVolume2 = () => {
    let totalCubedVolume2 = 0;
    if (
      values.volume2?.height &&
      values.volume2?.width &&
      values.volume2?.depth
    ) {
      totalCubedVolume2 =
      truncateToDecimals(values.volume2.height * values.volume2.depth * values.volume2.width);
    }
    return totalCubedVolume2.toFixed(2);
  };

  const totalCubedWeight = () => {
    let totalCubedWeight = 0;
    if (values.volume1?.cubedweight) {
      if (values.volume2?.cubedweight) {
        totalCubedWeight =
        truncateToDecimals(values.volume1.cubedweight + values.volume2.cubedweight);
      } else {
        totalCubedWeight = truncateToDecimals(values.volume1.cubedweight);
      }
    }
    values.total.totalCubedWeight = totalCubedWeight.toFixed(2);
  };

  const totalWeight = () => {
    let totalWeight = 0;
    if (values.volume1?.weight) {
      if (values.volume2?.weight) {
        totalWeight = truncateToDecimals(values.volume1.weight + values.volume2.weight);
      } else {
        totalWeight = truncateToDecimals(values.volume1.weight);
      }
    }
    values.total.totalWeight = totalWeight.toFixed(2);
  };

  let zeroNumber = 0;
  useEffect(() => {
    totalWeight();
    totalCubedWeight();
    totalVolume();
    totalCubed();
    setFormattedProductData({
      weightVolume1: values.volume1?.weight
        ? values.volume1?.weight.toFixed(2)
        : zeroNumber.toFixed(2),
      weightVolume2: values.volume2?.weight
        ? values.volume2?.weight.toFixed(2)
        : zeroNumber.toFixed(2),
      totalWeight: values.total.totalWeight.toString().replace(/\./g, ","),
      totalCubedWeight: values?.total?.totalCubedWeight
        .toString()
        .replace(/\./g, ","),
      totalVolume: values?.total?.totalVolume.toString().replace(/\./g, ","),
      totalCubed: values?.total?.totalCubed.toString().replace(/\./g, ","),
      totalCubedVolume1: totalCubedVolume1()
        .toString()
        .replace(/\./g, ","),
      totalCubedVolume2: totalCubedVolume2()
        .toString()
        .replace(/\./g, ","),
    });
  }, [values.volume1, values.volume2]);

  console.log(errors);

return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-1 mt-4" md="3">
              <h5>Volume 1:</h5>
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-1">
              <label htmlFor="name" style={{ display: "block" }}>
                Altura
              </label>
              <input
                id="height"
                placeholder="Altura"
                type="number"
                value={values.volume1?.height}
                onChange={(evt: any) => {
                   setVolume1("volume1", "height", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.height && touched.height && (
                <div className="error-div">{errors.height}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="name" style={{ display: "block" }}>
                Largura
              </label>
              <input
                id="width"
                placeholder="Largura"
                type="number"
                value={values.volume1?.width}
                onChange={(evt) => {
                  setVolume1("volume1", "width", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.width && touched.width && (
                <div className="error-div">{errors.width}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="name" style={{ display: "block" }}>
                Profundidade
              </label>
              <input
                id="depth"
                placeholder="Profundidade"
                type="number"
                value={values.volume1?.depth}
                onChange={(evt) => {
                  setVolume1("volume1", "depth", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.depth && touched.depth && (
                <div className="error-div">{errors.depth}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="name" style={{ display: "block" }}>
                Peso
              </label>
              <input
                id="weight"
                placeholder="Peso"
                type="number"
                value={values.volume1?.weight}
                onChange={(evt) => {
                  setVolume1("volume1", "weight", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.weight && touched.weight && (
                <div className="error-div">{errors.weight}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="cubedweight" style={{ display: "block" }}>
                Peso Cubado
              </label>
              <input
                id="cubedweight"
                placeholder="Peso Cubado"
                type="number"
                value={values.volume1?.cubedweight}
                onChange={(evt) => {
                  setVolume1(
                    "volume1",
                    "cubedweight",
                    evt.target.valueAsNumber
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.cubedweight && touched.cubedweight && (
                <div className="error-div">{errors.cubedweight}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="volume" style={{ display: "block" }}>
                Volume
              </label>
              <input
                id="volume"
                placeholder="Volume"
                type="number"
                value={values.volume1?.volume}
                onChange={(evt) => {
                  setVolume1("volume1", "volume", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.volume && touched.volume && (
                <div className="error-div">{errors.volume}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="totalCubage">Cubagem Total</label>
              <p>{totalCubedVolume1()}</p>
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="name">Peso Total</label>
              <p>
                {formattedProductData.weightVolume1}
              </p>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col className="mb-1 mt-4">
              <h5>Volume 2:</h5>
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-1">
              <label htmlFor="height2" style={{ display: "block" }}>
                Altura
              </label>
              <input
                id="height"
                placeholder="Altura"
                type="number"
                value={values.volume2?.height}
                onChange={(evt) => {
                  setVolume2("volume2", "height", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.height2 && touched.height2 && (
                <div className="error-div">{errors.height2}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="width2" style={{ display: "block" }}>
                Largura
              </label>
              <input
                id="width2"
                placeholder="Largura"
                type="number"
                value={values.volume2?.width}
                onChange={(evt) => {
                  setVolume2("volume2", "width", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.width2 && touched.width2 && (
                <div className="error-div">{errors.width2}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="depth2" style={{ display: "block" }}>
                Profundidade
              </label>
              <input
                id="depth"
                placeholder="Profundidade"
                type="number"
                value={values.volume2?.depth}
                onChange={(evt) => {
                  setVolume2("volume2", "depth", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.depth2 && touched.depth2 && (
                <div className="error-div">{errors.depth2}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="weight2" style={{ display: "block" }}>
                Peso
              </label>
              <input
                id="weight2"
                placeholder="Peso"
                type="number"
                value={values.volume2?.weight}
                onChange={(evt) => {
                  setVolume2("volume2", "weight", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.weight2 && touched.weight2 && (
                <div className="error-div">{errors.weight2}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="cubedweight" style={{ display: "block" }}>
                Peso Cubado
              </label>
              <input
                id="cubedweight"
                placeholder="Peso Cubado"
                type="number"
                value={values.volume2?.cubedweight}
                onChange={(evt) => {
                  setVolume2(
                    "volume2",
                    "cubedweight",
                    evt.target.valueAsNumber
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.cubedweight2 && touched.cubedweight2 && (
                <div className="error-div">{errors.cubedweight2}</div>
              )}
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="volume2" style={{ display: "block" }}>
                Volume
              </label>
              <input
                id="volume2"
                placeholder="Volume"
                type="number"
                value={values.volume2?.volume}
                onChange={(evt) => {
                  setVolume2("volume2", "volume", evt.target.valueAsNumber);
                }}
                onBlur={handleBlur}
              />
              {errors.volume2 && touched.volume2 && (
                <div className="error-div">{errors.volume2}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5" className="mb-3">
              <label htmlFor="totalCubage2" style={{ display: "block" }}>
                Cubagem Total
              </label>
              <p>{formattedProductData.totalCubedVolume2}</p>
            </Col>
            <Col md="5" className="mb-3">
              <label htmlFor="totalweight2" style={{ display: "block" }}>
                Peso Total
              </label>
              <p>{formattedProductData.weightVolume2}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-1 mt-4">
          <h5>Totais:</h5>
        </Col>
      </Row>

      <Row>
        <Col md="3" className="mb-3">
          <label htmlFor="name" style={{ display: "block" }}>
            Peso Total
          </label>
          <p>{formattedProductData.totalWeight}</p>
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="totalcubedweight" style={{ display: "block" }}>
            Peso Total Cubado
          </label>
          <p>{formattedProductData.totalCubedWeight}</p>
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="volume" style={{ display: "block" }}>
            Volume Total
          </label>
          <p>{formattedProductData.totalVolume}</p>
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="totalCubage" style={{ display: "block" }}>
            Cubagem Total
          </label>
          <p>{formattedProductData.totalCubed}</p>
        </Col>
      </Row>
    </>
  );
};

export default CubageForm;