import React, { useRef, Fragment } from "react";

interface TextAreaProps {
  name: string;
  label: string;
  placeHolder: string;
  value: string;
  onChange: (evt: any) => void;
  onBlur: () => void;
  isQuickView?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  placeHolder,
  value,
  onChange,
  onBlur,
  isQuickView,
}) => {
  const textAreaRef: any = useRef(null);

  if (textAreaRef.current != null && textAreaRef.current != undefined) {
    textAreaRef.current.style.height = "0px";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  }

  return (
    <Fragment>
      <label htmlFor={name} style={{ display: "block" }}>
        {label}
      </label>
      <textarea
        className="form-control"
        ref={textAreaRef}
        style={{ resize: "none", overflow: "hidden" }}
        placeholder={placeHolder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isQuickView}
      />
    </Fragment>
  );
};

export default TextArea;
