import React, { useState, useEffect, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { CustomSidebar } from "components";
import { AppBreadcrumb, AppHeader, AppSidebar } from "@coreui/react";
import {
  adminRoutes,
  sellerRoutes,
  pledgerRoutes,
  delivererRoutes,
  finacialRoutes,
} from "config/routes";
import firebase from "config/firebase";
import DefaultHeader from "./DefaultHeader";
import { useSelector } from "react-redux";

const DefaultLayout = (props) => {
  const userRole = useSelector((state) => state.user.role);
  const [userRoutes, setUserRoutes] = useState([]);

  const getUserRoutes = () => {
    const rolesRoutes = {
      admin: adminRoutes,
      seller: sellerRoutes,
      pledger: pledgerRoutes,
      deliverer: delivererRoutes,
      financial: finacialRoutes,
      collection: finacialRoutes,
    };
    setUserRoutes(rolesRoutes[userRole]);
  };

  useEffect(() => {
    let currentUser = firebase.auth().currentUser;
    if (!currentUser) props.history.push("/login");
    getUserRoutes();
  }, []);

  const signOut = (e) => {
    firebase.auth().signOut();
    e.preventDefault();
    props.history.push("/login");
  };

  return (
    <div className="app">
      {userRoutes.length > 0 && (
        <Fragment>
          <AppHeader fixed>
            <DefaultHeader
              onLogout={(e) => signOut(e)}
              history={props.history}
            />
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg">
              <CustomSidebar history={props.history} />
            </AppSidebar>
            <main className="main">
              <AppBreadcrumb appRoutes={userRoutes} className="d-print-none" />
              <Container fluid>
                <Switch>
                  {userRoutes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Container>
            </main>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DefaultLayout;
