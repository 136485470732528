import React, { useEffect, useState } from "react";
import {
  CustomReactSelect,
  TextArea,
  InputAndLabel,
  CenteredSpinner,
} from "components";
import { Button, Col, Row } from "reactstrap";
import { useGetAuctions, useDebounce, useValidatePawnUnique } from "hooks";
import { formatOptions } from "utils/general";
import moment from "moment";
import ItemList from "./ItemList";
import firebase from "config/firebase";
import { sumPawnItems } from "./helpers";
import dispatch, { EditActions } from "actions";

const PawnForm = (props: any) => {
  const { auctions } = useGetAuctions(undefined, undefined, undefined, undefined, true);
  const [relatedProducts, setRelatedProducts] = useState<any>(null);
  const [isLoading, setLoading] = useState(0);

  const db = firebase.firestore();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isEdit,
    isQuickView,
    id,
  } = props;


  useEffect(() => {
    if (isEdit) {
      priorLoadProducts(values.auction.data.items);
      loadRelatedPawns(values.auction.data.id);
    } else {
      setLoading(1);
    }
  }, []);

  useEffect(() => {
    if (isLoading === 1 && values) setLoading(2);
    if (isLoading === 2) dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  useEffect(() => {
    calculateDeliveryDate();
  }, [values.receivedAt, values.deliveryDays]);

  const getVoltageValue = () => {
    let objectVoltage: any = {};
    Object.keys(values.pawnItemsQuantity).forEach((key) => {
      const itemVoltage = values?.auction?.data?.items[key]?.voltage;
      if (itemVoltage) {
        const idVoltage = values.auction.data.items[key].id;
        const valueVoltage = values.auction.data.items[key].voltage.label;
        objectVoltage = { ...objectVoltage, [idVoltage]: valueVoltage };
        setFieldValue("setValueVoltage", objectVoltage);
      }
    });
  };

  useEffect(() => {
    getVoltageValue();
  }, [values?.auction?.data?.items]);

  useEffect(() => {
    if (values.auction && values.auction.data.daysForDelivery) {
      setFieldValue("deliveryDays", values.auction.data.daysForDelivery);
    } else {
      setFieldValue("deliveryDays", "");
      setFieldValue("deliveryDate", "");
    }
  }, [values.auction]);

  const calculateDeliveryDate = () => {
    const { receivedAt, deliveryDays } = values;
    if (receivedAt && deliveryDays) {
      setFieldValue(
        "deliveryDate",
        moment(receivedAt)
          .add(deliveryDays, "days")
          .format("YYYY-MM-DD")
      );
    } else {
      setFieldValue("deliveryDate", "");
    }
  };

  const priorLoadProducts = (items: any) => {
    loadProducts(items).then((prodSnapshots: any) => {
      let products: any = {};
      prodSnapshots.forEach((prodSnap: any) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setRelatedProducts(products);
    });
  };

  const loadProducts = (itemsWithProducts: any) => {
    const promises: any = [];
    let pawnItemsQuantity: any = {};

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      promises.push(item.product.get());
      pawnItemsQuantity[item.id] = 0;
    });
    if (!isEdit) setFieldValue("pawnItemsQuantity", pawnItemsQuantity);

    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const setPawnItemsQuantity = (id: string, quantity: number) => {
    setFieldValue("pawnItemsQuantity", {
      ...values.pawnItemsQuantity,
      [id]: quantity,
    });
  };

  const setValueVoltage = (id: string, voltage: string) => {
    setFieldValue("setValueVoltage", {
      ...values.setValueVoltage,
      [id]: voltage,
    });
  };

  const loadRelatedPawns = (auctionId: string) => {
    db.collection("pawns")
      .where("auction.id", "==", auctionId)
      .get()
      .then((querySnapshot) => {
        let relatedPawns = querySnapshot.docs.map((doc) => doc.data());
        calculatePawnedItems(relatedPawns);
        setLoading(1);
      })
      .catch((error) => {
        console.log("erro: ", error);
      });
  };

  const calculatePawnedItems = (relatedPawns: any) => {
    let previousPawnItems: any = {};
    relatedPawns.forEach((relatedPawn: any) => {
      if (relatedPawn.id !== values.id) {
        let pawnItemsQuantity = relatedPawn.pawnItemsQuantity;
        for (const pawnItemId in pawnItemsQuantity) {
          if (pawnItemsQuantity.hasOwnProperty(pawnItemId)) {
            if (previousPawnItems[pawnItemId]) {
              previousPawnItems[pawnItemId] += pawnItemsQuantity[pawnItemId];
            } else {
              previousPawnItems[pawnItemId] = pawnItemsQuantity[pawnItemId];
            }
          }
        }
      }
    });

    setFieldValue("previousPawnItems", previousPawnItems);
  };

  const debouncedPawnNumber = useDebounce(values.customPawnNumber, 1000);

  const { validating, uniqueValidated, isUnique } = useValidatePawnUnique({
    pawnNumber: debouncedPawnNumber,
    auctionId: values.auction?.data?.id,
    currentPawnId: id,
  });
  const pawnNumberPermited = !!uniqueValidated && !!isUnique;

  const productsSum = () => {
    let sum: any;
    if (values?.pawnItemsQuantity && values?.auction?.data?.items) {
      sum = sumPawnItems({
        auctionItems: values.auction.data.items,
        pawnItems: values.pawnItemsQuantity,
      });
    }

    return (
      sum && (
        <span className={`${sum.error ? "text-danger" : ""}`}>
          {sum.error ? sum.error : sum.sum}
        </span>
      )
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            isQuickView={isQuickView}
            label="Pregão"
            field="auction"
            value={values.auction}
            onChange={(field: any, newValue: any) => {
              setRelatedProducts(null);
              setFieldValue(field, newValue);
              priorLoadProducts(newValue.data.items);
              loadRelatedPawns(newValue.data.id);
            }}
            onBlur={setFieldTouched}
            error={errors.auction}
            touched={touched.auction}
            options={formatOptions(auctions, "auction")}
            autoFocus
          />
        </Col>

        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="company"
          label="Empresa:"
          type="text"
          value={values.auction && values.auction.data.company.name}
          disabled
        />
        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="tradingType"
          label="Tipo de licitação:"
          type="text"
          value={values.auction && values.auction.data.tradingType.label}
          disabled
        />
      </Row>

      <Row>
        {!isQuickView ? (
          <Col md="3" className="mb-3">
            <label htmlFor="name" style={{ display: "block" }}>
              Número
            </label>
            {!validating ? (
              <input
                id="customPawnNumber"
                placeholder="Número"
                type="text"
                value={values.customPawnNumber}
                onChange={(evt) => {
                  handleChange(evt);
                }}
              />
            ) : (
              <CenteredSpinner />
            )}
            {touched.customPawnNumber && errors.customPawnNumber && (
              <div className="error-div">{errors.customPawnNumber}</div>
            )}

            {uniqueValidated &&
              !errors.customPawnNumber &&
              !pawnNumberPermited && (
                <div className="error-div">Número já em uso</div>
              )}
          </Col>
        ) : (
          <InputAndLabel
            isQuickView={isQuickView}
            col={3}
            name="customPawnNumber"
            label="Número:"
            type="text"
            placeHolder="Número"
            value={values.customPawnNumber}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
            error={
              errors.customPawnNumber &&
              touched.customPawnNumber &&
              errors.customPawnNumber
            }
          />
        )}

        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="receivedAt"
          label="Pedido recebido em:"
          type="date"
          value={values.receivedAt}
          onChange={(evt) => {
            handleChange(evt);
            calculateDeliveryDate();
          }}
          onBlur={handleBlur}
          error={errors.receivedAt && touched.receivedAt && errors.receivedAt}
        />
        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="deliveryDays"
          label="Prazo de entrega em dias:"
          type="number"
          placeHolder="Prazo"
          value={values.deliveryDays}
          disabled={!!values.auction?.data?.daysForDelivery ? true : false}
          onChange={(evt) => {
            setFieldValue("deliveryDays", parseInt(evt.target.value));
          }}
          onBlur={handleBlur}
          error={
            errors.deliveryDays && touched.deliveryDays && errors.deliveryDays
          }
        />
        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="deliveryDate"
          label="Prazo de entrega:"
          type="date"
          value={values.deliveryDate}
          disabled={true}
        />

        {isEdit && (
          <Col md="6" className="mb-3">
            <label htmlFor="deliveryStatus" style={{ display: "block" }}>
              Situação da entrega:
            </label>
            <span
              className={`${["red", "yellow", "green"][values?.deliveryStatus]
                }-pill pill`}
            >
              {["Pendente", "Parcial", "Concluído"][values.deliveryStatus]}
            </span>
          </Col>
        )}
        <Col md="6" className="mb-3">
          <label htmlFor="deliveryStatus" style={{ display: "block" }}>
            Valor total do empenho:
          </label>
          {productsSum()}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-6">
          <TextArea
            isQuickView={isQuickView}
            name="observations1"
            label="Local de entrega:"
            placeHolder="Local de entrega"
            value={values.observations1}
            onChange={(evt: any) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.observations1 && touched.observations1 && (
            <div className="error-div">{errors.observations1}</div>
          )}
        </Col>
        <Col md="6" className="mb-6">
          <TextArea
            isQuickView={isQuickView}
            name="observations2"
            label="Observações:"
            placeHolder="Observações"
            value={values.observations2}
            onChange={(evt: any) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.observations2 && touched.observations2 && (
            <div className="error-div">{errors.observations2}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <ItemList
            isQuickView={isQuickView}
            isEdit={isEdit}
            items={
              relatedProducts && values.auction && values.auction.data.items
            }
            relatedProducts={relatedProducts}
            setPawnItemsQuantity={setPawnItemsQuantity}
            setVoltageValue={setValueVoltage}
            values={values}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {errors.pawnItemsQuantity && (
            <div className="error-div">{errors.pawnItemsQuantity}</div>
          )}
          {errors.voltageValue && (
            <div className="error-div">{errors.voltageValue}</div>
          )}
        </Col>
      </Row>

      {!isQuickView && (
        <Row>
          <Col className="text-right">
            <Button
              type="submit"
              color="success"
              disabled={isSubmitting || !pawnNumberPermited || validating}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      )}
    </form>
  );
};

export default PawnForm;