import * as firebase from "firebase";

const prod = {
  apiKey: "AIzaSyDUin-N9Wdf-2DxBYzXNiqjw2GpHjmX-nA",
  authDomain: "ecoblu-265911.firebaseapp.com",
  databaseURL: "https://ecoblu-265911.firebaseio.com",
  projectId: "ecoblu-265911",
  storageBucket: "ecoblu-265911.appspot.com",
  messagingSenderId: "663393324269",
  appId: "1:663393324269:web:a59c07f9aa0384a83990e4",
  measurementId: "G-D74HL73LS7",
};

const homolog = {
  apiKey: "AIzaSyBrpz-23-FdqfRi_yQimcY2yym7pA4TwEs",
  authDomain: "ecoblu-homolog.firebaseapp.com",
  databaseURL: "https://ecoblu-homolog.firebaseio.com/",
  projectId: "ecoblu-homolog",
  storageBucket: "ecoblu-homolog.appspot.com",
  messagingSenderId: "428159244422",
  appId: "1:428159244422:web:2b2af38917872e00d7d907",
};

firebase.initializeApp(prod);
export default firebase;
