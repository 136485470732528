import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetBrands = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [brands, setBrands] = useState([] as any);
  const [brandsNames, setBrandsNames] = useState([] as any);
  const [loadingBrands, setLoadingBrands] = useState(false);

  useEffect(() => {
    setLoadingBrands(true);
    db.collection("brands")
      .get()
      .then((querySnapshot) => {
        const brands = querySnapshot.docs.map((doc) => doc.data());
        setBrands(brands);
        setBrandsNames(brands.map((brand) => brand.name.toLowerCase()));
        setLoadingBrands(false);
      })
      .catch((error) => {
        setLoadingBrands(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingBrands, brands, brandsNames };
};

export default useGetBrands;
