const formatCompaniesOptions = (companiesInvolved) => {
  let companiesOptions = [{ value: "", label: "Todas" }];
  Object.values(companiesInvolved).forEach((product) => {
    companiesOptions.push({ value: product.id, label: product.name });
  }, []);
  return companiesOptions;
};

const formatVoltagesInvolved = (voltagesInvolved) => {
  let voltagesOptions = [{ value: "", label: "Todas" }];
  Object.values(voltagesInvolved).forEach((voltage) => {
    voltagesOptions.push({ value: voltage.value, label: voltage.label });
  }, []);
  return voltagesOptions;
};

const formatInstalledsInvolved = (installedsInvolved) => {
  let installedsOptions = [{ value: "", label: "Todos" }];
  Object.values(installedsInvolved).forEach((installed) => {
    installedsOptions.push({ value: installed.value, label: installed.label });
  });
  return installedsOptions;
};

const formatStatusInvolved = (installedsInvolved) => {
  let installedsOptions = [{ value: "", label: "Todos" }];
  Object.values(installedsInvolved).forEach((installed) => {
    installedsOptions.push({ value: installed.value, label: installed.label });
  });
  return installedsOptions;
};

const formatProductsOptions = (productsInvolved, brand) => {
  let productsOptions = [
    {
      value: "",
      label: "Todos",
    },
  ];

  Object.values(productsInvolved).forEach((product) => {
    if (!!brand) {
      if (product.brand.id === brand)
        productsOptions.push({
          value: product,
          label: `${product.name}, ${product.brand.name}, ${product.info} ${product.unitOfMeasurement.name}`,
        });
    } else {
      productsOptions.push({
        value: product,
        label: `${product.name}, ${product.brand.name}, ${product.info} ${product.unitOfMeasurement.name}`,
      });
    }
  });
  return productsOptions;
};

const formatBrandsOptions = (products) => {
  const brandsIds = [];
  let brandsOptions = [
    {
      value: "",
      label: "Todas",
    },
  ];
  Object.values(products).forEach((product) => {
    if (brandsIds.indexOf(product.brand.id) < 0) {
      brandsIds.push(product.brand.id);
      brandsOptions.push({
        value: product.brand.id,
        label: product.brand.name,
      });
    }
  }, []);
  return brandsOptions;
};

export {
  formatCompaniesOptions,
  formatProductsOptions,
  formatVoltagesInvolved,
  formatBrandsOptions,
  formatInstalledsInvolved,
  formatStatusInvolved,
};
