const calendarLabel = "Calendário";
const roleDescription = "selecionador de data";
const closeDatePicker = "Fechar";
const focusStartDate =
  "Interaja com o calendário e adicione a data de entrada.";
const clearDate = "Apagar data";
const clearDates = "Apagar datas";
const jumpToPrevMonth = "Voltar para trocar para o mês anterior.";
const jumpToNextMonth = "Prosseguir para trocara para o próximo mês.";
const keyboardShortcuts = "Atalhos do teclado";
const showKeyboardShortcutsPanel = "Abrir o painel dos atalhos do teclado.";
const hideKeyboardShortcutsPanel = "Fechar o painel dos atalhos do teclado.";
const openThisPanel = "Abrir este painel.";
const enterKey = "Enter key";
const leftArrowRightArrow = "Setas para direita e para esquerda";
const upArrowDownArrow = "Setas para cima e para baixo";
const pageUpPageDown = "teclas de page up e page down";
const homeEnd = "teclas home e end";
const escape = "tecla esc";
const questionMark = "tecla de interrogação";
const selectFocusedDate = "Selecionar a data em foco.";
const moveFocusByOneDay =
  "Mover para trás (esquerda) e para frente (direita) em um dia.";
const moveFocusByOneWeek =
  "Mover para trás (cima) e para frente (baixo) em uma semana.";
const moveFocusByOneMonth = "Trocar de meses.";
const moveFocustoStartAndEndOfWeek =
  "Ir para o primeiro ou último dia de uma semana.";
const returnFocusToInput = "Retornar para o campo de entrada da data.";
const keyboardForwardNavigationInstructions =
  "Navegue para frente para interagir com o calendário e selecionar uma data. Pressione o sinal de interrogação para ir para os atalhos do teclado.";
const keyboardBackwardNavigationInstructions =
  "Navegue para trás para interagir com o calendário e selecionar uma data. Pressione o sinal de interrogação para ir para os atalhos do teclado.";

const chooseAvailableStartDate = ({ date }) =>
  `Choose ${date} as your check-in date. It’s available.`;
const chooseAvailableEndDate = ({ date }) =>
  `Choose ${date} as your check-out date. It’s available.`;
const chooseAvailableDate = ({ date }) => date;
const dateIsUnavailable = ({ date }) => `Not available. ${date}`;
const dateIsSelected = ({ date }) => `Selected. ${date}`;
const dateIsSelectedAsStartDate = ({ date }) =>
  `Selected as start date. ${date}`;
const dateIsSelectedAsEndDate = ({ date }) => `Selected as end date. ${date}`;

export default {
  calendarLabel,
  roleDescription,
  closeDatePicker,
  focusStartDate,
  clearDate,
  clearDates,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardForwardNavigationInstructions,
  keyboardBackwardNavigationInstructions,

  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
  dateIsSelected,
  dateIsSelectedAsStartDate,
  dateIsSelectedAsEndDate,
};

export const DateRangePickerPhrases = {
  calendarLabel,
  roleDescription,
  closeDatePicker,
  clearDates,
  focusStartDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardForwardNavigationInstructions,
  keyboardBackwardNavigationInstructions,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
  dateIsSelected,
  dateIsSelectedAsStartDate,
  dateIsSelectedAsEndDate,
};

export const DateRangePickerInputPhrases = {
  focusStartDate,
  clearDates,
  keyboardForwardNavigationInstructions,
  keyboardBackwardNavigationInstructions,
};

export const SingleDatePickerPhrases = {
  calendarLabel,
  roleDescription,
  closeDatePicker,
  clearDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardForwardNavigationInstructions,
  keyboardBackwardNavigationInstructions,
  chooseAvailableDate,
  dateIsUnavailable,
  dateIsSelected,
};

export const SingleDatePickerInputPhrases = {
  clearDate,
  keyboardForwardNavigationInstructions,
  keyboardBackwardNavigationInstructions,
};

export const DayPickerPhrases = {
  calendarLabel,
  roleDescription,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  chooseAvailableDate,
  dateIsUnavailable,
  dateIsSelected,
  dateIsSelectedAsStartDate,
  dateIsSelectedAsEndDate,
};

export const DayPickerKeyboardShortcutsPhrases = {
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
};

export const DayPickerNavigationPhrases = {
  jumpToPrevMonth,
  jumpToNextMonth,
};

export const CalendarDayPhrases = {
  chooseAvailableDate,
  dateIsUnavailable,
  dateIsSelected,
  dateIsSelectedAsStartDate,
  dateIsSelectedAsEndDate,
};
