import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { withFormik } from "formik";
import * as Yup from "yup";
import ItemForm from "./ItemForm";

const ItemModal = (props: any) => {
  const { itemsModal, toggleItemsModal, addItem, editItem } = props;

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      product: Yup.string().required("Selecione um produto"),
      totalUnits: Yup.string().required("Insira a quantidade de itens"),
      unitPrice: Yup.string().required("Insira o preço do item"),
      installed: Yup.string().required("Selecione uma instalação"),
    }),
    validate: (values) => {
      const errors: any = {};
      if (values.totalUnits == 0)
        errors.totalUnits = "Adicione ao menos um objeto";

      if (values.unitPrice == 0)
        errors.unitPrice = "O preço deve ser maior que 0";
      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      let id;
      if (editItem) id = values.id;

      const newItem = {
        product: values.product.data,
        totalUnits: parseInt(values.totalUnits),
        unitPrice: parseInt(values.unitPrice),
        installed: values.installed,
        id,
      };

      addItem(newItem, editItem ? true : false);
      if (editItem) toggleItemsModal();
    },
    displayName: "ItemForm",
    mapPropsToValues: () => {
      let data: any = {
        product: "",
        totalUnits: 0,
        unitPrice: 0,
        installed: "",
        id: "",
      };
      if (editItem) {
        data = {
          product: {
            data: editItem.product,
            label: editItem.product.name,
          },
          totalUnits: editItem.totalUnits,
          unitPrice: editItem.unitPrice,
          installed: editItem.installed,
          id: editItem.id,
        };
      }
      return data;
    },
  });

  const MyEnhancedForm: any = formikEnhancer(ItemForm);

  return (
    <Modal isOpen={itemsModal} toggle={toggleItemsModal} size="lg">
      <ModalHeader toggle={toggleItemsModal}>
        {editItem ? "Editar" : "Adicionar"} item
      </ModalHeader>
      <ModalBody>
        <MyEnhancedForm editItem={editItem} />
      </ModalBody>
    </Modal>
  );
};

export default ItemModal;
