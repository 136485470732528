import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import PackingListDeliveryForm from "./PackingListDeliveryForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import firebase from "config/firebase";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import updatePackingListData from "./updatePackingListData";
import { CenteredSpinner } from "components";
import dispatch, { EditActions } from "actions";

const PackingListDelivery = ({ quickViewDelivery }) => {
  const history = useHistory();
  const location = useLocation();

  const locState = location?.state;

  const isQuickView = !!quickViewDelivery;
  const currentDelivery = isQuickView
    ? quickViewDelivery
    : location?.state?.row;
  const id = currentDelivery?.id;
  const isEdit = !!id;
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const pawn = isEdit ? currentDelivery.pawn : locState?.pawn;

  const [relatedProducts, setRelatedProducts] = useState(
    locState?.relatedProducts
  );

  const [packingList, setPackingList] = useState(locState?.packingList);
  const [packingListSelected, setPackingListSelected] = useState(
    locState?.packingListSelected
  );

  const deliveryItemsQuantity = isEdit
    ? currentDelivery.deliveryItemsQuantity
    : locState?.packingListSelected?.packingListItemsQuantity;
  const [isLoading, setLoading] = useState(true);

  const getRelatedDeliveries = () => {
    const auctionId = pawn.auction.id;
    db.collection("deliveries")
      .where("pawn.auction.id", "==", auctionId)
      .get()
      .then((querySnapshot) => {
        let relatedDeliveries = querySnapshot.docs.map((doc) => doc.data());
        calculateDeliveredItems(relatedDeliveries);
        setLoading(false);
      })
      .catch((error) => {
        alert("Não foi possível carregar os itens entregues");
        console.log("erro: ", error);
      });
  };

  const calculateDeliveredItems = (relatedDeliveries) => {
    const auctionRef = db.collection("auctions").doc(pawn.auction.id);
    let deliveryItemsQuantity = {};
    const totalUnit = Object.values(pawn.auction.items);
    let totalDelivered = 0;
    relatedDeliveries.forEach((relatedDelivery) => {
      deliveryItemsQuantity = relatedDelivery.deliveryItemsQuantity;
      Object.values(deliveryItemsQuantity).forEach((item) => {
        totalDelivered += item;
      });
    });
    const totalItems = totalUnit.reduce(
      (acc, item) => acc + item.totalUnits,
      0
    );
    let status = totalItems !== totalDelivered ? 1 : 2;
    auctionRef.update({ statusAuction: status });
  };

  useEffect(() => {
    if (isEdit) {
      loadPackingList();
      priorLoadProducts(currentDelivery.pawn.auction.items);
    }
  }, []);

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      nfe: Yup.string().required("Preencha o número de série/NFe"),
      deliveryDate: Yup.string().required("Selecione a data da entrega"),
      deliveredTo: Yup.string().required("Preencha o nome do receptor"),
      document: Yup.string().required("Preencha o documento do receptor"),
      deliveredBy: Yup.string().required("Preencha o nome do entregador"),
    }),
    handleSubmit: (values, { setSubmitting }) => {
      const packingListRef = db.collection("packingLists").doc(packingList.id);

      const newDelivery = {
        packingList: {
          id: packingList.id,
          packingListRef,
          number: packingList.number,
          packingListSelectedId: packingListSelected.id,
        },
        pawn,
        deliveryItemsQuantity,
        nfe: values.nfe,
        nfeUrl: values.nfeUrl,
        deliveryDate: values.deliveryDate,
        deliveredTo: values.deliveredTo,
        deliveredBy: values.deliveredBy,
        document: values.document,
        number: values.number,
      };
      const docId = isEdit ? id : db.collection("deliveries").doc().id;
      newDelivery["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newDelivery["createdAt"] = values["createdAt"];
        newDelivery["updatedAt"] = serverTimestamp;
      } else {
        newDelivery["createdAt"] = serverTimestamp;
      }

      const counterRef = db.collection("generalSettings").doc("counters");
      const docRef = db.collection("deliveries").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newDelivery["number"],
        collection: "deliveries",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      // info para atualizar os status no packingList
      const { packingListArray, packingListStatus } = updatePackingListData({
        packingList,
        packingListSelectedId: packingListSelected.id,
        type: "deliver",
        deliveryId: docId,
      });

      let action;
      if (isEdit) {
        let batch = db.batch();
        batch.update(docRef, newDelivery);
        batch.update(packingListRef, {
          status: packingListStatus,
          packingListArray,
        });
        log["action"] = "update";
        batch.set(logRef, log);
        action = batch.commit();
      } else {
        action = db.runTransaction(function (transaction) {
          return transaction.get(counterRef).then(function (countersDoc) {
            const newDeliveryCount = countersDoc.data().deliveries + 1;
            newDelivery["number"] = newDeliveryCount;
            log["reference"] = newDeliveryCount;
            transaction.set(docRef, newDelivery);
            transaction.update(counterRef, { deliveries: newDeliveryCount });
            transaction.update(packingListRef, {
              status: packingListStatus,
              packingListArray,
            });
            transaction.set(logRef, log);
          });
        });
      }

      action
        .then(() => {
          console.log("Entrega salva com sucesso");
          setSubmitting(false);
          history.goBack();
          getRelatedDeliveries();
        })
        .catch(function (error) {
          alert("Erro ao salvar entrega");
          console.error("Erro ao salvar entrega: ", error);
          setSubmitting(false);
        });

      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "PackingListDeliveryForm",
    mapPropsToValues: () => {
      let data = {
        id: "",
        nfe: "",
        nfeUrl: "",
        deliveryDate: "",
        deliveredTo: "",
        createdAt: "",
        number: "",
        deliveredBy: "",
        document: "",
      };
      if (isEdit) {
        data = {
          id: currentDelivery.id,
          deliveryItemsQuantity: currentDelivery.deliveryItemsQuantity,
          nfe: currentDelivery.nfe,
          nfeUrl: currentDelivery.nfeUrl ? currentDelivery.nfeUrl : "",
          deliveryDate: currentDelivery.deliveryDate,
          deliveredTo: currentDelivery.deliveredTo,
          createdAt: currentDelivery.createdAt,
          number: currentDelivery.number,
          packingList: currentDelivery.packingList,
          packingListSelected: currentDelivery.packingListSelected,
          pawn: currentDelivery.pawn,
          deliveredBy: currentDelivery.deliveredBy
            ? currentDelivery.deliveredBy
            : "",
          document: currentDelivery.document ? currentDelivery.document : "",
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(PackingListDeliveryForm);

  const priorLoadProducts = (items) => {
    loadProducts(items).then((prodSnapshots) => {
      let products = {};
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setRelatedProducts(products);
    });
  };

  const loadProducts = (itemsWithProducts) => {
    const promises = [];
    // let deliveryItemsQuantity = {};

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      promises.push(item.product.get());
      // deliveryItemsQuantity[item.id] = 0;
    });

    // if (!isEdit) setFieldValue("deliveryItemsQuantity", deliveryItemsQuantity);

    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const loadPackingList = () => {
    currentDelivery.packingList.packingListRef.get().then((snap) => {
      const packingList = snap.data();
      if (!!packingList) {
        setPackingList(packingList);

        const packingListSelected = packingList.packingListArray.find(
          (item) =>
            item.id === currentDelivery.packingList.packingListSelectedId
        );
        packingListSelected && setPackingListSelected(packingListSelected);
      }
    });
  };

  const body = () => {
    return pawn && relatedProducts && packingList && packingListSelected ? (
      <MyEnhancedForm
        getRelatedDeliveries={getRelatedDeliveries}
        id={id}
        isEdit={isEdit}
        pawn={pawn}
        packingList={packingList}
        packingListSelected={packingListSelected}
        relatedProducts={relatedProducts}
        deliveryItemsQuantity={deliveryItemsQuantity}
        isQuickView={isQuickView}
      />
    ) : (
      <CenteredSpinner />
    );
  };

  return (
    <div className="animated">
      {isQuickView ? (
        body()
      ) : (
        <Card>
          <CardHeader>
            {`Entrega ${isEdit ? "#" + currentDelivery.number : ""}`}
          </CardHeader>
          <CardBody>{body()}</CardBody>
        </Card>
      )}
    </div>
  );
};

export default PackingListDelivery;
