import React, { useEffect, useState } from "react";
import { CustomReactSelect, InputAndLabel } from "components";
import { Button, Col, Row } from "reactstrap";
import { useGetPawns } from "hooks";
import { formatOptions } from "utils/general";
import ItemList from "./ItemList";
import firebase from "config/firebase";

const DeliveryForm = (props: any) => {
  const { pawns } = useGetPawns();
  const [relatedProducts, setRelatedProducts] = useState<any>(null);
  const db = firebase.firestore();

  const isQuickView = true;

  const {
    values,
    touched,
    // dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    // handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isEdit,
  } = props;

  useEffect(() => {
    if (isEdit) {
      priorLoadProducts(values.pawn.data.auction.items);
      loadRelatedDeliveries(values.pawn.data.id);
    }
  }, []);

  const priorLoadProducts = (items: any) => {
    loadProducts(items).then((prodSnapshots: any) => {
      let products: any = {};
      prodSnapshots.forEach((prodSnap: any) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setRelatedProducts(products);
    });
  };

  const loadProducts = (itemsWithProducts: any) => {
    const promises: any = [];
    let deliveryItemsQuantity: any = {};

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      promises.push(item.product.get());
      deliveryItemsQuantity[item.id] = 0;
    });
    if (!isEdit) setFieldValue("deliveryItemsQuantity", deliveryItemsQuantity);

    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const setDeliveryItemsQuantity = (id: string, quantity: number) => {
    setFieldValue("deliveryItemsQuantity", {
      ...values.deliveryItemsQuantity,
      [id]: quantity,
    });
  };

  const loadRelatedDeliveries = (pawnId: string) => {
    db.collection("deliveries")
      .where("pawn.id", "==", pawnId)
      .get()
      .then((querySnapshot) => {
        let relatedDeliveries = querySnapshot.docs.map((doc) => doc.data());
        calculateDeliveredItems(relatedDeliveries);
      })
      .catch((error) => {
        console.log("erro: ", error);
      });
  };

  const calculateDeliveredItems = (relatedDeliveries: any) => {
    let previousDeliveryItems: any = {};
    relatedDeliveries.forEach((relatedDelivery: any) => {
      if (relatedDelivery.id !== values.id) {
        let deliveryItemsQuantity = relatedDelivery.deliveryItemsQuantity;
        for (const deliveryItemId in deliveryItemsQuantity) {
          if (deliveryItemsQuantity.hasOwnProperty(deliveryItemId)) {
            if (previousDeliveryItems[deliveryItemId]) {
              previousDeliveryItems[deliveryItemId] +=
                deliveryItemsQuantity[deliveryItemId];
            } else {
              previousDeliveryItems[deliveryItemId] =
                deliveryItemsQuantity[deliveryItemId];
            }
          }
        }
      }
    });
    setFieldValue("previousDeliveryItems", previousDeliveryItems);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="12" className="mb-3">
          <CustomReactSelect
            isQuickView={isQuickView}
            label="Empenho | Número | Pregão | Órgão | Empresa"
            field="pawn"
            value={values.pawn}
            onChange={(field: any, newValue: any) => {
              setRelatedProducts(null);
              setFieldValue(field, newValue);
              priorLoadProducts(newValue.data.auction.items);
              loadRelatedDeliveries(newValue.data.id);
            }}
            onBlur={setFieldTouched}
            error={errors.pawn}
            touched={touched.pawn}
            options={formatOptions(pawns, "pawn")}
          />
        </Col>
      </Row>

      <Row>
        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="nfe"
          label="Número da série/NFe:"
          type="text"
          value={values.nfe}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={errors.nfe && touched.nfe && errors.nfe}
        />

        <InputAndLabel
          isQuickView={isQuickView}
          col={3}
          name="deliveryDate"
          label="Data da entrega:"
          type="date"
          value={values.deliveryDate}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={
            errors.deliveryDate && touched.deliveryDate && errors.deliveryDate
          }
        />

        <InputAndLabel
          isQuickView={isQuickView}
          col={6}
          name="deliveredTo"
          label="Entregue para:"
          type="text"
          value={values.deliveredTo}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={
            errors.deliveredTo && touched.deliveredTo && errors.deliveredTo
          }
        />
      </Row>

      <Row>
        <Col>
          <ItemList
            isEdit={isEdit}
            items={
              relatedProducts && values.pawn && values.pawn.data.auction.items
            }
            relatedProducts={relatedProducts}
            setDeliveryItemsQuantity={setDeliveryItemsQuantity}
            values={values}
            isQuickView={isQuickView}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {errors.deliveryItemsQuantity && (
            <div className="error-div">{errors.deliveryItemsQuantity}</div>
          )}
        </Col>
      </Row>

      {!isQuickView && (
        <Row>
          <Col className="text-right">
            <Button type="submit" color="success" disabled={isSubmitting}>
              Salvar
            </Button>
          </Col>
        </Row>
      )}
    </form>
  );
};

export default DeliveryForm;
