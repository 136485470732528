import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { CenteredSpinner, CustomReactSelect } from "components";
import { DateRangePicker } from "react-dates";
import { useGetAuctions } from "hooks";
import { money } from "utils/mask";

import ptBrPhrases from "utils/general/ptBrPhrases";
import ReportTable from "./ReportTable";
import firebase from "config/firebase";

const UserListReport = () => {
  const db = firebase.firestore();

  const [error, setError] = useState(false);
  const [report, setReport] = useState([]);
  const [sellersBackup, setSellersBackup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nameOfSeller, setNameOfSeller] = useState({
    value: "",
    label: "Todos",
  });
  const [sellersOptions, setSellersOptions] = useState({
    value: "",
    label: "Todos",
  });
  const [totalOfAuctions, setTotalOfAuctions] = useState(0);
  const [viewTotalOfAuctions, setViewTotalOfAuctions] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState("startDate");

  const { loadingAuctions, auctions } = useGetAuctions(
    "",
    startDate,
    endDate,
    false
  );

  useEffect(() => {
    if (endDate) {
      createSellerData();
    }
  }, [auctions]);

  useEffect(() => {
    const filteredSellers = sellersBackup.filter(
      (seller) => seller.name === nameOfSeller.label
    );
    if (filteredSellers.length > 0 && nameOfSeller.value !== "") {
      setReport(filteredSellers);
      setViewTotalOfAuctions(false);
    } else {
      setReport(sellersBackup);
      setViewTotalOfAuctions(sellersBackup.length === 0 ? false : true);
    }
  }, [nameOfSeller]);

  const createSellerData = async () => {
    let sellers = [];
    let finallySellersList = [];
    let sellerOptionList = [sellersOptions];
    let countPriceOfAuctions = 0;

    setIsLoading(true);
    await db
      .collection("users")
      .where("role", "==", "seller")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sellers.push({ name: doc.data().name, uid: doc.data().uid });
        });
      })
      .catch((error) => {
        setError(true);
      });

    sellers.map((seller) => {
      let itemsCounter = 0;
      let itemsOfTotalValue = 0;

      const filteredAuctions = auctions.filter(
        (auction) => seller.uid === auction?.sellers?.value
      );

      if (filteredAuctions) {
        filteredAuctions.map((auction) => {
          itemsCounter += Object.keys(auction.items).length;
          Object.keys(auction.items).forEach((key) => {
            countPriceOfAuctions +=
              auction.items[key].unitPrice * auction.items[key].totalUnits;
            itemsOfTotalValue +=
              auction.items[key].unitPrice * auction.items[key].totalUnits;
          });
        });

        finallySellersList.push({
          name: seller.name,
          quantity: itemsCounter,
          totalValue: money(itemsOfTotalValue),
          averageTotal: money(itemsOfTotalValue / itemsCounter),
          totalAuctions: filteredAuctions.length,
        });

        sellerOptionList.push({
          value: seller.uid,
          label: seller.name,
        });
      }
    });

    finallySellersList.sort(function(a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });

    setIsLoading(false);
    setViewTotalOfAuctions(true);
    setReport(finallySellersList);
    setSellersBackup(finallySellersList);
    setSellersOptions(sellerOptionList);
    setTotalOfAuctions(countPriceOfAuctions);
  };

  const resetFilters = () => {
    setSellersBackup([]);
    setSellersOptions({ value: "", label: "Todos" });
    setNameOfSeller({ value: "", label: "Todos" });
    setTotalOfAuctions(0);
    setViewTotalOfAuctions(false);
    setStartDate();
    setEndDate();
    setFocusedInput("startDate");
  };

  return (
    <div className="animated">
      <Card>
        <CardHeader>Relatório de Vendedores</CardHeader>
        <CardBody>
          <Col className="d-print-none">
            <Row className="mt-3">
              <Col>
                <div className="mb-2">
                  <b>Período</b>
                </div>
                <DateRangePicker
                  startDate={startDate}
                  startDateId="startDate"
                  endDate={endDate}
                  endDateId="endDate"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  orientation={"vertical"}
                  openDirection={"down"}
                  displayFormat={"DD-MM-YYYY"}
                  isOutsideRange={() => false}
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  phrases={ptBrPhrases}
                />
              </Col>

              <Col>
                <CustomReactSelect
                  label="Selecione o vendedor"
                  field="seller"
                  value={nameOfSeller}
                  onChange={(field, newValue) => {
                    setNameOfSeller(newValue);
                  }}
                  options={sellersOptions}
                />
              </Col>

              <Col className="text-right mt-4">
                <Button color="info" onClick={() => window.print()}>
                  Imprimir
                </Button>
              </Col>
            </Row>
            <Col className="mt-3">
              <Button className="px-0" color="link" onClick={resetFilters}>
                apagar filtros
              </Button>
            </Col>
          </Col>

          {error && (
            <Row className="mt-4">
              <Col className="text-center">
                <h4>Não foi possível concluir sua solicitação</h4>
                <br />
                Por favor tente novamente
              </Col>
            </Row>
          )}
          {!error && (
            <Row className="mt-4">
              {isLoading ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <ReportTable data={report} />
                  {viewTotalOfAuctions && (
                    <p className="text-right">
                      <b>Total Pregões: </b>
                      {money(totalOfAuctions)}
                    </p>
                  )}
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default UserListReport;
