import { createReducer } from "typesafe-actions";
import { FilterActions } from "actions";
import { savePawnListReceivedAtDates } from "../actions/filter";

const {
    savePawnListFilters,
} = FilterActions;

const initialState = {
  filters: {
      deliveryStatus: {value: 0, label: "Pendente"}
  },
  receivedAtDates: {}
};

const reducer = createReducer(initialState)
  .handleAction(savePawnListFilters, (state, { payload }) => ({
    ...state,
    filters: payload,
  }))
  .handleAction(savePawnListReceivedAtDates, (state, { payload }) => ({
    ...state,
    receivedAtDates: payload,
  }));

export default reducer;
