import React, { Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import CompanyForm from "./CompanyForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import { removeMask } from "utils/mask";
import { cpf, cnpj } from "cpf-cnpj-validator";

import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useGetCompanies } from "hooks";
import { CenteredSpinner } from "components";

const CompanyDetails = ({ isModal = false, closeModal }) => {
  const history = useHistory();
  const location = useLocation();
  const row = idx(location, (_) => _.state.row);
  const isEdit =
    !isModal && idx(location, (_) => _.state.row.id) ? true : false;
  const id = row?.id;
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const { loadingCompanies, companies, companiesCnpj } = useGetCompanies();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Insira o nome da empresa"),
      cpfcnpj: Yup.string().required("Insira o CPF ou o CNPJ da empresa"),
      state: Yup.string().required("Escolha um estado"),
      city: Yup.string().required("Escolha uma cidade"),
    }),

    validate: (values) => {
      const errors = {};
      if (values.cpfcnpj) {
        if (!cnpj.isValid(values.cpfcnpj) || !cpf.isValid(values.cpfcnpj)) {
          errors.cpfcnpj = "Por favor insira um CPF ou CNPJ válido";
        }
        if (companiesCnpj) {
          if (companiesCnpj.indexOf(removeMask(values.cpfcnpj)) > -1) {
            errors.cpfcnpj = "CPF ou CNPJ já registrado";
          }
        }
      }
      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newCompany = {
        name: values.name,
        cpfcnpj: removeMask(values.cpfcnpj),
        state: {
          name: values.state.data.name,
          value: values.state.data.initials,
        },
        city: values.city.data,
      };

      const docId = isEdit ? id : db.collection("companies").doc().id;
      newCompany["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newCompany["createdAt"] = values["createdAt"];
        newCompany["updatedAt"] = serverTimestamp;
      } else {
        newCompany["createdAt"] = serverTimestamp;
      }

      const docRef = db.collection("companies").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newCompany["name"],
        collection: "companies",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let batch = db.batch();
      if (isEdit) {
        log["action"] = "update";
        batch.update(docRef, newCompany);
      } else {
        batch.set(docRef, newCompany);
      }
      batch.set(logRef, log);

      batch
        .commit()
        .then(() => {
          console.log("Empresa salva com sucesso");
          setSubmitting(false);
          {
            isModal ? closeModal() : history.goBack();
          }
        })
        .catch(function(error) {
          alert("Erro ao salvar empresa");
          console.error("Erro ao salvar empresa: ", error);
          setSubmitting(false);
        });
    },
    displayName: "CompanyForm",
    mapPropsToValues: () => {
      let data = {
        name: "",
        cpfcnpj: "",
        createdAt: "",
        state: "",
        city: "",
      };
      if (isEdit) {
        data = {
          name: row.name,
          cpfcnpj: row.cpfcnpj.toString(),
          createdAt: row.createdAt,
          state: {
            label: row.state.name,
            value: row.state.value,
            data: {
              name: row.state.name,
              initials: row.state.value,
            },
          },
          city: { label: row.city, data: row.city },
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(CompanyForm);

  if (isModal)
    return (
      <Fragment>
        {loadingCompanies ? (
          <CenteredSpinner />
        ) : (
          <MyEnhancedForm isEdit={isEdit} />
        )}
      </Fragment>
    );

  return (
    <div className="animated">
      <Card>
        <CardHeader>Empresa</CardHeader>
        <CardBody>
          {loadingCompanies ? (
            <CenteredSpinner />
          ) : (
            <MyEnhancedForm isEdit={isEdit} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CompanyDetails;
