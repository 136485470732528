const voltageOptions = (input) => {
  const voltage = {
    "110": "110v",
    "220": "220v",
    "380": "380v",
    "110v": "110v",
    "220v": "220v",
    "380v": "380v",
    Bivolt: "Bivolt",
    bivolt: "Bivolt",
    unknown: "Indefinido",
  };
  return voltage[input];
};

export default voltageOptions;
