import React from "react";
import { CustomReactSelect } from "components";
import { Col, Row } from "reactstrap";

const Filters = (props: any) => {
  const {
    company,
    setCompany,
    companiesOptions,
    brand,
    setBrand,
    brandsOptions,
    product,
    setProduct,
    productsOptions,
    tradingType,
    setTradingType,
    tradingTypesOptions,
    contract,
    setContract,
    contractsOptions,
  } = props;

  return (
    <div className="mt-4">
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Empresa"
            field="company"
            value={company}
            onChange={(field: any, newValue: any) => {
              setCompany(newValue);
            }}
            options={companiesOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Marca"
            field="brand"
            value={brand}
            onChange={(field: any, newValue: any) => {
              setBrand(newValue);
            }}
            options={brandsOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mb-3">
          <CustomReactSelect
            label="Produto"
            field="product"
            value={product}
            onChange={(field: any, newValue: any) => {
              setProduct(newValue);
            }}
            options={productsOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Tipo de Licitação"
            field="tradingType"
            value={tradingType}
            onChange={(field: any, newValue: any) => {
              setTradingType(newValue);
            }}
            options={tradingTypesOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Contrato"
            field="contract"
            value={contract}
            onChange={(field: any, newValue: any) => {
              setContract(newValue);
            }}
            options={contractsOptions}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
