import React from "react";
import { Button, Col, Row } from "reactstrap";
import { CustomReactSelect } from "components";
import { statusOptions, userRolesOptions, yesAndNo } from "utils/general";

const UserForm = (props: any) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = props;


  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="name" style={{ display: "block" }}>
            Nome
          </label>
          <input
            id="name"
            placeholder="Nome"
            type="text"
            value={values.name}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.name && touched.name && (
            <div className="error-div">{errors.name}</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="email" style={{ display: "block" }}>
            E-mail
          </label>
          <input
            id="email"
            placeholder="E-mail"
            type="email"
            value={values.email}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
            disabled
          />
          {errors.email && touched.email && (
            <div className="error-div">{errors.email}</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Status"
            field="status"
            value={values.status}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
            }}
            onBlur={setFieldTouched}
            error={errors.status}
            touched={touched.status}
            options={statusOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Papel"
            field="role"
            value={values.role}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
            }}
            onBlur={setFieldTouched}
            error={errors.role}
            touched={touched.role}
            options={userRolesOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mb-3">
          <CustomReactSelect
            label="Permissão para Cadastro de Produtos"
            field="permissionProduct"
            value={values.permissionProduct}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
            }}
            onBlur={setFieldTouched}
            error={errors.permissionProduct}
            touched={touched.permissionProduct}
            options={yesAndNo}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-right">
          <Button type="submit" color="success" disabled={isSubmitting}>
            Salvar
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default UserForm;
