import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";

const PawnReport = ({ data }) => {
  const columns = [
    {
      sort: true,
      dataField: "name",
      text: "Vendedor",
      sortFunc: (a, b, order) => {
        if (order === "desc") {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
      },
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade de itens",
    },
    {
      sort: true,
      dataField: "totalAuctions",
      text: "Pregões no total",
    },
    {
      sort: true,
      dataField: "totalValue",
      text: "Valor total",
    },
    {
      sort: true,
      dataField: "averageTotal",
      text: "Valor médio",
    },
  ];

  const defaultSorted = [
    {
      dataField: "totalValue",
      order: "desc",
    },
  ];

  return (
    <Fragment>
      <BootstrapTable
        keyField={["name", "quantity", "totalAuctions"]}
        data={data}
        columns={columns}
        bordered={false}
        defaultSorted={defaultSorted}
        hover
      />
    </Fragment>
  );
};

export default PawnReport;
