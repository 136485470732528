import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import firebase from "config/firebase";
import dispatch, { UserActions } from "actions";
import { useHistory } from "react-router-dom";
import { notify } from "services";
import { CenteredSpinner } from "components";

const Login = () => {
  const db = firebase.firestore();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const sendUserToRoleHome = (role) => {
    const rolePaths = {
      admin: "/dashboard",
      seller: "/pregoes",
      pledger: "/empenhos",
      deliverer: "/entregas",
      financial: "/empenhos",
      collection: "/empenhos",
    };

    history.push(rolePaths[role]);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setLoading(false);
      if (user) {
        setLoading(true);
        const { uid } = user;
        db.collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            setLoading(true);
            if (doc.exists) {
              const data = doc.data();

              if (!data.role || !data.status) {
                history.push("/nenhum-privilegio");
                return;
              }

              dispatch(UserActions.saveUser, { ...data });
              sendUserToRoleHome(data.role);
            }
            !doc.exists && history.push("/registro");
          })
          .catch((error) => {
            console.log("erro:", error);

            notify({
              description: "Não foi possivel acessar seu usuário.",
              type: "danger",
            });
          });
      }
    });
  }, []);

  const validateLogin = (_) => {
    if (!email) {
      notify({
        description: "Por favor insira seu e-mail.",
        type: "danger",
      });
      return;
    }
    if (!password) {
      notify({
        description: "Por favor insira sua senha.",
        type: "danger",
      });
      return;
    }

    login();
  };

  const login = (_) => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setLoading(false);
        let alertString = "Erro ao logar. Por favor tente novamente.";

        if (error.code === "auth/user-not-found") {
          alertString = "Usuário não existente. Por favor tente novamente.";
        } else if (error.code === "auth/wrong-password") {
          alertString = "Senha incorreta. Por favor tente novamente.";
        }

        notify({
          description: alertString,
          type: "danger",
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      validateLogin();
    }
  };

  return (
    <div className="app flex-row align-items-center animated fadeIn">
      <Container>
        <Row className="justify-content-center">
          <Col md="12" lg="5">
            <Card className="p-4">
              <CardBody>
                <Form>
                  <h1>Login</h1>
                  <p className="text-muted">Entre na conta</p>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="cui-envelope-closed"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="E-mail"
                      autoComplete="email"
                      value={email}
                      onChange={(evt) => {
                        setEmail(evt.target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(evt) => {
                        setPassword(evt.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </InputGroup>
                  <Row className="align-items-center">
                    <Col xs="6">
                      <Button
                        color="link"
                        className="px-0"
                        onClick={() => {
                          history.push("/recuperar-senha");
                        }}
                      >
                        Esqueceu sua senha?
                      </Button>
                    </Col>
                    {loading ? (
                      <CenteredSpinner />
                    ) : (
                      <Col xs="6" className="text-right">
                        <Button
                          color="primary"
                          className="px-4"
                          onClick={validateLogin}
                        >
                          Entrar
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              color="link"
              className="px-0"
              onClick={() => {
                history.push("/registro");
              }}
            >
              registrar
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
