import { useState } from "react";
import firebase from "config/firebase";
import { notify } from "services";

const useGetOpenPackingLists = () => {
  const db = firebase.firestore();
  const [loadingPackingLists, setLoadingPackingLists] = useState(false);
  const [openPackingListsError, setOpenPackingListsError] = useState(false);

  const getOpenPackingLists = async () => {
    setLoadingPackingLists(true);
    const querySnapshot: any = await db
      .collection("packingLists")
      .where("status", "<", 2)
      .get()
      .catch((error) => {
        // setLoadingPackingLists(false);
        console.log("erro: ", error);
        setOpenPackingListsError(true);
        notify({
          description: "Não foi possivel baixar os dados .",
          type: "danger",
        });
      });

    setLoadingPackingLists(false);
    return querySnapshot.docs.map((doc: any) => doc.data());
  };

  return { loadingPackingLists, getOpenPackingLists, openPackingListsError };
};

export default useGetOpenPackingLists;
