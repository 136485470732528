import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PrintPLDeliveryItems from "./PrintPLDeliveryItems";
import "./style.css";

const PLDeliveryCardPrint = ({
  pawn,
  packingList,
  relatedProducts,
  isLast
}: any) => {

  useEffect(()=>{
    sessionStorage.clear();
  },[]);

  return (
    <Fragment>
      {pawn && (
        <div style={{ pageBreakInside: "avoid" }}>
          <Row className="mb-2 d-none d-print-block print-text">
            <Col> 
              <h3>
                {packingList.city}, {packingList.state.value}
              </h3>
              
              <strong>
                Número: {pawn.customPawnNumber}
                {" - "}
                {pawn.auction.governmentAgency.name}
              </strong>
              <br />
              Endereço: {packingList.address}
              <br />
              {packingList.observations && "Obs:"} {packingList.observations}
            </Col>
          </Row>
          <PrintPLDeliveryItems
            pawn={pawn}
            items={relatedProducts && pawn?.auction?.items}
            relatedProducts={relatedProducts}
            values={packingList}
          />
          {!isLast && <hr className="my-4 d-none d-print-block" />}
        </div>
      )}
    </Fragment>
  );
};

export default PLDeliveryCardPrint;
