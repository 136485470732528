import firebase from "config/firebase";

const getDeliveries = (startDate?: any, endDate?: any) => {
  const db = firebase.firestore();

  if (startDate && endDate) {
    return db.collection("deliveries")
      .where("deliveryDate", ">=", startDate.format("YYYY-MM-DD"))
      .where("deliveryDate", "<=", endDate.format("YYYY-MM-DD"))
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data())
      })
      .catch((error) => {
        console.log(error)
        return error
      });
  } else {
    return db.collection("deliveries")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data())
      })
      .catch((error) => {
        return error
      });
  }
};

export default getDeliveries;
