import React, { useEffect, useState, Fragment } from "react";
// import { CustomReactSelect, TextArea, InputAndLabel } from "components";
import { Button, Collapse, CardHeader, Card, CardBody, Col, Row } from "reactstrap";
import { useGetPackingListsByPawn } from "hooks";
import { PackingListDelivery, DeliveryDetails } from "screens";
// import PackingListDetailsInfo from "../../PackingList/PackingListList/Details/PackingListDetailsInfo";
import PackingListDetails from './PackingListDetails'

interface PackingListListInterface {
  pawnId: string;
}

const PackingListList = ({ pawnId }: PackingListListInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { loadingPackingLists, packingLists } = useGetPackingListsByPawn(pawnId);

  const [plCards, setPlCards] = useState([] as any);

  useEffect(() => {
    setPlCards(
      packingLists.map((pl: any) => (
        <Card>
          <CardHeader className="small-header-padding-pl d-flex d-print-none">Romaneio #{pl.number}</CardHeader>
          <CardBody>
            <PackingListDetails data={pl} />
          </CardBody>
        </Card>
      ))
    );
  }, [pawnId, packingLists]);

  return (
    <Fragment>
      {packingLists.length > 0 && (
        <Fragment>
          <Row>
            <Col className="text-center">
              <Button color="link" onClick={toggle} className="mb-2">
                <p>
                  {isOpen ? " Esconder romaneios" : " Exibir romaneios"}
                </p>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Collapse isOpen={isOpen}>{plCards}</Collapse>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PackingListList;
