import { createReducer } from "typesafe-actions";
import { LocationActions } from "actions";

const { saveCurrentState, clearCurrentState } = LocationActions;

const initialState = {
  currentState: "",
};

const reducer = createReducer(initialState)
  .handleAction(saveCurrentState, (state, { payload }) => ({
    ...state,
    currentState: payload,
  }))
  .handleAction(clearCurrentState, (state) => ({
    ...state,
    currentState: initialState.currentState,
  }));

export default reducer;
