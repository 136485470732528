import React, { useEffect, useState } from "react";
import { CustomReactSelect } from "components";
import { Button, Col, Row } from "reactstrap";
import { cnpj, cpf } from "utils/mask";
import dispatch, { LocationActions, EditActions } from "actions";
import { states, cities } from "utils/location";
import { useSelector } from "react-redux";
import { formatOptions } from "utils/general";

const GovernmentAgencyForm = (props: any) => {
  const currentState = useSelector((state: any) => state.location.currentState);

  const [isLoading, setLoading] = useState(true);

  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isEdit,
  } = props;

  useEffect(() => {
    if (isEdit) {
      dispatch(LocationActions.saveCurrentState, values.state.data);
    }

    return () => {
      dispatch(LocationActions.clearCurrentState);
    };
  }, []);

  useEffect(() => {
    if (isLoading) setLoading(false);
    if (!isLoading) dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="name" style={{ display: "block" }}>
            Nome
          </label>
          <input
            id="name"
            placeholder="Nome"
            type="text"
            value={values.name}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
            autoFocus
          />
          {errors.name && touched.name && (
            <div className="error-div">{errors.name}</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="cpfcnpj" style={{ display: "block" }}>
            CPF ou CNPJ
          </label>
          <input
            id="cpfcnpj"
            placeholder="CPF ou CNPJ "
            type="text"
            value={
              values.cpfcnpj.length < 14
                ? cpf(values.cpfcnpj)
                : cnpj(values.cpfcnpj)
            }
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
            maxLength={18}
          />
          {errors.cpfcnpj && touched.cpfcnpj && (
            <div className="error-div">{errors.cpfcnpj}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Estado"
            field="state"
            value={values.state}
            onChange={(field: any, newValue: any) => {
              dispatch(LocationActions.saveCurrentState, newValue.data);
              setFieldValue(field, newValue);
              setFieldValue("city", "");
            }}
            onBlur={setFieldTouched}
            error={errors.state}
            touched={touched.state}
            options={formatOptions(states, "states")}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Cidade"
            field="city"
            value={values.city}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            error={errors.city}
            touched={touched.city}
            options={
              currentState && formatOptions(cities(currentState), "cities")
            }
            isDisabled={currentState ? false : true}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-right">
          <Button type="submit" color="success" disabled={isSubmitting}>
            Salvar
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default GovernmentAgencyForm;
