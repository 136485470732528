import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetUsers = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [users, setUsers] = useState([] as any);
  const [usersNames, setUsersNames] = useState([] as any);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    setLoadingUsers(true);
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        const users = querySnapshot.docs.map((doc) => doc.data());
        setUsers(users);
        setUsersNames(users.map((user) => user.name));
        setLoadingUsers(false);
      })
      .catch((error) => {
        setLoadingUsers(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingUsers, users, usersNames };
};

export default useGetUsers;
