import React, { useEffect, useState, Fragment } from "react";
// import { CustomReactSelect, TextArea, InputAndLabel } from "components";
import { Button, Collapse, Card, CardBody, Col, Row } from "reactstrap";
import { useGetDeliveriesByPawn } from "hooks";
import { PackingListDelivery, DeliveryDetails } from "screens";

interface DeliveriesListInterface {
  pawnId: string;
}

const DeliveriesList = ({ pawnId }: DeliveriesListInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { pawnDeliveries } = useGetDeliveriesByPawn(pawnId);

  const [deliveriesCards, setDeliveryCards] = useState([] as any);

  useEffect(() => {
    setDeliveryCards(
      pawnDeliveries.map((delivery: any) => (
        <Card>
          <CardBody className="small-text">
            {!!delivery.packingList ? (
              <PackingListDelivery quickViewDelivery={delivery} />
            ) : (
              <DeliveryDetails quickViewDelivery={delivery} />
            )}
          </CardBody>
        </Card>
      ))
    );
  }, [pawnId, pawnDeliveries]);

  return (
    <Fragment>
      {!!pawnDeliveries.length && (
        <Fragment>
          <Row>
            <Col className="text-center">
              <Button color="link" onClick={toggle} className="mb-2">
                {isOpen ? "Esconder entregas" : "Exibir entregas"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Collapse isOpen={isOpen}>{deliveriesCards}</Collapse>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DeliveriesList;
