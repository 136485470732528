import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetAuctions } from "hooks";
import { CenteredSpinner } from "components";
import Filters from "./Filters";
import FiltersPrint from "./FiltersPrint";
import ReportTable from "./ReportTable";
import {
  formatCompaniesOptions,
  formatProductsOptions,
  formatTradingTypesInvolved,
  formatBrandsOptions,
} from "./formatters";

import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";

const AuctionReport = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState("startDate");

  const [company, setCompany] = useState({ value: "", label: "Todas" });
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [brand, setBrand] = useState({ value: "", label: "Todas" });
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [product, setProduct] = useState({ value: "", label: "Todos" });
  const [productsOptions, setProductsOptions] = useState([]);
  const [tradingType, setTradingType] = useState({ value: "", label: "Todos" });
  const [tradingTypesOptions, setTradingTypesOptions] = useState([]);
  const [contract, setContract] = useState({ value: "", label: "Todos" });
  const [contractsOptions, setContractsOptions] = useState([]);

  const { loadingAuctions, auctions } = useGetAuctions(
    "",
    startDate,
    endDate,
    true
  );

  const [totalAuctionsItems, setTotalAuctionsItems] = useState({});
  const [filteredAuctionsItems, setFilteredAuctionsItems] = useState({});
  const [
    filteredAuctionItemsQuantity,
    setFilteredAuctionItemsQuantity,
  ] = useState({});
  const [itemsByProducts, setItemsByProducts] = useState({});
  const [products, setProducts] = useState({});

  const [report, setReport] = useState([]);

  const resetFilters = () => {
    setCompany({ value: "", label: "Todas" });
    setBrand({ value: "", label: "Todas" });
    setProduct({ value: "", label: "Todos" });
    setTradingType({ value: "", label: "Todos" });
    setContractsOptions({ value: "", label: "Todos" });
  };
  const resetOptions = () => {
    setCompaniesOptions([]);
    setBrandsOptions([]);
    setProductsOptions([]);
    setTradingTypesOptions([]);
    setContractsOptions([]);
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    resetFilters();
    resetOptions();
    let companiesInvolved = {};
    let relatedProducts = {}; // produtos (modelo, marca, etc) para serem baixados depois
    let tradingTypesInvolved = {};
    let totalAuctionsItems = {}; // itens dos auctions (instalado, preço)
    let optionsContract = [{ value: "", label: "Todos" }];
    auctions.map((auction) => {
      companiesInvolved[auction.company.id] = auction.company;
      tradingTypesInvolved[auction.tradingType.value] = auction.tradingType;
      totalAuctionsItems = { ...totalAuctionsItems, ...auction.items };

      if (
        auction?.contract !== "" &&
        optionsContract.filter((option) => option.value === "Com contrato")
          .length === 0
      )
        optionsContract.push({ value: "Com contrato", label: "Com contrato" });
      else if (
        (!auction.contract || auction?.contract === "") &&
        optionsContract.filter((option) => option.value === "Sem contrato")
          .length === 0
      )
        optionsContract.push({ value: "Sem contrato", label: "Sem contrato" });
      Object.keys(auction.items).forEach((key) => {
        let auctionItem = auction.items[key];
        relatedProducts[auctionItem.product.id] = auctionItem.product;
      });
    });

    setCompaniesOptions(formatCompaniesOptions(companiesInvolved));
    setTradingTypesOptions(formatTradingTypesInvolved(tradingTypesInvolved));
    setContractsOptions(optionsContract);
    setTotalAuctionsItems(totalAuctionsItems);
    priorLoadProducts(relatedProducts);
  }, [auctions]);

  useEffect(() => {
    setLoading(true);
    setReport([]);
    let filteredAuctionsItems = {}; // itens dos auctions ( instalado, preço)
    auctions.map((auction) => {
      if (company?.value && auction.company.id != company.value) return;
      if (tradingType?.value && auction.tradingType.value != tradingType.value)
        return;

      if (contract.value !== "") {
        if (contract.value === "Sem contrato" && auction.contract.length > 0)
          return;
        if (contract.value === "Com contrato" && auction.contract.length === 0)
          return;
      }

      filteredAuctionsItems = {
        ...filteredAuctionsItems,
        ...auction.items,
      };
    });
    setFilteredAuctionsItems(filteredAuctionsItems);
  }, [totalAuctionsItems, company, tradingType, contract]);

  const priorLoadProducts = (items) => {
    loadProducts(items).then((prodSnapshots) => {
      let products = {};
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setProducts(products);
      setProductsOptions(formatProductsOptions(products));
      setBrandsOptions(formatBrandsOptions(products));
    });
  };

  const loadProducts = (relatedProducts) => {
    const promises = [];
    Object.keys(relatedProducts).forEach((key) => {
      let product = relatedProducts[key];
      promises.push(product.get());
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    let itemsByProducts = {};
    Object.keys(products).forEach((key) => {
      let product = products[key];
      Object.keys(filteredAuctionsItems).forEach((key) => {
        let auctionItem = filteredAuctionsItems[key];
        if (brand.value && brand.value !== product.brand.id) return;
        if (brand.value && brand.value !== product.brand.id) return;

        if (auctionItem.product.id === product.id) {
          let quantity = totalAuctionsItems[auctionItem.id]?.totalUnits;
          if (!quantity) quantity = 0;

          if (!itemsByProducts[product.id]) itemsByProducts[product.id] = {};

          if (!itemsByProducts[product.id][product])
            itemsByProducts[product.id][product] = 0;

          itemsByProducts[product.id][product] += quantity;
        }
      });
    });
    setItemsByProducts(itemsByProducts);
  }, [filteredAuctionsItems, products, brand]);

  useEffect(() => {
    setLoading(true);
    setReport([]);
    formatReport(itemsByProducts);
  }, [itemsByProducts, product]);

  const formatReport = (itemsByProducts) => {
    let report = [];
    Object.keys(itemsByProducts).forEach((productId) => {
      Object.keys(itemsByProducts[productId]).forEach((item) => {
        let canAdd = true;
        if (product?.value?.id && product.value.id !== productId) {
          canAdd = false;
        }

        const quantity = itemsByProducts[productId][item];
        if (canAdd && !!quantity && products) {
          let totalUnits = 0;
          let unitPrice = 0;
          let middlePrice = 0;
          Object.keys(filteredAuctionsItems).map((key) => {
            if (filteredAuctionsItems[key].product.id === productId) {
              totalUnits = filteredAuctionsItems[key].totalUnits;
              unitPrice = filteredAuctionsItems[key].unitPrice;
              middlePrice += totalUnits * unitPrice;
            }
          });
          report.push({
            id: productId,
            model: products[productId]["name"],
            brand: products[productId]["brand"]["name"],
            info: products[productId]["info"],
            unitOfMeasurement: products[productId]["unitOfMeasurement"].name,
            quantity,
            middlePrice: middlePrice / quantity,
          });
        }
      });
    });
    setReport(report);
    setLoading(false);
  };

  const isLoading = loadingAuctions || loading;

  useEffect(() => {
    setProductsOptions(formatProductsOptions(products, brand.value));
    setProduct({ value: "", label: "Todos" });
  }, [brand]);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Relatório de Pregões</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                orientation={"vertical"}
                openDirection={"down"}
                displayFormat={"DD-MM-YYYY"}
                isOutsideRange={() => false}
                startDatePlaceholderText="Data inicial"
                endDatePlaceholderText="Data final"
                phrases={ptBrPhrases}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Filters
                company={company}
                setCompany={setCompany}
                companiesOptions={companiesOptions}
                brand={brand}
                setBrand={setBrand}
                brandsOptions={brandsOptions}
                product={product}
                setProduct={setProduct}
                productsOptions={productsOptions}
                tradingType={tradingType}
                setTradingType={setTradingType}
                tradingTypesOptions={tradingTypesOptions}
                contract={contract}
                setContract={setContract}
                contractsOptions={contractsOptions}
              />
            </Col>
          </Row>
          <Row className="d-none d-print-block">
            <Col>
              <FiltersPrint
                company={company}
                brand={brand}
                product={product}
                tradingType={tradingType}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Button className="px-0" color="link" onClick={resetFilters}>
                apagar filtros
              </Button>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => window.print()}>
                Imprimir
              </Button>
            </Col>
          </Row>

          {error && (
            <Row className="mt-4">
              <Col className="text-center">
                <h4>Não foi possível concluir sua solicitação</h4>
                <br />
                Por favor tente novamente
              </Col>
            </Row>
          )}
          {!error && (
            <Row className="mt-4">
              {isLoading ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <ReportTable
                    data={report}
                    filteredAuctionsItems={filteredAuctionsItems}
                    auctions={auctions}
                  />
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default AuctionReport;
