import React, { Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import GovernmentAgencyForm from "./GovernmentAgencyForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import { removeMask } from "utils/mask";
import { cpf, cnpj } from "cpf-cnpj-validator";

import dispatch, { LocationActions, EditActions } from "actions";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useGetGovernmentAgencies } from "hooks";
import { CenteredSpinner } from "components";

const GovernmentAgencyDetails = ({ isModal = false, closeModal }) => {
  const history = useHistory();
  const location = useLocation();
  const row = idx(location, (_) => _.state.row);
  const isEdit =
    !isModal && idx(history, (_) => _.location.state.row.id) ? true : false;
  const id = row?.id;
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const {
    loadingGovernmentAgencies,
    governmentAgencies,
    governmentAgenciesCnpj,
  } = useGetGovernmentAgencies();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Insira o nome do orgão"),
      cpfcnpj: Yup.string().required("Insira o CPF ou CNPJ do orgão"),
      state: Yup.string().required("Escolha um estado"),
      city: Yup.string().required("Escolha uma cidade"),
    }),

    validate: (values) => {
      const errors = {};
      if (values.cpfcnpj) {
        if (!cnpj.isValid(values.cpfcnpj) && !cpf.isValid(values.cpfcnpj))
          errors.cpfcnpj = "Por favor insira um CPF ou CNPJ válido";
        if (governmentAgenciesCnpj) {
          if (governmentAgenciesCnpj.indexOf(removeMask(values.cpfcnpj)) > -1) {
            if (!isEdit) {
              errors.cpfcnpj = "CPF ou CNPJ já registrado";
            }
          }
        }
      }

      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newGovernmentAgency = {
        name: values.name,
        state: {
          name: values.state.data.name,
          value: values.state.data.initials,
        },
        city: values.city.data,
      };

      const docId = isEdit ? id : db.collection("governmentAgencies").doc().id;
      newGovernmentAgency["id"] = docId;
      if (cnpj.isValid(values.cpfcnpj))
        newGovernmentAgency["cnpj"] = removeMask(values.cpfcnpj);

      if (cpf.isValid(values.cpfcnpj))
        newGovernmentAgency["cpf"] = removeMask(values.cpfcnpj);

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newGovernmentAgency["createdAt"] = values["createdAt"];
        newGovernmentAgency["updatedAt"] = serverTimestamp;
      } else {
        newGovernmentAgency["createdAt"] = serverTimestamp;
      }

      const docRef = db.collection("governmentAgencies").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newGovernmentAgency["name"],
        collection: "governmentAgencies",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let batch = db.batch();
      if (isEdit) {
        log["action"] = "update";
        batch.update(docRef, newGovernmentAgency);
      } else {
        batch.set(docRef, newGovernmentAgency);
      }
      batch.set(logRef, log);

      batch
        .commit()
        .then(() => {
          console.log("Orgão salvo com sucesso");
          setSubmitting(false);
          {
            isModal ? closeModal() : history.goBack();
          }
        })
        .catch(function(error) {
          alert("Erro ao salvar orgão");
          console.error("Erro ao salvar orgão: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "GovernmentAgencyForm",
    mapPropsToValues: () => {
      let data = {
        name: "",
        cpfcnpj: "",
        createdAt: "",
        state: "",
        city: "",
      };
      if (isEdit) {
        dispatch(LocationActions.saveCurrentState, row.state);
        data = {
          name: row.name,
          cpfcnpj: row.cnpj ? row.cnpj.toString() : row.cpf.toString(),
          createdAt: row.createdAt,
          state: {
            label: row.state.name,
            value: row.state.value,
            data: {
              name: row.state.name,
              initials: row.state.value,
            },
          },
          city: { label: row.city, data: row.city },
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(GovernmentAgencyForm);

  if (isModal)
    return (
      <Fragment>
        {loadingGovernmentAgencies ? (
          <CenteredSpinner />
        ) : (
          <MyEnhancedForm isEdit={isEdit} />
        )}
      </Fragment>
    );

  return (
    <div className="animated">
      <Card>
        <CardHeader>Orgão</CardHeader>
        <CardBody>
          {loadingGovernmentAgencies ? (
            <CenteredSpinner />
          ) : (
            <MyEnhancedForm isEdit={isEdit} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default GovernmentAgencyDetails;
