interface ActionsInterface {
  (action: string): string;
}

export const actionTranlate: ActionsInterface = (action) => {
  switch (action) {
    case "create":
      return "Criação";
      break;
    case "update":
      return "Atualização";
      break;
    case "delete":
      return "Exclusão";
      break;

    default:
      return action;
      break;
  }
};

interface CollectionsInterface {
  (collection: string): string;
}

export const collectionTranlate: CollectionsInterface = (collection) => {
  switch (collection) {
    case "auctions":
      return "Pregões";
      break;

    case "brands":
      return "Marcas";
      break;

    case "companies":
      return "Empresas";
      break;

    case "deliveries":
      return "Entregas";
      break;

    case "governmentAgencies":
      return "Órgãos";
      break;

    case "pawns":
      return "Empenhos";
      break;

    case "products":
      return "Produtos";
      break;

    case "unitiesOfMeasurement":
      return "Unidades de medida";
      break;

    case "users":
      return "Usuários";
      break;

    case "packingLists":
      return "Romaneios";
      break;

    default:
      return collection;
      break;
  }
};
