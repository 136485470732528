import { useState, useEffect } from "react";
import { useGetPawnByNumber } from "hooks";

const useValidatePawnUnique = (props: any) => {
  const { pawnNumber, auctionId, currentPawnId, inputFocus } = props;
  const controller = new AbortController();
  const [validating, setValidating] = useState(false);
  const [uniqueValidated, setUniqueValidated] = useState(false);
  const [isUnique, setIsUnique] = useState(false);

  const { getPawn } = useGetPawnByNumber();

  useEffect(() => {
    if(!inputFocus){
    setUniqueValidated(false);
    if (pawnNumber && auctionId) {
      setValidating(true);
      getPawn({
        number: pawnNumber,
        auctionId: auctionId,
        onComplete: (pawns: any[]) => {
          setUniqueValidated(true);
          setValidating(false);
          if (pawns.length > 1) {
            setIsUnique(false);
          } else if (pawns.length === 1) {
            setIsUnique(pawns[0].id === currentPawnId);
          } else {
            setIsUnique(pawns.length === 0);
          }
        },
      });
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 2000);
      clearTimeout(timeout);
    };
  }
  }, [pawnNumber, currentPawnId, auctionId, inputFocus]);

  return { validating, uniqueValidated, isUnique };
};

export default useValidatePawnUnique;