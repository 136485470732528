const statusOptions = [
  {
    value: false,
    label: "Inativo",
  },
  {
    value: true,
    label: "Ativo",
  },
];

export default statusOptions;
