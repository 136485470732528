import { createReducer } from "typesafe-actions";
import { UserActions } from "actions";

const { saveUser, clearUser } = UserActions;

const initialState = {
  uid: "",
  name: "",
  email: "",
  role: "",
  status: "",
};

const reducer = createReducer(initialState)
  .handleAction(saveUser, (state, { payload }) => ({
    ...payload,
  }))
  .handleAction(clearUser, (state) => ({
    ...initialState,
  }));

export default reducer;
