import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetPackingLists, useGetPawns } from "hooks";
import { CenteredSpinner } from "components";
import Filters from "./Filters";
import FiltersPrint from "./FiltersPrint";
import ReportTable from "./ReportTable";
import {
  formatCompaniesOptions,
  formatProductsOptions,
  formatVoltagesInvolved,
  formatBrandsOptions,
  formatInstalledsInvolved,
  formatStatusInvolved,
} from "./formatters";

import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";

const PackingListReport = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState("startDate");

  const [company, setCompany] = useState({ value: "", label: "Todas" });
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [brand, setBrand] = useState({ value: "", label: "Todas" });
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [product, setProduct] = useState({ value: "", label: "Todos" });
  const [productsOptions, setProductsOptions] = useState([]);
  const [voltage, setVoltage] = useState({ value: "", label: "Todas" });
  const [voltagesOptions, setVoltagesOptions] = useState([]);

  const [installed, setInstalled] = useState({ value: "", label: "Todos" });
  const [installedOptions, setInstalledOptions] = useState([]);

  const [status, setStatus] = useState({ value: "", label: "Todos" });
  const [statusOptions, setStatusOptions] = useState([]);

  const { loadingPackingLists, packingLists } = useGetPackingLists(
    "",
    startDate,
    endDate,
    true
  );
  // const { loadingPawns, pawns } = useGetPawns("", startDate, endDate, true);
  const [pawns, setPawns] = useState([]);
  const [hashProductPackingList, setHashProductPackingList] = useState({});

  const [totalAuctionsItems, setTotalAuctionsItems] = useState({});
  const [filteredAuctionsItems, setFilteredAuctionsItems] = useState({});
  const [totalPawnsItems, setTotalPawnsItems] = useState({});
  const [itemsByProductsAndVoltage, setItemsByProductsAndVoltage] = useState(
    {}
  );
  const [products, setProducts] = useState({});

  const [report, setReport] = useState([]);

  const [plBystatus, setPLByStatus] = useState([]);

  const resetFilters = () => {
    setCompany({ value: "", label: "Todas" });
    setBrand({ value: "", label: "Todas" });
    setProduct({ value: "", label: "Todos" });
    setVoltage({ value: "", label: "Todas" });
    setInstalled({ value: "", label: "Todos" });
    setStatus({ value: "", label: "Todos" });
  };
  const resetOptions = () => {
    setCompaniesOptions([]);
    setBrandsOptions([]);
    setProductsOptions([]);
    setVoltagesOptions([]);
    setInstalled([]);
    setStatus([]);
  };

  useEffect(() => {
    if (packingLists) {
      setLoading(true);
      setReport([]);
      resetOptions();
      resetFilters();
      let statusInvolved = {};
      const promises = packingLists.map(async (packingList) => {
        statusInvolved[packingList.status === 1 ? 0 : packingList.status] =
          packingList.status === 0 || packingList.status === 1
            ? { label: "Pendente/Parcial", value: 0 }
            : packingList.status === 2
            ? { label: "Concluído", value: 2 }
            : { label: "Em Trânsito", value: 3 };
      });
      Promise.all(promises).then((res) => {
        setStatusOptions(formatStatusInvolved(statusInvolved));
      });
    }
  }, [packingLists]);

  useEffect(() => {
    if (status && statusOptions.length > 0) {
      setLoading(true);
      // setReport([]);
      // resetOptions();
      // resetFilters();
      let plBystatus = [];
      let status_permited = status.value;

      const promises = packingLists.map(async (packingList) => {
        if (status_permited !== "") {
          if (
            (status_permited === 0 || status_permited === 1) &&
            packingList.status !== 0 &&
            packingList.status !== 1
          )
            return;
          if (packingList.status !== status_permited) return;
        }
        plBystatus.push(packingList);
      });
      Promise.all(promises).then((res) => {
        setPLByStatus(plBystatus);
      });
    }
  }, [status, statusOptions]);

  useEffect(() => {
    if (plBystatus) {
      setLoading(true);
      setReport([]);
      // resetOptions();
      // resetFilters();
      let companiesInvolved = {};
      let voltagesInvolved = {};
      let installedInvolved = {};
      // let statusInvolved = {};
      let relatedProducts = {}; // produtos (modelo, marca, etc) para serem baixados depois
      let totalAuctionsItems = {}; // itens dos auctions (voltagem, instalado, preço)
      let totalPawnsItems = {};

      let pawns = [];
      const promises = plBystatus.map(async (packingList) => {
        // statusInvolved[packingList.status === 1 ? 0 : packingList.status] = packingList.status === 0 || packingList.status === 1 ?
        //                                     { label: 'Pendente/Parcial', value: 0 } :
        //                                     packingList.status === 2 ?
        //                                     { label: 'Concluído', value: 1 } :
        //                                     { label: 'Em Trânsito', value: 3 }
        await packingList.packingListArray.map(async (item) => {
          Object.keys(item.pawn.auction.items).forEach((key) => {
            let auctionItem = item.pawn.auction.items[key];
            let itemVoltage = auctionItem.voltage;
            voltagesInvolved[itemVoltage.value] = itemVoltage;
            installedInvolved[auctionItem.installed["value"] ? true : false] =
              auctionItem.installed;
            relatedProducts[auctionItem.product.id] = auctionItem.product;
          });
          // encontrar quantidades
          Object.keys(item.packingListItemsQuantity).forEach((keyItem) => {
            if (totalPawnsItems[keyItem])
              totalPawnsItems[keyItem] +=
                item.packingListItemsQuantity[keyItem];
            else
              totalPawnsItems[keyItem] = item.packingListItemsQuantity[keyItem];
          });

          pawns.push(item.pawn);
          companiesInvolved[item.pawn.auction.company.id] =
            item.pawn.auction.company;
          totalAuctionsItems = {
            ...totalAuctionsItems,
            ...item.pawn.auction.items,
          };
        });
      });
      Promise.all(promises).then((res) => {
        setPawns(pawns);
        setCompaniesOptions(formatCompaniesOptions(companiesInvolved));
        setVoltagesOptions(formatVoltagesInvolved(voltagesInvolved));
        setInstalledOptions(formatInstalledsInvolved(installedInvolved));
        // setStatusOptions(formatStatusInvolved(statusInvolved));
        setTotalAuctionsItems(totalAuctionsItems);
        priorLoadProducts(relatedProducts);
        setTotalPawnsItems(totalPawnsItems);
      });
    }
  }, [plBystatus]);

  useEffect(() => {
    setLoading(true);
    setReport([]);
    let filteredAuctionsItems = {}; // itens dos auctions (voltagem, instalado, preço)
    // let totalPawnsItems = {};
    pawns.map((pawn) => {
      if (company?.value && pawn.auction.company.id != company.value) return;

      let items = {};
      let keys = [];
      Object.keys(pawn.auction.items).forEach((key) => {
        if (installed.value === "") {
          items[key] = pawn.auction.items[key];
          keys.push(key);
        } else if (installed.value && pawn.auction.items[key].installed.value) {
          items[key] = pawn.auction.items[key];
          keys.push(key);
        } else if (
          !installed.value &&
          !pawn.auction.items[key].installed.value
        ) {
          items[key] = pawn.auction.items[key];
          keys.push(key);
        }
      });

      filteredAuctionsItems = {
        ...filteredAuctionsItems,
        ...items,
      };
    });
    setFilteredAuctionsItems(filteredAuctionsItems);
  }, [totalAuctionsItems, company, installed]);

  const priorLoadProducts = (items) => {
    loadProducts(items).then((prodSnapshots) => {
      let products = {};
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setProducts(products);
      setProductsOptions(formatProductsOptions(products));
      setBrandsOptions(formatBrandsOptions(products));
    });
  };

  const loadProducts = (relatedProducts) => {
    const promises = [];
    Object.keys(relatedProducts).forEach((key) => {
      let product = relatedProducts[key];
      try {
        promises.push(product.get());
      } catch (error) {}
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    let itemsByProductsAndVoltage = {};
    Object.keys(products).forEach((key) => {
      let product = products[key];
      Object.keys(filteredAuctionsItems).forEach((key) => {
        let auctionItem = filteredAuctionsItems[key];
        if (brand.value && brand.value !== product.brand.id) return;

        if (auctionItem.product.id === product.id) {
          let quantity = totalPawnsItems[auctionItem.id];
          if (!quantity) quantity = 0;
          const voltage = auctionItem.voltage.value;

          if (!itemsByProductsAndVoltage[product.id])
            itemsByProductsAndVoltage[product.id] = {};

          if (!itemsByProductsAndVoltage[product.id][voltage])
            itemsByProductsAndVoltage[product.id][voltage] = 0;

          itemsByProductsAndVoltage[product.id][voltage] += quantity;
        }
      });
    });
    setItemsByProductsAndVoltage(itemsByProductsAndVoltage);
  }, [filteredAuctionsItems, totalPawnsItems, products, brand]);

  useEffect(() => {
    setLoading(true);
    setReport([]);
    formatReport(itemsByProductsAndVoltage);
  }, [itemsByProductsAndVoltage, voltage, product]);

  const formatReport = (itemsByProductsAndVoltage) => {
    let report = [];
    Object.keys(itemsByProductsAndVoltage).forEach((productId) => {
      Object.keys(itemsByProductsAndVoltage[productId]).forEach(
        (itemVoltage) => {
          let canAdd = true;
          if (product?.value?.id && product.value.id !== productId) {
            canAdd = false;
          }

          if (voltage?.value && voltage.value !== itemVoltage) {
            canAdd = false;
          }

          const quantity = itemsByProductsAndVoltage[productId][itemVoltage];
          if (canAdd && !!quantity && products) {
            let totalUnits = 0;
            let unitPrice = 0;
            let middlePrice = 0;
            Object.keys(filteredAuctionsItems).map((key) => {
              if (
                filteredAuctionsItems[key].product.id === productId &&
                filteredAuctionsItems[key].voltage.value === itemVoltage
              ) {
                totalUnits = filteredAuctionsItems[key].totalUnits;
                unitPrice = filteredAuctionsItems[key].unitPrice;
                middlePrice += totalUnits * unitPrice;
              }
            });
            report.push({
              id: productId,
              model: products[productId]["name"],
              brand: products[productId]["brand"]["name"],
              info: products[productId]["info"],
              unitOfMeasurement: products[productId]["unitOfMeasurement"].name,
              voltage: itemVoltage,
              quantity,
              middlePrice: middlePrice / quantity,
            });
          }
        }
      );
    });
    setReport(report);
    setLoading(false);
  };

  const isLoading = loadingPackingLists || loading;

  useEffect(() => {
    setProductsOptions(formatProductsOptions(products, brand.value));
    setProduct({ value: "", label: "Todos" });
  }, [brand]);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Relatório de Romaneios</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                orientation={"vertical"}
                openDirection={"down"}
                displayFormat={"DD-MM-YYYY"}
                isOutsideRange={() => false}
                startDatePlaceholderText="Data inicial"
                endDatePlaceholderText="Data final"
                phrases={ptBrPhrases}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Filters
                company={company}
                setCompany={setCompany}
                companiesOptions={companiesOptions}
                brand={brand}
                setBrand={setBrand}
                brandsOptions={brandsOptions}
                product={product}
                setProduct={setProduct}
                productsOptions={productsOptions}
                voltage={voltage}
                setVoltage={setVoltage}
                voltagesOptions={voltagesOptions}
                installed={installed}
                setInstalled={setInstalled}
                installedOptions={installedOptions}
                status={status}
                setStatus={setStatus}
                statusOptions={statusOptions}
              />
            </Col>
          </Row>
          <Row className="d-none d-print-block">
            <Col>
              <FiltersPrint
                company={company}
                brand={brand}
                product={product}
                voltage={voltage}
                installed={installed}
                status={status}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Button className="px-0" color="link" onClick={resetFilters}>
                apagar filtros
              </Button>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => window.print()}>
                Imprimir
              </Button>
            </Col>
          </Row>

          {error && (
            <Row className="mt-4">
              <Col className="text-center">
                <h4>Não foi possível concluir sua solicitação</h4>
                <br />
                Por favor tente novamente
              </Col>
            </Row>
          )}
          {!error && (
            <Row className="mt-4">
              {isLoading ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <ReportTable
                    data={report}
                    filteredAuctionsItems={filteredAuctionsItems}
                    packingLists={plBystatus}
                    installed={installed}
                  />
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PackingListReport;
