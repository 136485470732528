import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetPackingLists = (count?: any, startDate?: any, endDate?: any, onlyByDate?: boolean, status?: number ) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [packingLists, setPackingLists] = useState([] as any);
  const [loadingPackingLists, setLoadingPackingLists] = useState(false);

  useEffect(() => {
    if (!onlyByDate) {
      setLoadingPackingLists(true);
      db.collection("packingLists")
        .orderBy("createdAt", "desc")
        .where("status", "in", !!status ? status === 3 ? [0, 1, 2] : [status] : [0, 1])
        .get()
        .then((querySnapshot) => {
          setPackingLists(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingPackingLists(false);
        })
        .catch((error) => {
          setLoadingPackingLists(false);
          console.log("erro: ", error);
        });
      }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [count, status]);

  useEffect(() => {
    if (startDate && endDate) {
      setLoadingPackingLists(true);
      const start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
      const end = firebase.firestore.Timestamp.fromDate(new Date(endDate))
      db.collection("packingLists")
        .where("createdAt", ">=", start)
        .where("createdAt", "<=", end)
        .get()
        .then(async (querySnapshot) => {
          let packingLists = querySnapshot.docs.map((doc) => doc.data())
          const promises = packingLists.map(async packingList => {
            const sub_promises = packingList.packingListArray.map(async (item: any) => {
              try {
                item.pawn = await item.pawn.get().then((response: any) => { return response.data() })
              } catch {}
            })
            await Promise.all(sub_promises)
          })
          await Promise.all(promises)
          setPackingLists(packingLists);
          setLoadingPackingLists(false);
        })
        .catch((error) => {
          setLoadingPackingLists(false);
          console.log("erro: ", error);
        });
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [startDate, endDate]);

  return { loadingPackingLists, packingLists };
};

export default useGetPackingLists;
