import React from "react";
import { Col, Row } from "reactstrap";

const FiltersPrint = (props: any) => {
  const { company, brand, product, voltage, installed, status } = props;
  return (
    <div className="mt-4 d-none d-print-block">
      {/* <Row>
        <Col sm={6} className="mb-3">
          <strong>Empresa:</strong>
          <br />
          {company.label}
        </Col>
        <Col sm={6} className="mb-3">
          <strong>Marca:</strong>
          <br />
          {brand.label}
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mb-3">
          <strong>Produto:</strong>
          <br />
          {product.label}
        </Col>
        <Col sm={6} className="mb-3">
          <strong>Voltagem:</strong>
          <br />
          {voltage.label}
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mb-3">
          <strong>Instalado?</strong>
          <br />
          {installed.label}
        </Col>
        <Col sm={6} className="mb-3">
          <strong>Situação de Entrega:</strong>
          <br />
          {status.label}
        </Col>
      </Row> */}
    </div>
  );
};

export default FiltersPrint;
