import {
  iconDashboard,
  iconEmpenhos,
  iconRelatorio,
  iconPregoes,
  iconProdutos,
  iconOrgaos,
  iconEntregas,
  iconMarcas,
  iconUnidades,
  iconLog,
  iconUser,
  iconRomaneio,
  iconFamily
} from "./app/assets/icons";

const dashboard = {
  name: "Dashboard",
  url: "/dashboard",
  customIcon: iconDashboard,
};
const auctions = {
  name: "Pregões",
  url: "/pregoes",
  customIcon: iconPregoes,
};
const auctionReport = {
  name: "Relatório de Pregões",
  url: "/pregoes/relatorio",
  customIcon: iconRelatorio,
};
const pawns = {
  name: "Empenhos",
  url: "/empenhos",
  customIcon: iconEmpenhos,
};
const pawnReport = {
  name: "Relatório de Empenhos",
  url: "/empenhos/relatorio",
  customIcon: iconRelatorio,
};
const deliveries = {
  name: "Entregas",
  url: "/entregas",
  customIcon: iconEntregas,
};

const productFamily = {
  name: "Familias",
  url: "/familias",
  customIcon: iconFamily,
};
const governmentAgencies = {
  name: "Órgãos",
  url: "/orgaos",
  customIcon: iconOrgaos,
};
const brands = {
  name: "Marcas",
  url: "/marcas",
  customIcon: iconMarcas,
};
const unitiesOfMeasurement = {
  name: "Unidades de medida",
  url: "/unidades-de-medida",
  customIcon: iconUnidades,
};
const products = {
  name: "Produtos",
  url: "/produtos",
  customIcon: iconProdutos,
};
const logs = {
  name: "Log de eventos",
  url: "/log",
  customIcon: iconLog,
};
const users = {
  name: "Usuários",
  url: "/usuarios",
  customIcon: iconUser,
};
const packingList = {
  name: "Romaneios",
  url: "/romaneios",
  customIcon: iconRomaneio,
};
const packingListReport = {
  name: "Relatório de Romaneios",
  url: "/romaneios/relatorio",
  customIcon: iconRelatorio,
};
const userReport = {
  name: "Relatório de Vendedores",
  url: "/usuarios/relatorio",
  customIcon: iconRelatorio,
};

const productReport = {
  name: "Relatório de Produtos",
  url: "/produtos/relatorio",
  customIcon: iconRelatorio,
};

const adminPaths = [
  dashboard,
  auctions,
  pawns,
  packingList,
  deliveries,
  governmentAgencies,
  brands,
  unitiesOfMeasurement,
  products,
  productFamily,
  logs,
  users,
  productReport,
  auctionReport,
  pawnReport,
  packingListReport,
  userReport,
];
const sellerPaths = [
  auctions,
  governmentAgencies,
  brands,
  unitiesOfMeasurement,
  products,
];
const pledgerPaths = [pawns, pawnReport];
const delivererPaths = [deliveries, packingList];
const financialPaths = [pawns];

export {
  adminPaths,
  sellerPaths,
  pledgerPaths,
  delivererPaths,
  financialPaths,
};
