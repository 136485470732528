import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import { ToastContainer } from "react-toastify";

import moment from "moment";
import "moment/locale/pt-br";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "react-toastify/dist/ReactToastify.min.css";
import "./App.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import DefaultLayout from "./app/containers/DefaultLayout";
import {
  Login,
  SignUp,
  RecoverPassword,
  NoRole,
  Page404,
  Page500,
} from "./app/screens";

const App = () => {
  moment.locale("pt-br");
  return (
    <Provider store={store}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <HashRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/registro" component={SignUp} />
          <Route exact path="/recuperar-senha" component={RecoverPassword} />
          <Route exact path="/nenhum-privilegio" component={NoRole} />
          <Route exact path="/404" component={Page404} />
          <Route exact path="/500" component={Page500} />
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    </Provider>
  );
};

export default App;
