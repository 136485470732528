import React, { useState, Fragment, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetDeliveries } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CenteredSpinner, QuickViewModal } from "components";
import DeleteModal from "./DeleteModal";
import moment from "moment";
import { PackingListDelivery, DeliveryDetails } from "screens";

const PawnList = ({ history }) => {
  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);
  const { loadingDeliveries, deliveries } = useGetDeliveries(count);

  const [quickViewItem, setQuickViewItem] = useState(undefined);

  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Entrega nº",
    },
    {
      sort: true,
      dataField: "pawn.auction.company.name",
      text: "Empresa",
    },
    {
      sort: true,
      dataField: "pawn.auction.number",
      text: "Pregão",
    },
    {
      sort: true,
      dataField: "pawn.auction.governmentAgency.name",
      text: "Órgão",
    },
    {
      sort: true,
      dataField: "packingList.number",
      text: "Romaneio",
    },
    {
      sort: true,
      dataField: "pawn.customPawnNumber",
      text: "Número",
    },
    {
      sort: true,
      dataField: "nfe",
      text: "NFe",
    },
    {
      sort: true,
      text: "Data de entrega",
      formatter: (cell, row) => {
        return moment(row.deliveryDate).format("DD/MM/YYYY");
      },
    },
    {
      sort: true,
      dataField: "deliveredTo",
      text: "Entregue para",
    },

    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setQuickViewItem(row);
              }}
            />
            <i
              className="icon-note ml-2 action-button"
              onClick={() => {
                const path = !!row.packingList
                  ? "./entregas/romaneios/detalhes"
                  : "./entregas/detalhes";
                history.push({
                  pathname: path,
                  state: { row },
                });
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "number",
      order: "desc",
    },
  ];

  const searchBarRef = useRef()
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus()
  }, [])

  return (
    <Fragment>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        delivery={modalItem}
        refreshList={() => setCount(count + 1)}
      />
      <QuickViewModal
        closeModal={() => setQuickViewItem(undefined)}
        isOpen={quickViewItem}
        title={`Entrega #${quickViewItem?.number}`}
      >
        {!!quickViewItem?.packingList ? (
          <PackingListDelivery quickViewDelivery={quickViewItem} />
        ) : (
          <DeliveryDetails quickViewDelivery={quickViewItem} />
        )}
      </QuickViewModal>
      <div className="animated">
        <Card>
          <CardHeader>Entregas</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={deliveries}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button
                            block
                            color="success"
                            onClick={() => {
                              history.push("./entregas/romaneio");
                            }}
                          >
                            Nova Entrega
                          </Button>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {loadingDeliveries ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default PawnList;
