import React, { useState, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import AuctionForm from "./AuctionForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import dispatch, { EditActions } from "actions";

const AuctionDetails = ({ history }) => {
  const isEdit = idx(history, (_) => _.location.state.row.id) ? true : false;
  const id = idx(history, (_) => _.location.state.row.id);
  const notEdit = idx(history, (_) => _.notEdit);
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const [userData, setUserData] = useState([]);
  const [actualUser, setActualUser] = useState("");
  const [oldSeller, setOldSeller] = useState();

  useEffect(() => {
    let currentUser = firebase.auth().currentUser;

    db.collection("users")
      .get()
      .then((querySnapshot) => {
        const pickerData = querySnapshot.docs.map((doc) => doc.data());
        setUserData(pickerData);

        pickerData
          .filter((filterSeller) => filterSeller.uid === currentUser.uid)
          .map((sellerFiltered) => setActualUser(sellerFiltered));
      });
  }, []);

  const createItemsRefs = (values) => {
    let referencedItems = {};
    Object.keys(values.itemsList).forEach((key) => {
      let item = values.itemsList[key];
      referencedItems[key] = {
        ...item,
        product: db.collection("products").doc(item.product.id),
      };
    });
    return referencedItems;
  };

  const createLogOfSaller = async (docId, serverTimestamp, newAuction) => {
    const log = {
      action: "update",
      reference: `${newAuction["number"]} 
        - Novo vendedor: ${newAuction.sellers.label} 
        - Vendedor Anterior: ${
          oldSeller?.label ? oldSeller.label : "Indefinido"
        }`,
      collection: "auctions",
      doc: docId,
      createdAt: serverTimestamp,
      user,
    };

    await db
      .collection("logs")
      .doc()
      .set(log);
  };

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      number: Yup.string().required("Insira o número do pregão"),
      governmentAgency: Yup.string().required("Escolha um Órgão"),
      company: Yup.string().required("Escolha uma empresa"),
      tradingType: Yup.string().required("Escolha um tipo de licitação"),
      itemsList: Yup.object().required("Adicione ao menos um item ao pregão"),
      sellers: Yup.object().required("Adicione um vendedor"),
      daysForDelivery: Yup.number("Precisa ser um número")
        .required("Insira o número de dias para a entrega")
        .typeError("Precisa ser um número"),
    }),
    creationAuctionDate: Yup.date("Selecione uma data").required(
      "É necessário uma data"
    ),
    validate: (values) => {
      const errors = {};
      if (values.daysForDelivery === 0 || values.daysForDelivery === "") {
        errors.daysForDelivery = "Insira o número de dias para a entrega";
      }

      if (values.itemsList) {
        if (Object.keys(values.itemsList).length === 0)
          errors.itemsList = "Adicione ao menos um item ao pregão";
      } else {
        errors.itemsList = "Nenhum item no pregão";
      }

      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      let referencedItems = createItemsRefs(values);

      let newAuction = {
        number: values.number,
        governmentAgency: values.governmentAgency.data,
        company: values.company.data,
        daysForDelivery: values.daysForDelivery,
        tradingType: values.tradingType,
        observations: values.observations ? values.observations : "",
        contract: values.contract,
        otherFiles: values.otherFiles,
        items: referencedItems,
        sellers: values.sellers,
        creationAuctionDate: values.creationAuctionDate,
        typeAuction: values.typeAuction,
        statusAuction: 0,
      };

      if(values.contractDate) {
        newAuction = {...newAuction, contractDate: values.contractDate}
      }

      const docId = isEdit ? id : db.collection("auctions").doc().id;
      newAuction["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const docRef = db.collection("auctions").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newAuction["number"],
        collection: "auctions",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      if (
        newAuction.sellers.label !== oldSeller &&
        isEdit &&
        newAuction.sellers.label !== "Indefinido"
      ) {
        createLogOfSaller(docId, serverTimestamp, newAuction);
      }

      let batch = db.batch();
      if (isEdit) {
        newAuction["createdAt"] = values["createdAt"];
        newAuction["updatedAt"] = serverTimestamp;
        log["action"] = "update";
        batch.update(docRef, newAuction);
      } else {
        newAuction["createdAt"] = serverTimestamp;
        batch.set(docRef, newAuction);
        // Salvar items na coleção "productsInUse"
        for (const key in referencedItems) {
          if (referencedItems.hasOwnProperty(key)) {
            const referencedProductId = referencedItems[key].product.id;
            const prodReference = db
              .collection("productsInUse")
              .doc(referencedProductId);
            batch.set(prodReference, { [docId]: true }, { merge: true });
          }
        }
      }
      batch.set(logRef, log);
      batch
        .commit()
        .then(() => {
          console.log("Pregão salvo com sucesso");
          setSubmitting(false);
          history.goBack();
        })
        .catch((error) => {
          alert("Erro ao salvar pregão");
          console.error("Erro ao salvar pregão: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "AuctionForm",
    mapPropsToValues: () => {
      let data = {
        number: "",
        createdAt: "",
        tradingType: "",
        observations: "",
        contract: "",
        otherFiles: [],
        itemsList: "",
        governmentAgency: "",
        company: "",
        sellers: "",
        daysForDelivery: "",
        creationAuctionDate: "",
        statusAuction: "",
      };

      if (isEdit) {
        const row = history.location.state.row;
        const products = history.location.state.products;
        data = {
          number: row.number,
          id: row.id,
          createdAt: row.createdAt,
          tradingType: row.tradingType,
          observations: row.observations,
          contract: row.contract ? row.contract : "",
          otherFiles: row.otherFiles ? row.otherFiles : [],
          itemsList: row.items,
          daysForDelivery: row.daysForDelivery,
          governmentAgency: {
            label: row.governmentAgency.name,
            data: row.governmentAgency,
          },
          company: {
            label: row.company.name,
            data: row.company,
          },
          sellers: row.sellers,
          creationAuctionDate: row.creationAuctionDate,
          statusAuction: row.statusAuction,
          contractDate: row.contractDate,
          typeAuction: row.typeAuction
        };

        Object.keys(row.items).forEach((key) => {
          let item = row.items[key];
          data.itemsList[key].product = products[item.product.id];
        });
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(AuctionForm);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Pregão</CardHeader>
        <CardBody>
          <MyEnhancedForm
            isEdit={isEdit}
            id={id}
            notEdit={notEdit}
            userData={userData}
            actualUser={actualUser}
            setOldSeller={setOldSeller}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default AuctionDetails;
