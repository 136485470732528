import React, { Fragment, useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import PLDeliveryItems from "./PLDeliveryItems";
import moment from "moment";
import "./style.css";
import { useHistory } from "react-router-dom";
import { useGetDelivery } from "hooks";
import firebase from "config/firebase";

const PLDeliveryCardDisplay = ({
  pawn,
  packingList,
  relatedProducts,
  pawnError,
  deliverPackingList,
}: any) => {
  const db = firebase.firestore();
  const history = useHistory();
  const { loadingDelivery, getDelivery } = useGetDelivery();
  const [loading, setLoading] = useState(false);

  const singleItem = (size: number, label: string, value: any) => {
    return (
      <Col md={size} className="mb-3">
        <label className="small-text mb-0" style={{ display: "block" }}>
          {label}
        </label>
        <span className="small-text">{value ? value : "..."}</span>
      </Col>
    );
  };

  const editPL = () => {
    getDelivery(packingList.deliveryId).then((delivery) => {
      history.push({
        pathname: "./romaneios/detalhes",
        state: { row: delivery },
      });
    });
  };

  return (
    <>
      <Card className={`item ${packingList.status == 1 && "delivered"}`}>
        <CardHeader className="small-header-padding d-flex ">
          {!pawnError && (
            <Fragment>
              {packingList.status == 1 && <strong>Entregue </strong>}
              <span className="ml-auto mr-0">
                {packingList.status == 1 && (
                  <Button
                    color="info"
                    onClick={editPL}
                    className="card-header-buttons"
                  >
                    Editar
                  </Button>
                )}{" "}
                {packingList.status != 1 && (
                  <Button
                    color="success"
                    className="card-header-buttons"
                    onClick={() =>
                      deliverPackingList(packingList, pawn, relatedProducts)
                    }
                  >
                    Entregar
                  </Button>
                )}
              </span>
            </Fragment>
          )}
        </CardHeader>

        <CardBody>
          {pawnError && (
            <Row>
              <Col className="text-center text-danger">
                Erro ao baixar empenho. O empenho provavelmente foi excluído.
              </Col>
            </Row>
          )}
          {pawn && (
            <Fragment>
              <Row>
                {singleItem(
                  9,
                  "Empenho  | Pregão | Órgão | Empresa",
                  `Empenho: ${pawn.number}${
                    pawn.customPawnNumber
                      ? ` | Número:  ${pawn.customPawnNumber}`
                      : ""
                  } | Pregão: ${pawn.auction.number} | Órgão: ${
                    pawn.auction.governmentAgency.name
                  } | Empresa: ${pawn.auction.company.name}`
                )}
                {singleItem(
                  3,
                  "Data da entrega:",
                  moment(packingList.deliveryDate).format("DD/MM/YYYY")
                )}
              </Row>
              <Row>
                {singleItem(9, "Local de entrega", packingList.address)}
                {singleItem(
                  3,
                  "Cidade",
                  `${packingList.city}, ${packingList.state.value}`
                )}
              </Row>
              <Row>
                {singleItem(
                  6,
                  "Observações / Instruções de entrega",
                  packingList.observations
                )}
                {singleItem(
                  6,
                  "Cidade",
                  `${packingList.city}, ${packingList.state.value}`
                )}
              </Row>
              <Row>
                <PLDeliveryItems
                  pawn={pawn}
                  items={relatedProducts && pawn?.auction?.items}
                  relatedProducts={relatedProducts}
                  values={packingList}
                  isDelivered={packingList.status == 1}
                />
              </Row>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default PLDeliveryCardDisplay;
