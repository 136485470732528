import { createReducer } from "typesafe-actions";
import { EditButtonActions } from "actions";

const {
  setEditbutton,
} = EditButtonActions;

const initialState = {
  isEdit: false
};

const reducer = createReducer(initialState)
  .handleAction(setEditbutton, (state, { payload }) => ({
    ...state,
    isEdit: payload.isEdit
  }));

export default reducer;
