interface CNPJInterface {
  (input: string): string;
}

const cnpj: CNPJInterface = input => {
  let value = "";
  if (input)
    value = input
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  return value;
};

export default cnpj;
