import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PackingListForm from "./PackingListForm";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { notify } from "services";

import { useGetPawnsFromOpenPackingLists } from "hooks";

const PackingListListDetails = (data) => {
  const history = useHistory();
  const location = useLocation();
  const row = idx(location, (_) => _.state.row) || data.data;
  const [id, setId] = useState(row?.id);
  const [isEdit, setIsEdit] = useState(!!id);

  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const {
    loadingPawns,
    pawnsInPL,
    pawnsFromOpenPackingListsError,
  } = useGetPawnsFromOpenPackingLists();

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      driver: Yup.string().required("Insira o nome do motorista e a placa"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const newPackingList = {
        driver: values.driver,
        listing: values.listing,
        packingListArray: values.packingListArray,
        observationsListArray: values.observationsListArray,
        number: values.number,
        status: values.status,
      };

      const docId = isEdit ? id : db.collection("packingLists").doc().id;
      newPackingList["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newPackingList["updatedAt"] = serverTimestamp;
      } else {
        newPackingList["createdAt"] = serverTimestamp;
      }

      const counterRef = db.collection("generalSettings").doc("counters");
      const docRef = db.collection("packingLists").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newPackingList["number"],
        collection: "packingLists",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let action;
      if (isEdit) {
        let batch = db.batch();
        batch.update(docRef, newPackingList);
        log["action"] = "update";
        batch.set(logRef, log);
        action = batch.commit();
      } else {
        action = db.runTransaction(function(transaction) {
          return transaction.get(counterRef).then(function(countersDoc) {
            const newPackingListCount = countersDoc.data().packingLists + 1;
            newPackingList["number"] = newPackingListCount;
            log["reference"] = newPackingListCount;
            transaction.set(docRef, newPackingList);
            transaction.update(counterRef, {
              packingLists: newPackingListCount,
            });
            transaction.set(logRef, log);
          });
        });
      }

      action
        .then(() => {
          console.log("Romaneio salvo com sucesso", newPackingList["id"]);
          setSubmitting(false);
          setIsEdit(true);
          setId(newPackingList["id"]);
          formik.setFieldValue("number", newPackingList["number"]);
          formik.setFieldValue("createdAt", newPackingList["createdAt"]);
          values.toPrint && window.print();
          !values.toPrint && history.goBack();
        })
        .catch(function(error) {
          alert("Erro ao salvar romaneio");
          console.error("Erro ao salvar romaneio: ", error);
          setSubmitting(false);
        });
    },
    displayName: "PackingListForm",
    initialValues: {
      number: row?.number ? row.number : "",
      driver: row?.driver ? row.driver : "",
      createdAt: row?.createdAt ? row.createdAt : "",
      listing: row?.listing ? row.listing : [],
      packingListArray: row?.packingListArray ? row.packingListArray : [],
      observationsListArray: row?.observationsListArray
        ? row.observationsListArray
        : [],
      status: row?.status ? row.status : 0,
    },
  });

  const createObservation = async (values, observation) => {
    try {
      const newObservationList = {
        createdAt: values.createdAt,
        driver: values.driver,
        packingListArray: values.packingListArray,
        observationsListArray: observation,
        number: values.number,
        status: values.status,
      };

      const docId = isEdit ? id : await db.collection("packingLists").doc().id;
      newObservationList["id"] = docId;

      let serverTimestamp = await firebase.firestore.FieldValue.serverTimestamp();

      newObservationList["updatedAt"] = serverTimestamp;

      const log = {
        action: "create",
        reference: newObservationList["number"],
        collection: "packingLists",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      await db
        .collection("packingLists")
        .doc(docId)
        .set(newObservationList);
      await db
        .collection("logs")
        .doc()
        .set(log);
    } catch (error) {
      notify({
        description:
          "Não foi possível criar essa observação, tente novamente mais tarde.",
        type: "danger",
      });
    }
  };

  return (
    <div className="animated">
      <Card>
        <CardHeader className="d-print-none">
          {`Romaneio ${isEdit ? "#" + formik.values.number : ""}`}
        </CardHeader>
        <CardBody>
          {pawnsFromOpenPackingListsError ? (
            <Row>
              <Col className="text-center">Erro ao carregar dados</Col>
            </Row>
          ) : (
            <PackingListForm
              isEdit={isEdit}
              formik={formik}
              createObservation={createObservation}
              pawnsInPL={pawnsInPL}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PackingListListDetails;
