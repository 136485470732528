import React, { useState, useEffect, Fragment } from "react";
import {
  CustomReactSelect,
  CenteredSpinner,
  ComponentModal,
  TextArea,
  FileInput,
  FilesList,
  InputAndLabel,
} from "components";
import { Button, Col, Row } from "reactstrap";
import {
  useGetCompanies,
  useGetGovernmentAgencies,
  useDebounce,
  useValidateAuctionUnique,
} from "hooks";
import { formatOptions, tradingTypes, typeAuction } from "utils/general";
import { CompanyDetails, GovernmentAgencyDetails } from "screens";
import { money } from "utils/mask";

import ItemList from "./ItemList";
import firebase from "config/firebase";
import dispatch, { EditActions } from "actions";

const AuctionForm = (props: any) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,

    isEdit,
    id,
    notEdit,
    userData,
    actualUser,
    setOldSeller,
  } = props;

  const [companyModalIsOpen, setCompanyModalIsOpen] = useState<boolean>(false);
  const [companyCount, setCompanyCount] = useState(0);
  const [sellers, setSellers] = useState([{}]);
  const [oldSellerValueLabel, setOldSellerValueLabel] = useState({});

  const { companies } = useGetCompanies(companyCount);

  const [
    governmentAgencyModalIsOpen,
    setGovernmentAgencyModalIsOpen,
  ] = useState<boolean>(false);
  const [governmentAgencyCount, setGovernmentAgencyCount] = useState(0);
  const { governmentAgencies } = useGetGovernmentAgencies(
    governmentAgencyCount
  );

  const [deliveredItems, setDeliveredItems] = useState(undefined);
  const db = firebase.firestore();
  const [isLoading, setLoading] = useState(true);

  const debouncedAuctionNumber = useDebounce(values.number, 800);

  const { validating, uniqueValidated, isUnique } = useValidateAuctionUnique({
    auctionNumber: debouncedAuctionNumber,
    governmentAgencyId: values.governmentAgency?.data?.id,
    currentAuctionId: id,
  });
  const auctionNumberPermited = true;

  useEffect(() => {
    getUserInfo();
    if (isEdit) {
      getRelatedDeliveries();
      if (values.sellers === undefined) {
        setFieldValue("sellers", { value: "Indefinido", label: "Indefinido" });
      }
    } else {
      setLoading(false);
    }
  }, []);

  const getUserInfo = () => {
    const userPickerData: any[] = [];
    userData
      .filter(
        (filterSeller: any) =>
          filterSeller.role === "seller" || filterSeller.role === "admin"
      )
      .map((sellerFiltered: any) =>
        userPickerData.push({
          value: sellerFiltered.uid,
          label: `${sellerFiltered.name} (${sellerFiltered.role === "seller" ? "Vendedor" : "Admin"
            })`,
        })
      );

    if (isEdit) {
      setOldSellerValueLabel({
        value: values?.sellers?.value ? values.sellers?.value : "Indefinido",
        label: values?.sellers?.label ? values.sellers?.label : "Indefinido",
      });
      setOldSeller(values?.sellers?.label);
    }

    if (actualUser.role === "seller" && actualUser.role !== "" && !isEdit) {
      setFieldValue("sellers", {
        value: actualUser.uid,
        label: actualUser.name,
      });

      setOldSellerValueLabel({
        value: actualUser.uid,
        label: actualUser.name,
      });
    }

    setSellers(userPickerData);
  };
  useEffect(() => {
    if (!isLoading) dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  const getRelatedDeliveries = () => {
    const auctionId = values.id;
    db.collection("deliveries")
      .where("pawn.auction.id", "==", auctionId)
      .get()
      .then((querySnapshot) => {
        let relatedDeliveries = querySnapshot.docs.map((doc) => doc.data());
        calculateDeliveredItems(relatedDeliveries);
        setLoading(false);
      })
      .catch((error) => {
        alert("Não foi possível carregar os itens entregues");
        console.log("erro: ", error);
      });
  };

  const calculateDeliveredItems = (relatedDeliveries: any) => {
    let deliveredItems: any = {};

    relatedDeliveries.forEach((relatedDelivery: any) => {
      const deliveryItemsQuantity = relatedDelivery.deliveryItemsQuantity;
      for (const key in deliveryItemsQuantity) {
        if (deliveryItemsQuantity.hasOwnProperty(key)) {
          const deliveredItemQuantity = deliveryItemsQuantity[key];
          if (deliveredItems[key]) {
            deliveredItems[key] += deliveredItemQuantity;
          } else {
            deliveredItems[key] = deliveredItemQuantity;
          }
        }
      }
    });
    setDeliveredItems(deliveredItems);
  };
  const addItem = (item: any, isEdit?: boolean) => {
    if (!isEdit) {
      item.id = db.collection(item.product.id).doc().id;
    }

    setFieldValue("itemsList", { ...values.itemsList, [item.id]: item });

    // Primeiro Parametro - ?
    // Segundo Parametro - As informações coletadas + o local de origem onde quer que seja armazenado.

    // setFieldValue("itemsList", {...values.itemsList, [item.id]: {...item, idInstallation: newIdInstallation} })

    // ---> Lógica de criar um id sequencial não repetido

    // const counterRef = db.collection("generalSettings").doc("counters");
    // const docRef = db.collection("pawns").doc(docId);
    // const logRef = db.collection("logs").doc();

    // action = db.runTransaction(function(transaction) {
    //   return transaction.get(counterRef).then(function(countersDoc) {
    //     const newPawnCount = countersDoc.data().pawns + 1;
    //     newPawn["number"] = newPawnCount;
    //     setPawnNumber(newPawnCount);
    //     log["reference"] = newPawnCount;
    //     transaction.set(docRef, newPawn);
    //     transaction.update(counterRef, { pawns: newPawnCount });
    //     transaction.set(logRef, log);
    //   });
    // });
  };

  const deleteItem = (itemId: any) => {
    let newValues = { ...values.itemsList };
    delete newValues[itemId];
    setFieldValue("itemsList", newValues);
  };

  const closeCompanyModal = () => {
    setCompanyCount(companyCount + 1);
    setCompanyModalIsOpen(false);
  };

  const closeGovernmentAgencyModal = () => {
    setGovernmentAgencyCount(governmentAgencyCount + 1);
    setGovernmentAgencyModalIsOpen(false);
  };

  const calcTotalPrice = () => {
    let totalPrice = 0;
    for (const key in values.itemsList) {
      const item = values.itemsList[key];
      totalPrice += item.totalUnits * item.unitPrice;
    }
    return money(totalPrice);
  };

  return (
    <Fragment>
      <ComponentModal
        name="Adicionar empresa"
        component={companyModalIsOpen}
        closeModal={closeCompanyModal}
      >
        <CompanyDetails isModal={true} closeModal={closeCompanyModal} />
      </ComponentModal>

      <ComponentModal
        name="Adicionar órgão"
        component={governmentAgencyModalIsOpen}
        closeModal={closeGovernmentAgencyModal}
      >
        <GovernmentAgencyDetails
          isModal={true}
          closeModal={closeGovernmentAgencyModal}
        />
      </ComponentModal>

      <form onSubmit={handleSubmit} key="AuctionForm">
        <Row>
          <Col md="4" className="mb-3">
            <label htmlFor="name" style={{ display: "block" }}>
              Número
            </label>
            {!validating ? (
              <input
                id="number"
                placeholder="Número"
                type="text"
                value={values.number}
                autoFocus
                onChange={(evt) => {
                  handleChange(evt);
                  // setAuctionNumber(evt.target.value);
                  // setUniqueValidated(false);
                }}
                onBlur={handleBlur}
              />
            ) : (
              <CenteredSpinner />
            )}
            {touched.number && errors.number && (
              <div className="error-div">{errors.number}</div>
            )}

            {uniqueValidated && !errors.number && !auctionNumberPermited && (
              <div className="error-div">Número já em uso</div>
            )}
          </Col>
          <Col md="4" className="mb-3">
            <CustomReactSelect
              label="Órgão"
              field="governmentAgency"
              value={values.governmentAgency}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.governmentAgency}
              touched={touched.governmentAgency}
              options={formatOptions(governmentAgencies)}
              onAdd={() => {
                setGovernmentAgencyModalIsOpen(true);
              }}
            />
          </Col>
          <Col md="4">
            <CustomReactSelect
              label="Tipo de Pregão"
              field="typeAuction"
              value={values.typeAuction}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.typeAuction}
              touched={touched.typeAuction}
              options={typeAuction}
            />
            </Col>
        </Row>

        <Row>
          <Col md="4" className="mb-3">
            <CustomReactSelect
              label="Empresa"
              field="company"
              value={values.company}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.company}
              touched={touched.company}
              options={formatOptions(companies)}
              onAdd={() => {
                setCompanyModalIsOpen(true);
              }}
            />
          </Col>
          <Col md="4" className="mb-3">
            <CustomReactSelect
              label="Modalidade"
              field="tradingType"
              value={values.tradingType}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.tradingType}
              touched={touched.tradingType}
              options={tradingTypes}
            />
          </Col>
          <InputAndLabel
              col={4}
              name="contractDate"
              label="Data do contrato:"
              type="date"
              value={values.contractDate}
              onChange={(evt) =>
                setFieldValue("contractDate", evt.target.value)
              }
              onBlur={handleBlur}
              error={
                errors.contractDate &&
                touched.contractDate &&
                errors.contractDate
              }
            />
        </Row>
        <Row>
          <Col md="4" className="mb-3">
            <label htmlFor="contract" style={{ display: "block" }}>
              Contrato:
            </label>
            <FileInput
              path="pregoes/contratos"
              name="Contrato"
              fileUrl={values.contract}
              onComplete={(downloadURL: any) => {
                setFieldValue("contract", downloadURL);
              }}
              onRemove={() => setFieldValue("contract", "")}
            />
          </Col>
          <Col md="4" className="mb-3">
            <label style={{ display: "block" }}>Outros:</label>
            <FilesList
              filesArray={values.otherFiles}
              path={"pregoes/outros"}
              onChange={(filesArray: any) =>
                setFieldValue("otherFiles", filesArray)
              }
            />
          </Col>
          <Col md="2" className="mb-3">
            <label>Valor total do pregão:</label>
            <p>{calcTotalPrice()}</p>
          </Col>
          {actualUser.role === "admin" && (
            <Col md="2" className="mb-3">
              <CustomReactSelect
                label="Vendedor"
                field="sellers"
                value={values.sellers}
                onChange={(field: any, newValue: any) => {
                  setFieldValue(field, newValue);
                }}
                onBlur={setFieldTouched}
                error={errors.sellers}
                touched={touched.sellers}
                options={sellers}
              />
            </Col>
          )}

          {actualUser.role !== "admin" && (
            <Col md="2" className="mb-3">
              <CustomReactSelect
                label="Vendedor"
                field="sellers"
                value={oldSellerValueLabel}
                onChange={(field: any, newValue: any) => {
                  setFieldValue(field, newValue);
                }}
                onBlur={setFieldTouched}
                error={errors.sellers}
                touched={touched.sellers}
                options={sellers}
                isDisabled={actualUser.role !== "admin"}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col md="6" className="mb-3">
            <TextArea
              name="observations"
              label="Observações"
              placeHolder="Observações"
              value={values.observations}
              onChange={(evt: any) => {
                handleChange(evt);
              }}
              onBlur={handleBlur}
            />
            {errors.observations && touched.observations && (
              <div className="error-div">{errors.observations}</div>
            )}
          </Col>
          <Col md="3" className="mb-3">
            <label htmlFor="daysForDelivery" style={{ display: "block" }}>
              Dias para entrega
            </label>
            <input
              id="daysForDelivery"
              type="number"
              value={values.daysForDelivery}
              onChange={(evt) => {
                setFieldValue("daysForDelivery", evt.target.value);
              }}
              onBlur={handleBlur}
            />
            {errors.daysForDelivery && touched.daysForDelivery && (
              <div className="error-div">{errors.daysForDelivery}</div>
            )}
          </Col>
          <Col>
            <InputAndLabel
              col={12}
              name="AuctionDate"
              label="Data pregão:"
              type="date"
              value={values.creationAuctionDate}
              onChange={(evt) =>
                setFieldValue("creationAuctionDate", evt.target.value)
              }
              onBlur={handleBlur}
              error={
                errors.creationAuctionDate &&
                touched.creationAuctionDate &&
                errors.creationAuctionDate
              }
            />
          </Col>
        </Row>
        <Row>
          {isEdit && !deliveredItems ? (
            <CenteredSpinner />
          ) : (
            <Col>
              <ItemList
                isEdit={isEdit}
                items={values.itemsList}
                addItem={addItem}
                deleteItem={deleteItem}
                deliveredItems={deliveredItems}
                notEdit={notEdit}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {errors.itemsList && touched.itemsList && (
              <div className="error-div">{errors.itemsList}</div>
            )}
          </Col>
        </Row>

        {!notEdit && (
          <Row className="mt-4">
            <Col className="text-right">
              <Button
                type="submit"
                color="success"
                disabled={isSubmitting || !auctionNumberPermited || validating}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        )}
      </form>
    </Fragment>
  );
};

export default AuctionForm;
