import React from "react";

interface NotificationProps {
  title?: string;
  description: string;
}

const Notification: React.FC<NotificationProps> = ({ title, description }) => (
  <div>
    {title && <h4>{title}</h4>}
    <p>{description}</p>
  </div>
);

export default Notification;
