import React from "react";
import { Col, Row, Input, Table } from "reactstrap";
import { money } from "utils/mask";
import idx from "idx";

const ItemList = ({
  isEdit,
  items,
  relatedProducts,
  setDeliveryItemsQuantity,
  values,
  isQuickView,
}) => {
  const formatItems = () => {
    let formattedItems = [];
    if (items)
      for (let [key, value] of Object.entries(items)) {
        if (values.pawn.data.pawnItemsQuantity[value.id] > 0)
          formattedItems.push(value);
      }
    return formattedItems;
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      rows.push(
        <tr>
          <td title="Modelo">
            {idx(relatedProducts, (_) => _[row?.product?.id].name)}
          </td>
          <td title="Marca">
            {idx(relatedProducts, (_) => _[row?.product?.id].brand?.name)}
          </td>
          <td title="Capacidade">{`${idx(
            relatedProducts,
            (_) => _[row?.product?.id].info
          )} ${idx(
            relatedProducts,
            (_) => _[row?.product?.id].unitOfMeasurement?.name
          )}`}</td>
          <td title="Instalado?">{row?.installed?.label}</td>
          <td title="Voltagem">{row?.voltage?.label}</td>
          <td title="Quantidade entregue">
            {idx(values?.previousDeliveryItems, (_) => _[row.id])
              ? values?.previousDeliveryItems[row.id]
              : 0}
          </td>
          <td title="Quantidade total no empenho">
            {values?.pawn?.data?.pawnItemsQuantity[row.id]}
          </td>
          <td title="Valor unitário">{money(row?.unitPrice)}</td>
          <td title="Quantidade nesta entrega">
            {isQuickView ? (
              <span>{values?.deliveryItemsQuantity[row.id]}</span>
            ) : (
              <Input
                type="number"
                min={0}
                style={{ maxWidth: "90px" }}
                value={values?.deliveryItemsQuantity[row.id]}
                onChange={(evt) => {
                  setDeliveryItemsQuantity(row.id, parseInt(evt.target.value));
                }}
              />
            )}
          </td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <div className="animated">
      <Row className="mt-4">
        <Col>
          <h3>Itens da entrega</h3>
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Capacidade</th>
            <th>Instalado?</th>
            <th>Voltagem</th>
            <th>Qtd. já entregue</th>
            <th>Qtd. total no empenho</th>
            <th>Valor unitário</th>
            <th>Quantidade nesta entrega</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
    </div>
  );
};

export default ItemList;
