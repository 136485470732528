import { createReducer } from "typesafe-actions";
import { UtilsActions } from "actions";

const {
  saveCompaniesCnpj,
  saveGovernmentAgenciesCnpj,
  clearCnpjList,
  saveBrandsNames,
  clearBrandsNames,
  saveUnitiesOfMeasurement,
  clearUnitiesOfMeasurement,
  saveProductFamily
} = UtilsActions;

const initialState = {
  companiesCnpj: [],
  governmentAgenciesCnpj: [],
  brandsNames: [],
  unitiesOfMeasurement: [],
  productFamily: [] 
};

const reducer = createReducer(initialState)
  .handleAction(saveCompaniesCnpj, (state, { payload }) => ({
    ...state,
    companiesCnpj: payload,
  }))
  .handleAction(saveGovernmentAgenciesCnpj, (state, { payload }) => ({
    ...state,
    governmentAgenciesCnpj: payload,
  }))
  .handleAction(clearCnpjList, (state) => ({
    ...state,
    companiesCnpj: [],
    governmentAgenciesCnpj: [],
  }))
  .handleAction(saveBrandsNames, (state, { payload }) => ({
    ...state,
    brandsNames: payload,
  }))
  .handleAction(clearBrandsNames, (state) => ({
    ...state,
    brandsNames: [],
  }))
  .handleAction(saveUnitiesOfMeasurement, (state, { payload }) => ({
    ...state,
    unitiesOfMeasurement: payload,
  }))
  .handleAction(clearUnitiesOfMeasurement, (state) => ({
    ...state,
    unitiesOfMeasurement: [],
  })).handleAction(clearUnitiesOfMeasurement, (state) => ({
    ...state,
    unitiesOfMeasurement: [],
  })).handleAction(saveProductFamily, (state, { payload }) => ({
    ...state,
    productFamily: payload,
  }))

export default reducer;
