import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { CustomReactSelect } from "components";
import { formatOptions } from "utils/general";
import dispatch, { LocationActions } from "actions";
import { useSelector } from "react-redux";
import { states, cities } from "utils/location";

interface observationCity {
  value: string;
  label: string;
}

const ObservationForm = (props: any) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    packingListArray,
    setFieldTouched,
    setFieldValue,
    observationsListArray,
    index,
  } = props;

  const [newCity, setNewCity] = useState("");
  const [oldCity, setOldCity] = useState("");
  const currentState = useSelector((state: any) => state.location.currentState);

  useEffect(() => {
    const observationCities: observationCity[] = packingListArray;
    if (values.state)
      dispatch(LocationActions.saveCurrentState, values.state.data);

    if (typeof index === "number") {
      values.observations =
        observationsListArray[index].observationsDescription;
    }
    getFocus('stateForm');
  }, []);


  function getFocus(id: string) {
    // document.getElementById(id)?.focus()
    window.setTimeout(function () {
      document.getElementById(id)?.focus();
    }, 0);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Estado"
            field="state"
            value={values.state}
            onChange={(field: any, newValue: any) => {
              dispatch(LocationActions.saveCurrentState, newValue.data);
              setFieldValue(field, newValue);
              setFieldValue("city", "");
            }}
            onBlur={setFieldTouched}
            error={errors.state}
            touched={touched.state}
            options={formatOptions(states, "states")}
            // autoFocus
            id='stateForm'
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Cidade"
            field="city"
            value={values.city}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
              if (newValue.value === oldCity) {
                setNewCity("");
              } else {
                setNewCity(newValue.value);
              }
            }}
            onBlur={setFieldTouched}
            error={errors.city}
            touched={touched.city}
            options={
              currentState && formatOptions(cities(currentState), "cities")
            }
            isDisabled={currentState ? false : true}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mb-3">
          <label htmlFor="observationsTitle" style={{ display: "block" }}>
            Local
          </label>
          <input
            name="observationsTitle"
            id="observationsTitle"
            placeholder="Informe pelo menos 6 caracteres."
            value={values.observationsTitle}
            onChange={(newValue) => {
              handleChange(newValue);
            }}
            onBlur={handleBlur}
          />
          {errors.observationsTitle && touched.observationsTitle && (
            <div className="error-div">{errors.observationsTitle}</div>
          )}
        </Col>
      </Row>
      <label htmlFor="observations" style={{ display: "block" }}>
        Detalhes
      </label>
      <textarea
        name="observations"
        placeholder="Informe pelo menos 6 caracteres."
        id="observations"
        value={values.observations}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        rows={4}
      />
      {errors.observations && touched.observations && (
        <div className="error-div">{errors.observations}</div>
      )}
      <div className="mt-3">
        {oldCity && newCity && (
          <p>
            A cidade atual é <b>{oldCity}</b>, você deseja alterar para{" "}
            <b>{newCity}</b> ?
          </p>
        )}
      </div>

      <Col className="mb-3">
        <hr />
      </Col>

      <Col className="text-right">
        <Button type="submit" color="success" disabled={isSubmitting}>
          Salvar
        </Button>
      </Col>
    </form>
  );
};

export default ObservationForm;
