import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import firebase from "config/firebase";
import { collectionTranlate } from "utils/general";
import { useSelector } from "react-redux";
import { CenteredSpinner } from "components";

interface DeleteModalPropsInterface {
  closeModal: any;
  path: string;
  type: string;
  modalItem: any;
  refreshList: any;
}

const DeleteModal = (props: DeleteModalPropsInterface) => {
  const { closeModal, path, type, modalItem, refreshList } = props;
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState("");
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(false);
  // const [typeIdentifier, setTypeIdentifier] = useState("name"); //parametro usado para identificar
  const [didDelete, setDidDelete] = useState(false);
  const db = firebase.firestore();
  const user = useSelector<any>((state) => state.user);

  const id = modalItem && modalItem.id;
  const [name, setName] = useState("");

  useEffect(() => {
    let name = modalItem ? modalItem.name : "";

    switch (type) {
      case "unitOfMeasurement":
        setCollection("products");
        break;

      case "brand":
        setCollection("products");
        break;

        case "family":
        setCollection("productFamily");
        break;

      case "governmentAgency":
        setCollection("auctions");
        break;

      case "auction":
        setCollection("pawns");
        // setTypeIdentifier("number");
        if (modalItem) name = modalItem.number;
        break;

      case "product":
        setCollection("productsInUse");
        if (modalItem)
          name = `${modalItem.brand.name} - ${modalItem.name} ${modalItem.info} ${modalItem.unitOfMeasurement.name}`;
        break;

      case "company":
        setCollection("auctions");
        break;

      case "pawn":
        setCollection("deliveries");
        // setTypeIdentifier("number");
        if (modalItem) name = modalItem.number;
        break;

      case "delivery":
        setCollection("xxx"); // Coleção qualquer pra ele executar o delete sem realmente pesquisar
        // setTypeIdentifier("number");
        if (modalItem) name = modalItem.number;
        break;

      case "packingList":
        setCollection("deliveries"); // Coleção qualquer pra ele executar o delete sem realmente pesquisar
        // setTypeIdentifier("number");
        if (modalItem) name = modalItem.number;
        break;

      default:
        break;
    }

    // Zerar valores para nova pesquisa
    setMessage(`Tem certeza que deseja excluir "${name}"?`);
    setVerified(false);
    setDidDelete(false);
    setName(name);
    // setTypeIdentifier("name");
  }, [modalItem]);

  const createLog = () => {
    const log = {
      action: "delete",
      reference: name,
      collection: path,
      doc: id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user,
    };
    return log;
  };

  const runBatch = (batch: any) => {
    batch
      .commit()
      .then(() => {
        console.log("Documento excluído com sucesso");
        setVerified(true);
        setLoading(false);
        setDidDelete(true);
        setMessage("Documento excluído com sucesso");
      })
      .catch((error: any) => {
        alert("Erro ao salvar unidade de medida");
        console.error("Erro ao salvar unidade de medida: ", error);
      });
  };

  const checkReferences = () => {
    setLoading(true);
    db.collection(collection)
      .where(`${type}.id`, "==", id)
      .get()
      .then((querySnapshot) => {
        let relatedDocs = querySnapshot.docs.map((doc) => doc.data());
        if (relatedDocs.length == 0) {
          let batch = db.batch();
          const docRef = db.collection(path).doc(id);
          const logRef = db.collection("logs").doc();
          batch.delete(docRef);
          batch.set(logRef, createLog());

          runBatch(batch);
        } else {
          setVerified(true);
          setLoading(false);
          const isPlural = relatedDocs.length > 1 && true;
          const message = `Não é possível excluir "${name}" porque existe${
            isPlural ? "m" : ""
          } ${relatedDocs.length} documento${isPlural ? "s" : ""} referenciado${
            isPlural ? "s" : ""
          } em "${collectionTranlate(collection).toLowerCase()}".`;

          // let relatedDocsNames: string[] = [];
          // relatedDocs.forEach(relatedUnit => {
          //   relatedDocsNames.push(relatedUnit[typeIdentifier]);
          // });
          // let items = relatedDocsNames.join(", ")

          setMessage(
            // message + '\n' + items
            message
          );
          // relatedDocs.forEach(relatedUnit => {
          // });
        }
      })
      .catch((error) => {
        setVerified(true);
        setLoading(false);
        setMessage(
          `Não foi possível verificar documentos referenciado a este registro.
          Por favor verifique a internet e tente novamente.`
        );
        console.log(`erro ao puxar dados de ${id}`);
        console.log(error);
      });
  };

  const checkProductReferences = () => {
    setLoading(true);
    db.collection(collection)
      .doc(modalItem.id)
      .get()
      .then((doc) => {
        const docData = doc.data();

        if (!docData) {
          let batch = db.batch();
          const docRef = db.collection(path).doc(id);
          const logRef = db.collection("logs").doc();
          batch.delete(docRef);
          batch.set(logRef, createLog());
          runBatch(batch);
        } else {
          setVerified(true);
          setLoading(false);
          const itemsQuantity = Object.keys(docData).length;
          const isPlural = itemsQuantity > 1 && true;
          const message = `Não é possível excluir "${name}" porque existe${
            isPlural ? "m" : ""
          } ${itemsQuantity} documento${isPlural ? "s" : ""} referenciado${
            isPlural ? "s" : ""
          } em "pregões".`;

          setMessage(message);
        }
      })
      .catch((error) => {
        setVerified(true);
        setLoading(false);
        setMessage(
          `Não foi possível verificar documentos referenciado a este registro.
            Por favor verifique a internet e tente novamente.`
        );
        console.log(`erro ao puxar dados de ${id}`);
        console.log(error);
      });
  };

  const handleConfirm = () => {
    if (!verified) {
      if (type == "product") {
        checkProductReferences();
      } else {
        checkReferences();
      }
    } else {
      if (didDelete) refreshList();
      closeModal();
    }
  };

  const text = message.split("\n").map((item, i) => <p key={i}>{item}</p>);

  return (
    <Modal isOpen={modalItem ? true : false} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Aviso</ModalHeader>
      <ModalBody>{loading ? <CenteredSpinner /> : text}</ModalBody>
      <ModalFooter>
        {!verified && (
          <Button color="secondary" onClick={closeModal}>
            Não
          </Button>
        )}
        <Button color="primary" onClick={handleConfirm}>
          {verified ? "Ok" : "Sim"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;