import { createReducer } from "typesafe-actions";
import { DeliveryActions } from "actions";

const {
    savePackingList,
} = DeliveryActions;

const initialState = {
    packingList: undefined
};

const reducer = createReducer(initialState)
  .handleAction(savePackingList, (state, { payload }) => ({
    ...state,
    packingList: payload,
  }));

export default reducer;
