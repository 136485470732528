import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetPawns } from "hooks";
import { CenteredSpinner } from "components";
import Filters from "./Filters";
import FiltersPrint from "./FiltersPrint";
import ReportTable from "./ReportTable";
import {
  formatCompaniesOptions,
  formatProductsOptions,
  formatVoltagesInvolved,
  formatBrandsOptions,
  formatPawnsOptions,
  formatStatusOptions,
} from "./formatters";

import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";
import uniqid from "uniqid";

const PawnReport = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState("startDate");

  const [company, setCompany] = useState({ value: "", label: "Todas" });
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [brand, setBrand] = useState({ value: "", label: "Todas" });
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [product, setProduct] = useState({ value: "", label: "Todos" });
  const [productsOptions, setProductsOptions] = useState([]);
  const [voltage, setVoltage] = useState({ value: "", label: "Todas" });
  const [voltagesOptions, setVoltagesOptions] = useState([]);
  const [formattedPawns, setFormattedPawns] = useState({
    value: "",
    label: "Nenhum",
  });
  const [formattedStatus, setFormattedStatus] = useState({
    value: "",
    label: "Todos",
  });
  const [pawnsOptions, setPawnsOptions] = useState([]);
  const [pawnDetail, setPawnDetail] = useState([]);
  const [isEditPawn, setIsEditPawn] = useState(false);

  const { loadingPawns, pawns: pawnsHook } = useGetPawns(
    "",
    startDate,
    endDate,
    true
  );
  const [pawns, setPawns] = useState([]);

  const [totalAuctionsItems, setTotalAuctionsItems] = useState({});
  const [filteredAuctionsItems, setFilteredAuctionsItems] = useState({});
  const [filteredPawnItemsQuantity, setFilteredPawnItemsQuantity] = useState(
    {}
  );
  const [totalPawnsItems, setTotalPawnsItems] = useState({});
  const [itemsByProductsAndVoltage, setItemsByProductsAndVoltage] = useState(
    {}
  );
  const [products, setProducts] = useState({});

  const [report, setReport] = useState([]);

  // Status
  useEffect(() => {
    if (!!pawnsHook) setPawns(pawnsHook);
    if (formattedStatus.label !== "Todos") {
      setPawns(
        pawnsHook.filter(
          (pawn) => pawn.deliveryStatus === formattedStatus.value
        )
      );
    }
  }, [pawnsHook, formattedStatus]);

  const resetFilters = () => {
    setCompany({ value: "", label: "Todas" });
    setBrand({ value: "", label: "Todas" });
    setProduct({ value: "", label: "Todos" });
    setVoltage({ value: "", label: "Todas" });
    setFormattedPawns({ value: "", label: "Nenhum" });
  };
  const resetOptions = () => {
    setCompaniesOptions([]);
    setBrandsOptions([]);
    setProductsOptions([]);
    setVoltagesOptions([]);
    setPawnsOptions([]);
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    resetFilters();
    resetOptions();
    let companiesInvolved = {};
    let voltagesInvolved = {};
    let relatedProducts = {}; // produtos (modelo, marca, etc) para serem baixados depois
    let totalAuctionsItems = {}; // itens dos auctions (voltagem, instalado, preço)
    pawns.map((pawn) => {
      companiesInvolved[pawn.auction.company.id] = pawn.auction.company;
      totalAuctionsItems = { ...totalAuctionsItems, ...pawn.auction.items };

      Object.keys(pawn.auction.items).forEach((key) => {
        let auctionItem = pawn?.auction?.items[key];
        let itemVoltage = auctionItem?.voltage;
        try {
          voltagesInvolved[itemVoltage?.value] = itemVoltage;
          relatedProducts[auctionItem?.product?.id] = auctionItem?.product;
        } catch (error) {}
      });
    });

    setCompaniesOptions(formatCompaniesOptions(companiesInvolved));
    setVoltagesOptions(formatVoltagesInvolved(voltagesInvolved));
    setTotalAuctionsItems(totalAuctionsItems);
    priorLoadProducts(relatedProducts);
  }, [pawns]);


  useEffect(() => {
    setLoading(true);
    setReport([]);
    let filteredAuctionsItems = {}; // itens dos auctions (voltagem, instalado, preço)
    let filteredPawnItemsQuantity = {};
    let totalPawnsItems = {}; // soma das quantidades dos empenhos
    pawns.map((pawn) => {
      if (company?.value && pawn?.auction?.company.id != company?.value) return;

      filteredAuctionsItems = {
        ...filteredAuctionsItems,
        ...pawn.auction.items,
      };
      filteredPawnItemsQuantity = {
        ...filteredPawnItemsQuantity,
        ...pawn.pawnItemsQuantity,
      };

      Object.keys(pawn.pawnItemsQuantity).forEach((key) => {
        let pawnItemQuantity = pawn?.pawnItemsQuantity[key]; 
        if (!totalPawnsItems[key]) totalPawnsItems[key] = 0;

        totalPawnsItems[key] += pawnItemQuantity;
      });
    });
    setTotalPawnsItems(totalPawnsItems);
    setFilteredAuctionsItems(filteredAuctionsItems);
    setFilteredPawnItemsQuantity(filteredPawnItemsQuantity);
  }, [totalAuctionsItems, company]);

  const priorLoadProducts = (items) => {
    loadProducts(items).then((prodSnapshots) => {
      let products = {};
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });

      setProducts(products);
      setProductsOptions(formatProductsOptions(products));
      setBrandsOptions(formatBrandsOptions(products));
      setStatusOptions(formatStatusOptions());
    });
  };

  const loadProducts = (relatedProducts) => {
    const promises = [];
    Object.keys(relatedProducts).forEach((key) => {
      let product = relatedProducts[key];
      promises.push(product.get());
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    let itemsByProductsAndVoltages = {};
    Object.keys(products).forEach((key) => {
      let product = products[key];
      Object.keys(filteredAuctionsItems).forEach((key) => {
        try {
          let auctionItem = filteredAuctionsItems[key];
          if (brand.value && brand.value !== product.brand.id) return;

          if (auctionItem?.product?.id === product?.id) {
            let quantity = totalPawnsItems[auctionItem?.id];
            if (!quantity) quantity = 0;
            const voltage = auctionItem?.voltage?.value ? auctionItem?.voltage?.value : "110";

            if (!itemsByProductsAndVoltages[product?.id])
              itemsByProductsAndVoltages[product?.id] = {};

            if (!itemsByProductsAndVoltages[product?.id][voltage])
              itemsByProductsAndVoltages[product?.id][voltage] = 0;

            itemsByProductsAndVoltages[product?.id][voltage] += quantity;
          }
        } catch (error) {}
      });
    });
    setItemsByProductsAndVoltage(itemsByProductsAndVoltages);
  }, [filteredAuctionsItems, totalPawnsItems, products, brand]);

  useEffect(() => {
    setLoading(true);
    setReport([]);
    formatReport(itemsByProductsAndVoltage);
  }, [itemsByProductsAndVoltage, voltage, product, formattedStatus]);

  const formatReport = (itemsByProductsAndVoltage) => {
    let report = [];
    let arrayBrands = [
      {
        value: "",
        label: "Todas",
      },
    ];
    Object.keys(itemsByProductsAndVoltage).forEach((productId) => {
      Object.keys(itemsByProductsAndVoltage[productId]).forEach(
        (itemVoltage) => {
          let canAdd = true;
          if (product?.value?.id && product?.value?.id !== productId) {
            canAdd = false;
          }

          if (voltage?.value && voltage.value !== itemVoltage) {
            canAdd = false;
          }

          const quantity = itemsByProductsAndVoltage[productId][itemVoltage];
          if (canAdd && !!quantity && products) {
            let totalUnits = 0;
            let unitPrice = 0;
            let middlePrice = 0;
            pawns.map((pawn) => {
              Object.keys(pawn?.auction?.items).map((key) => {
                try {
                  if (
                    pawn?.auction?.items[key]?.product?.id === productId &&
                    pawn?.auction?.items[key]?.voltage?.value === itemVoltage
                  ) {
                    totalUnits = pawn?.pawnItemsQuantity[key];
                    unitPrice = pawn?.auction?.items[key]?.unitPrice;
                    middlePrice += totalUnits * unitPrice;
                  } 
                } catch (error) {}
              });
            });

            if (!pawns.length) return;


            arrayBrands.push({
              value: products[productId]["brand"]["id"],
              label: products[productId]["brand"]["name"],
            });
            report.push({
              key: uniqid(),
              id: productId,
              model: products[productId]["name"],
              brand: products[productId]["brand"]["name"],
              brandId: products[productId]["brand"]["id"],
              info: products[productId]["info"],
              unitOfMeasurement: products[productId]["unitOfMeasurement"].name,
              voltage: itemVoltage,
              quantity,
              middlePrice: middlePrice / quantity,
            });
          }
        }
      );
    });
     
    let filtered = arrayBrands.reduce((filtered, item) => {
      if (
        !filtered.some(
          (filteredItem) => JSON.stringify(filteredItem) == JSON.stringify(item)
          )
          )
          filtered.push(item);
          return filtered;
        }, []);


        let reportFiltered = report.filter((e) => e.middlePrice !== 0);
    setReport(reportFiltered);
    setBrandsOptions(filtered);
    setLoading(false);
  };
  useEffect(() => {
    let filteredPawns = [];
    let relatedProducts = {};
    let productId = [];

    if (product.value !== "" && brand.value !== "") {
      pawns.map((pawn) => {
        Object.keys(pawn.auction.items).forEach((key) => {
          let auctionItem = pawn.auction.items[key];
          relatedProducts[auctionItem.product.id] = auctionItem.product;

          if (product.value.id === pawn.auction.items[key].product.id) {
            filteredPawns.push(pawn);
          }
        });
      });
    }

    if (filteredPawns !== []) {
      const newValues = filteredPawns.filter(
        (actual, newIndex) => filteredPawns.indexOf(actual) === newIndex
      );
      setPawnsOptions(
        formatPawnsOptions(newValues, product.label, brand.label)
      );
    }
  }, [product, brand, formattedStatus]);

  useEffect(() => {
    let filteredPawns = [];

    if (formattedPawns.value !== "") {
      filteredPawns = pawns.filter((pawn) => pawn.id === formattedPawns.value);
      setPawnDetail(filteredPawns);
    }
  }, [formattedPawns]);

  const isLoading = loadingPawns || loading;

  useEffect(() => {
    setProductsOptions(formatProductsOptions(products, brand.value));
    setProduct({ value: "", label: "Todos" });
  }, [brand]);

  useEffect(() => {
    setBrand({ value: "", label: "Todos" });
  }, [company]);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Relatório de Empenhos</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                orientation={"vertical"}
                openDirection={"down"}
                displayFormat={"DD-MM-YYYY"}
                isOutsideRange={() => false}
                startDatePlaceholderText="Data inicial"
                endDatePlaceholderText="Data final"
                phrases={ptBrPhrases}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Filters
                company={company}
                setCompany={setCompany}
                companiesOptions={companiesOptions}
                brand={brand}
                setBrand={setBrand}
                brandsOptions={brandsOptions}
                product={product}
                setProduct={setProduct}
                productsOptions={productsOptions}
                voltage={voltage}
                setVoltage={setVoltage}
                voltagesOptions={voltagesOptions}
                formattedPawns={formattedPawns}
                setFormattedPawns={setFormattedPawns}
                setFormattedStatus={setFormattedStatus}
                pawnsOptions={pawnsOptions}
                setIsEditPawn={setIsEditPawn}
                statusOptions={statusOptions}
                formattedStatus={formattedStatus}
              />
            </Col>
          </Row>
          <Row className="d-none d-print-block">
            <Col>
              <FiltersPrint
                company={company}
                brand={brand}
                product={product}
                voltage={voltage}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Button className="px-0" color="link" onClick={resetFilters}>
                apagar filtros
              </Button>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => window.print()}>
                Imprimir
              </Button>
            </Col>
          </Row>

          {error && (
            <Row className="mt-4">
              <Col className="text-center">
                <h4>Não foi possível concluir sua solicitação</h4>
                <br />
                Por favor tente novamente
              </Col>
            </Row>
          )}
          {!error && (
            <Row className="mt-4">
              {isLoading ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <ReportTable
                    data={report}
                    filteredAuctionsItems={filteredAuctionsItems}
                    pawns={pawns}
                    pawnDetail={pawnDetail}
                    isEditPawn={isEditPawn}
                    setIsEditPawn={setIsEditPawn}
                  />
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PawnReport;
