import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import firebase from "config/firebase";
import { collectionTranlate } from "utils/general";
import { useSelector } from "react-redux";
import { CenteredSpinner } from "components";

interface LeftModalPropsInterface {
  handleConfirm: any;
  handleClose: any;
  isOpen: boolean;
}

const LeftModal = (props: LeftModalPropsInterface) => {
  const { handleConfirm, handleClose, isOpen } = props;

  return (
    <Modal isOpen={isOpen} toggle={handleClose} centered>
      <ModalBody>As informações não serão salvas.{'\n'}Tem certeza que deseja sair?</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Não
          </Button>
        <Button color="primary" onClick={handleConfirm}>
          Sim
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeftModal;
