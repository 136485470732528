const userRolesOptions = [
  {
    value: "deliverer",
    label: "Entregador",
  },
  {
    value: "pledger",
    label: "Empenhador",
  },
  {
    value: "seller",
    label: "Vendedor",
  },
  {
    value: "financial",
    label: "Financeiro",
  },
  {
    value: "collection",
    label: "Cobrança",
  },
  {
    value: "admin",
    label: "Administrador",
  },
  {
    value: "",
    label: "Nenhum",
  },
];

export default userRolesOptions;

// - Administrador - Acesso total
// - Vendedor - Gerencia Pregões, Órgãos, Marcas, Unidades de medidas e Produtos
// - Empenhador - Gerencia empenhos
// - Entregador - Gerencia Entregas e Romaneios
