import React, { Fragment, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { money } from "utils/mask";
import "./styles.css";

const PawnReport = ({ data }) => {
  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Número Pregão",
    },
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "capacity",
      text: "Capacidade",
      formatter: (cell, row) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
    {
      sort: true,
      dataField: "price",
      text: "Preço Pregão",
      formatter: (cell, row) => money(cell),
    },
    {
      sort: true,
      dataField: "listPrice",
      text: "Preço Tabela",
      formatter: (cell, row) => (cell === "-" ? "-" : money(cell)),
    },
    {
      sort: true,
      text: "Diferença ",
      dataField: "priceDifference",
      formatter: (cell, row) => (cell === "-" ? "-" : money(cell)),
      style: (cell, row) => {
        const negativeNumber = Math.sign(cell) === -1;
        if (negativeNumber) {
          return {
            color: "red",
          };
        }
      },
    },
  ];

  return (
    <Fragment>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={false}
        hover
        wrapperClasses="table-responsive"
      />
    </Fragment>
  );
};

export default PawnReport;
