import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import dispatch, { EditActions } from "actions";

const BrandForm = (props: any) => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = props;

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (isLoading) setLoading(false)
    if (!isLoading) dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="name" style={{ display: "block" }}>
            Nome
          </label>
          <input
            id="name"
            placeholder="Nome"
            type="text"
            value={values.name}
            onChange={evt => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.name && touched.name && (
            <div className="error-div">{errors.name}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="text-right">
          <Button type="submit" color="success" disabled={isSubmitting}>
            Salvar
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default BrandForm;
