import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetProducts = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [products, setProducts] = useState([] as any);
  const [loadingProducts, setLoadingProducts] = useState(false);

  useEffect(() => {
    setLoadingProducts(true);
    db.collection("products")
      .get()
      .then((querySnapshot) => {
        setProducts(querySnapshot.docs.map((doc) => doc.data()));
        setLoadingProducts(false);
      })
      .catch((error) => {
        setLoadingProducts(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return { loadingProducts, products };
};

export default useGetProducts;
