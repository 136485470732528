import { DefaultLayout } from "containers";
import {
  Dashboard,
  PawnList,
  PawnDetails,
  PawnReport,
  CompanyList,
  CompanyDetails,
  AuctionList,
  AuctionDetails,
  AuctionReport,
  GovernmentAgencyList,
  GovernmentAgencyDetails,
  ProductList,
  ProductDetails,
  BrandList,
  BrandDetails,
  ProductFamilyList,
  ProductFamilyDetails,
  ProductReport,
  UnitOfMeasurementDetails,
  UnitOfMeasurementList,
  DeliveryList,
  DeliveryDetails,
  DeliveryPackingListSelection,
  PackingListDelivery,
  LogList,
  UserList,
  UserDetails,
  UserListReport,
  PackingListList,
  PackingListDetails,
  PackingListReport,
} from "screens";
const home = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
];
const dashboard = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
];
const pawns = [
  { path: "/empenhos", name: "Empenhos", component: PawnList, exact: true },
  { path: "/empenhos/detalhes", name: "Empenho", component: PawnDetails },
  { path: "/empenhos/relatorio", name: "Relatório", component: PawnReport },
];
const companies = [
  { path: "/empresas", name: "Empresas", component: CompanyList, exact: true },
  { path: "/empresas/detalhes", name: "Empresa", component: CompanyDetails },
];
const auctions = [
  { path: "/pregoes", name: "Pregões", component: AuctionList, exact: true },
  { path: "/pregoes/detalhes", name: "Pregão", component: AuctionDetails },
  { path: "/pregoes/relatorio", name: "Relatório", component: AuctionReport },
];
const governmentAgencyDetails = [
  { path: "/orgaos", name: "Órgãos", component: GovernmentAgencyList, exact: true },
  {
    path: "/orgaos/detalhes",
    name: "Órgão",
    component: GovernmentAgencyDetails,
  },
];
const products = [
  { path: "/produtos", name: "Produtos", component: ProductList, exact: true },
  { path: "/produtos/detalhes", name: "Produto", component: ProductDetails },
  { path: "/produtos/relatorio", name: "Relatório", component: ProductReport },
];
const brands = [
  { path: "/marcas", name: "Marcas", component: BrandList, exact: true },
  { path: "/marcas/detalhes", name: "Marca", component: BrandDetails },
];
const unitiesOfMeasurement = [
  {
    path: "/unidades-de-medida",
    name: "Unidades de medida",
    component: UnitOfMeasurementList,
    exact: true,
  },
  {
    path: "/unidades-de-medida/detalhes",
    name: "Unidade de medida",
    component: UnitOfMeasurementDetails,
  },
];
const deliveries = [
  { path: "/entregas", name: "Entregas", component: DeliveryList, exact: true },
  { path: "/entregas/detalhes", name: "Entrega", component: DeliveryDetails },
  {
    path: "/entregas/romaneio",
    name: "Entrega - Romaneio",
    component: DeliveryPackingListSelection,
  },
  {
    path: "/entregas/romaneios/detalhes",
    name: "Entrega",
    component: PackingListDelivery,
  },
];
const users = [
  { path: "/usuarios", name: "Usuários", component: UserList, exact: true },
  { path: "/usuarios/detalhes", name: "Usuário", component: UserDetails },
  { path: "/usuarios/relatorio", name: "Relatório", component: UserListReport },
];
const log = [{ path: "/log", name: "Log", component: LogList }];
const packingList = [
  { path: "/romaneios", name: "Romaneios", component: PackingListList, exact: true },
  { path: "/romaneios/detalhes", name: "Romaneio", component: PackingListDetails },
  { path: "/romaneios/relatorio", name: "Relatório", component: PackingListReport },
];

const productFamily = [
  { path: "/familias", name: "Familias", component: ProductFamilyList, exact: true },
  { path: "/familias/detalhes", name: "Familia", component: ProductFamilyDetails, exact: true },
];

const adminRoutes = [
  ...home,
  ...dashboard,
  ...pawns,
  ...companies,
  ...auctions,
  ...governmentAgencyDetails,
  ...products,
  ...brands,
  ...unitiesOfMeasurement,
  ...deliveries,
  ...users,
  ...log,
  ...packingList,
  ...productFamily
];

const sellerRoutes = [
  ...home,
  ...auctions,
  ...governmentAgencyDetails,
  ...products,
  ...brands,
  ...unitiesOfMeasurement,
];
const pledgerRoutes = [...pawns];
const delivererRoutes = [...deliveries, ...packingList];
const finacialRoutes = [...pawns];

export {
  adminRoutes,
  sellerRoutes,
  pledgerRoutes,
  delivererRoutes,
  finacialRoutes,
};
