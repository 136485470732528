import React, { useState, Fragment, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import PLDeliveryForm from "./PLDeliveryForm";
import { Button, Col, Row } from "reactstrap";
import { ComponentModal } from "components";
import PLDeliveryCard from "./DisplayPLDelivery/PLDeliveryCard";
import ObservationCard from "./DisplayObservation/ObservationCard";
import firebase from "config/firebase";
import idx from "idx";
import { notify } from "services";
import ObservationForm from "./ObservationForm";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dispatch, { EditButtonActions } from 'actions';


const PackingListList = ({
  typeOfButtonPrint,
  listing,
  packingListArray,
  observationsListArray,
  savePackingList,
  deletePackingList,
  updatePackingList,
  updateListing,
  updateObservationsListArray,
  pawnsInPL,
  saveOrderList,
  deleteOrderList,
  setTotal,
  objectTotal,
}) => {
  const [editPackingListIndex, setEditPackingListIndex] = useState("");
  const [observationListIndex, setObservationListIndex] = useState("");
  const isEdit = !!packingListArray[editPackingListIndex];
  const isObservationEdit = !!observationsListArray[observationListIndex];
  const [packingListModalIsOpen, setPackingListModalIsOpen] = useState(false);
  const [packingListOBSModalIsOpen, setPackingListOBSModalIsOpen] = useState(
    false
  );
  const db = firebase.firestore();
  const [originalItems, setOriginalItems] = useState({});
  const packingListArrayCities = [];

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    try {
      const itemMoving = reorder(
        listing,
        result.source.index,
        result.destination.index
      );
      handleRLDDChange(itemMoving);
    } catch (e) {
      console.log(e);
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "white" : "white",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
    width: "100%",
  });

  useEffect(() => {
    let index = 0;
    const packingListArrayItems = packingListArray;
    packingListArrayItems.map((response) => {
      packingListArrayCities.push({
        value: response.city,
        label: `${response.state.value} - ${response.city}`,
      });
      index++;
    });
  }, [packingListArray, packingListArrayCities]);

  useEffect(() => {
    if (isEdit) {
      setOriginalItems(
        packingListArray[editPackingListIndex].packingListItemsQuantity
      );
    } else {
      setOriginalItems({});
    }
  }, [editPackingListIndex, isEdit, packingListArray]);

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      pawnData: Yup.string().required("Selecione um empenho"),
      state: Yup.string().required("Selecione um estado"),
      city: Yup.string().required("Selecione uma cidade"),
    }),
    validate: (values) => {
      const errors = {};
      if (values.packingListItemsQuantity) {
        let totalToDeliver = 0;
        for (const key in values.packingListItemsQuantity) {
          const itemInPl =
            idx(pawnsInPL, (_) => _[values.pawnData.data.id][key]) || 0;

          const previousItemQuantity = originalItems[key] || 0;

          let qtdInUseOnthisPackingList = 0;
          for (const id of Object.keys(packingListArray)) {
            if (
              values.pawnData.data.number ===
              packingListArray[id].pawn.number &&
              !isEdit
            ) {
              qtdInUseOnthisPackingList +=
                packingListArray[id].packingListItemsQuantity[key];
            }
          }
          if (qtdInUseOnthisPackingList > 0 && itemInPl > 0)
            qtdInUseOnthisPackingList -= itemInPl;
          if (qtdInUseOnthisPackingList < 0) qtdInUseOnthisPackingList = 0;

          let previousPackingListItemQuantity =
            values.previousPackingListItems[key] || 0;
          const packingListItemQuantity = values.packingListItemsQuantity[key];
          totalToDeliver += packingListItemQuantity;
          if (isNaN(packingListItemQuantity))
            errors.packingListItemsQuantity = "Item sem quantidade";
          if (
            packingListItemQuantity +
            previousPackingListItemQuantity +
            qtdInUseOnthisPackingList +
            itemInPl -
            previousItemQuantity >
            values.pawnData.data.pawnItemsQuantity[key]
          ) {
            errors.packingListItemsQuantity =
              "Quantidade de itens supera a quantidade determinada no empenho";
          }
        }
        if (totalToDeliver === 0) {
          errors.packingListItemsQuantity = "Nenhum item a ser entregue";
        }
      }
      return errors;
    },

    handleSubmit: (values, { setSubmitting }) => {
      const newPackingList = {
        packingListItemsQuantity: values.packingListItemsQuantity,
        pawn: db.collection("pawns").doc(values.pawnData.data.id),
        address: values.address,
        observations: values.observations,
        state: {
          name: values.state.data.name,
          value: values.state.data.initials,
        },
        city: values.city.data,
      };

      let n = Math.max.apply(
        Math,
        packingListArray.map(function (o) {
          return o.id + 1;
        })
      );
      n = n === Infinity || n === -Infinity ? 1 : n;
      newPackingList["id"] = values.id
        ? values.id
        : packingListArray.length === 0
          ? 0
          : n;

      savePackingList(newPackingList, editPackingListIndex);
      closePackingListModal();
    },
    displayName: "PLDeliveryForm",
    mapPropsToValues: () => {
      let data = {
        id: "",
        packingListItemsQuantity: "",
        address: "",
        observations: "",
        pawn: "",
        pawnData: "",
        state: "",
        city: "",
      };
      if (isEdit) {
        const packingList = packingListArray[editPackingListIndex];
        data = {
          id: packingList.id,
          packingListItemsQuantity: packingList.packingListItemsQuantity,
          address: packingList.address,
          observations: packingList.observations,
          pawn: packingList.pawn,
          state: {
            label: packingList.state.name,
            value: packingList.state.value,
            data: {
              name: packingList.state.name,
              initials: packingList.state.value,
            },
          },
          city: { label: packingList.city, data: packingList.city },
        };
      }
      return data;
    },
  });

  const formikObservationEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      observationsTitle: Yup.string()
        .required("Informe o local")
        .min(6, "O local deve conter mais de 6 caracteres"),
      observations: Yup.string()
        .required("Informe os detalhes")
        .min(6, "O detalhe deve conter mais de 6 caracteres"),
      state: Yup.string().required("Selecione um estado"),
      city: Yup.string().required("Selecione uma cidade"),
    }),
    validate: (values) => {
      const errors = {};
      if (values.observations === "" || values.observationsTitle === "") {
        errors.observations = "Nenhum item foi inserido.";
      }

      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newObservation = {
        observationsTitle: values.observationsTitle,
        observationsDescription: values.observations,
        state: values.state,
        city: values.city,
        index: values.city.value,
      };
      let n = Math.max.apply(
        Math,
        observationsListArray.map(function (o) {
          return o.id + 1;
        })
      );
      n = n === Infinity || n === -Infinity ? 1 : n;
      newObservation["id"] = values.id
        ? values.id
        : observationsListArray.length === 0
          ? 0
          : n;
      saveOrderList(newObservation, observationListIndex);
      closeObservationModal();
    },
    displayName: "ObservationForm",
    mapPropsToValues: () => {
      let data = {
        id: "",
        observationsTitle: "",
        observations: "",
        state: "",
        city: "",
        index: "",
      };

      if (isObservationEdit) {
        const observationList = observationsListArray[observationListIndex];
        data = {
          id: observationList.id,
          observationsTitle: observationList.observationsTitle,
          observations: observationList.observations,
          state: observationList.state,
          city: observationList.city,
          index: {
            value: observationList.index,
            label: observationList.index,
          },
        };
      }

      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(PLDeliveryForm);
  const MyObservationEnhancedForm = formikObservationEnhancer(ObservationForm);

  const editPackingListModal = (index) => {
    if (!packingListArray[index].status) {
      setEditPackingListIndex(index);
      setPackingListModalIsOpen(true);
    } else {
      notify({
        description: "Não é possível editar um romaneio já entregue.",
        type: "danger",
      });
    }
  };

  const editObservationListModal = (index) => {
    if (!undefined) {
      setObservationListIndex(index);
      setPackingListOBSModalIsOpen(true);
    } else {
      notify({
        description: "Não é possível editar essa observação.",
        type: "danger",
      });
    }
  };

  const closePackingListModal = () => {
    setPackingListModalIsOpen(false);
    setEditPackingListIndex("");
    dispatch(EditButtonActions.setEditbutton, { isEdit: false });
  };

  const closeObservationModal = () => {
    setPackingListOBSModalIsOpen(false);
    setObservationListIndex("");
    dispatch(EditButtonActions.setEditbutton, { isEdit: false });
  };

  const handleSeachObservationIndex = (observationList) => {
    const searchIndex = observationsListArray.findIndex(
      (obsevation) => obsevation.id === observationList.id
    );

    if (searchIndex) {
      return searchIndex;
    }

    return 0;
  };

  const handleRLDDChange = (newList) => {
    updateListing(newList);
  };

  return (
    <Fragment>
      <ComponentModal
        name="Adicionar endereço ao romaneio"
        component={packingListModalIsOpen}
        closeModal={closePackingListModal}
        size="xl"
      >
        {packingListModalIsOpen && (
          <MyEnhancedForm
            packingListArray={packingListArray}
            editPackingListIndex={editPackingListIndex}
            pawnsInPL={pawnsInPL}
            originalItems={originalItems}
          />
        )}
      </ComponentModal>
      <ComponentModal
        name="Adicionar observação"
        component={packingListOBSModalIsOpen}
        closeModal={closeObservationModal}
        size="xl"
      >
        <MyObservationEnhancedForm
          packingListArray={packingListArrayCities}
          observationsListArray={observationsListArray}
          index={observationListIndex}
          isObservationEdit={isObservationEdit}
        />
      </ComponentModal>
      <Row className="d-print-none packingList-button-area">
        <Col sm={8}>
          <h4>Empenhos</h4>
        </Col>
        <Col>
          <Button
            block
            color="success"
            onClick={() => {
              setPackingListModalIsOpen(true);
              dispatch(EditButtonActions.setEditbutton, { isEdit: false })
            }}
          >
            Adicionar Empenho
          </Button>
        </Col>
        <Col>
          <Button
            block
            color="success"
            onClick={() => {
              setPackingListOBSModalIsOpen(true);
            }}
          >
            Adicionar Observação
          </Button>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {listing.map((item, index) => {
                console.log(item.id, index);
                if (
                  item.type === "packingListArray" &&
                  !packingListArray[item.pos]
                )
                  return <></>;
                if (
                  item.type === "observationsListArray" &&
                  !observationsListArray[item.pos]
                )
                  return <></>;
                return (
                  <Draggable
                    key={String(item.id)}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.type === "observationsListArray" ? (
                          <ObservationCard
                            typeOfButtonPrint={typeOfButtonPrint}
                            observationsList={observationsListArray[item.pos]}
                            editObservationListModal={() => {
                              editObservationListModal(
                                handleSeachObservationIndex(
                                  observationsListArray[item.pos]
                                )
                              );
                              dispatch(EditButtonActions.setEditbutton, { isEdit: true })
                            }}
                            deleteOrderList={deleteOrderList}
                            position={index}
                            index={handleSeachObservationIndex(
                              observationsListArray[item.pos]
                            )}
                          />
                        ) : (
                          <PLDeliveryCard
                            setTotal={setTotal}
                            objectTotal={objectTotal}
                            typeOfButtonPrint={typeOfButtonPrint}
                            packingList={packingListArray[item.pos]}
                            editPackingListModal={() => {
                              editPackingListModal(item.pos);
                            }}
                            deletePackingList={() => {
                              deletePackingList(item.pos);
                            }}
                            position={index}
                            isLast={item.pos === packingListArray.length - 1}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};

export default PackingListList;
