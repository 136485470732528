import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { InputAndLabel, FileInput } from "components";
import { cpf, removeMask } from "utils/mask";
import ItemList from "./ItemList";
import dispatch, { EditActions } from "actions";
import firebase from "config/firebase";

const PackingListDeliveryForm = (props: any) => {
  const {
    id,
    isEdit,
    pawn,
    packingList,
    packingListSelected,
    relatedProducts,
    deliveryItemsQuantity,
    isQuickView,
  } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = props;

  const db = firebase.firestore();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading) setLoading(false);
    if (!isLoading && values)
      dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="12" className="mb-3">
          <label style={{ display: "block" }}>
            Empenho | Número | Pregão | Órgão | Empresa:
          </label>
          Empenho: {pawn.number} | Número: {pawn.customPawnNumber} | Pregão:{" "}
          {pawn.auction.number} | Órgão: {pawn.auction.governmentAgency.name} |
          Empresa: {pawn.auction.company.name}
        </Col>
      </Row>

      <Row>
        <Col md="6" className="mb-3">
          <label style={{ display: "block" }}>Romaneio | Motorista:</label>
          Número: {packingList.number} | Motorista: {packingList.driver}
        </Col>
        <Col md="6" className="mb-3">
          <label style={{ display: "block" }}>Endereço:</label>
          {packingListSelected.address}, {packingListSelected.city} -{" "}
          {packingListSelected.state.value}
        </Col>
      </Row>

      <Row>
        <Col md="4" className="mb-3">
          <label htmlFor="nfeUrl" style={{ display: "block" }}>
            Nota fiscal:
          </label>
          <FileInput
            path="deliveries/notas"
            name="Nota fiscal"
            fileUrl={values.nfeUrl}
            onComplete={(downloadURL: any) => {
              setFieldValue("nfeUrl", downloadURL);
            }}
            onRemove={() => setFieldValue("nfeUrl", "")}
            isQuickView={isQuickView}
          />
        </Col>

        <InputAndLabel
          isQuickView={isQuickView}
          col={4}
          name="nfe"
          label="Número da série/NFe:"
          type="text"
          value={values.nfe}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={errors.nfe && touched.nfe && errors.nfe}
        />

        <InputAndLabel
          isQuickView={isQuickView}
          col={4}
          name="deliveryDate"
          label="Data da entrega:"
          type="date"
          value={values.deliveryDate}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={
            errors.deliveryDate && touched.deliveryDate && errors.deliveryDate
          }
        />
      </Row>
      <Row>
        <InputAndLabel
          isQuickView={isQuickView}
          col={4}
          name="deliveredBy"
          label="Entregue por:"
          type="text"
          value={values.deliveredBy}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={
            errors.deliveredBy && touched.deliveredBy && errors.deliveredBy
          }
        />

        <InputAndLabel
          isQuickView={isQuickView}
          col={4}
          name="deliveredTo"
          label="Entregue para:"
          type="text"
          value={values.deliveredTo}
          onChange={(evt) => handleChange(evt)}
          onBlur={handleBlur}
          error={
            errors.deliveredTo && touched.deliveredTo && errors.deliveredTo
          }
        />

        <InputAndLabel
          isQuickView={isQuickView}
          col={4}
          name="document"
          label="Documento:"
          type="text"
          value={
            values.document.length === 11
              ? cpf(values.document)
              : values.document
          }
          onChange={(evt) => {
            setFieldValue("document", removeMask(evt.target.value));
          }}
          onBlur={handleBlur}
          error={errors.document && touched.document && errors.document}
        />
      </Row>

      <Row>
        <Col>
          <ItemList
            items={pawn.auction.items}
            relatedProducts={relatedProducts}
            pawn={pawn}
            deliveryItemsQuantity={deliveryItemsQuantity}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          {errors.deliveryItemsQuantity && (
            <div className="error-div">{errors.deliveryItemsQuantity}</div>
          )}
        </Col>
      </Row>

      {!isQuickView && (
        <Row>
          <Col className="text-right">
            <Button type="submit" color="success" disabled={isSubmitting}>
              Salvar
            </Button>
          </Col>
        </Row>
      )}
    </form>
  );
};

export default PackingListDeliveryForm;
