import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetPackingListsByPawn = (pawnId: string) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [packingLists, setPackingLists] = useState([] as any);
  const [loadingPackingLists, setLoadingPackingLists] = useState(false);

  useEffect(() => {
      setLoadingPackingLists(true);
      db.collection("packingLists")
        .orderBy("createdAt", "asc")
        .get()
        .then((querySnapshot) => {
          // setPackingLists(querySnapshot.docs.map((doc) => doc.data()));
          let packingLists: any[] = []
          querySnapshot.docs.map((doc) => doc.data()).map(pl => {
            let add = false
            pl.packingListArray.forEach((singlePL: any) => {
              if (singlePL.pawn.id === pawnId) add = true
            });
            if (add) packingLists.push(pl)
          })
          setPackingLists(packingLists)
          setLoadingPackingLists(false);
        })
        .catch((error) => {
          setLoadingPackingLists(false);
          console.log("erro: ", error);
        });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, []);

  return { loadingPackingLists, packingLists };
};

export default useGetPackingListsByPawn;
