const tradingTypeArr = [
  {
    value: "presencial",
    label: "Presencial",
  },
  {
    value: "pregaoEletronico",
    label: "Pregão eletrônico",
  },
  {
    value: "tomadaDePreco",
    label: "Tomada de preço / Concorrência",
  },
  {
    value: "compraDireta",
    label: "Compra Direta",
  },
];

export default tradingTypeArr;
