import { useState } from "react";
import firebase from "config/firebase";
import { notify } from "services";

const useSendFile = () => {
  const db = firebase.firestore();
  const [sendingFile, setSendingFile] = useState(false);

  const sendFile = async ({ path, file, onComplete }: any) => {
    setSendingFile(true);
    const randomId = db.collection("deliveries").doc().id;

    let storageRef = firebase.storage().ref(`${path}/${randomId}`);
    let uploadTask = storageRef.put(file);

    const response = uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload " + progress + "%");
        // console.log(snapshot.state);
        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload pausado");
        //     break;
        //   case "running":
        //     console.log("Upload rodando");
        //     break;
        // }
      },
      (error) => {
        // Handle unsuccessful uploads
        notify({
          description:
            "Não foi possivel salvar o arquivo. Por favor, tente novamente.",
          type: "danger",
        });
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            setSendingFile(false);
            onComplete(downloadURL);
            notify({
              description: "Arquivo salvo com sucesso",
              type: "success",
            });
            return downloadURL;
          })
          .catch((error) => {
            console.log("erro", error);
            notify({
              description:
                "Não foi possivel salvar o arquivo. Por favor, tente novamente.",
              type: "danger",
            });
          });
      }
    );

    return response;
  };

  return { sendingFile, sendFile };
};

export default useSendFile;
