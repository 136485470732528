import { useState } from "react";
import firebase from "config/firebase";
import { notify } from "services";

const useGetPawnByNumber = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingPawns, setLoadingPawns] = useState(false);

  const getPawn = ({ number, auctionId, onComplete }: any) => {
    setLoadingPawns(true);
    db.collection("pawns")
      .where("customPawnNumber", "==", number)
      .where("auction.id", "==", auctionId)
      .get()
      .then((querySnapshot) => {
        const pawns = querySnapshot.docs.map((doc) => doc.data());
        setLoadingPawns(false);
        onComplete(pawns);
      })
      .catch((error) => {
        setLoadingPawns(false);
        console.log("erro: ", error);
        notify({
          description: "Não foi possivel validar o empenho.",
          type: "danger",
        });
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loadingPawns, getPawn };
};

export default useGetPawnByNumber;
