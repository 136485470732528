import React from "react";
import { CustomReactSelect } from "components";
import { Button, Col, Row } from "reactstrap";
import { useGetProducts } from "hooks";
import { formatOptions, yesAndNo } from "utils/general";
import { money, removeMask } from "utils/mask";

const ItemForm = (props: any) => {
  const { editItem } = props;
  const { loadingProducts, products } = useGetProducts();

  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Produto"
            field="product"
            value={values.product}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
            }}
            onBlur={setFieldTouched}
            error={errors.product}
            touched={touched.product}
            options={formatOptions(products, "products")}
          />
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="totalUnits" style={{ display: "block" }}>
            Quantidade:
          </label>
          <input
            id="totalUnits"
            type="number"
            min={0}
            value={values.totalUnits}
            onBlur={handleBlur}
            onChange={(evt) => {
              handleChange(evt);
            }}
          />
          {errors.totalUnits && touched.totalUnits && (
            <div className="error-div">{errors.totalUnits}</div>
          )}
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="unitPrice" style={{ display: "block" }}>
            Valor unitário:
          </label>
          <input
            id="unitPrice"
            type="text"
            value={money(values.unitPrice)}
            onChange={(evt) => {
              setFieldValue("unitPrice", removeMask(evt.target.value));
            }}
            onBlur={handleBlur}
            maxLength={18}
          />
          {errors.unitPrice && touched.unitPrice && (
            <div className="error-div">{errors.unitPrice}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="3" className="mb-3">
          <CustomReactSelect
            label="Instalado"
            field="installed"
            value={values.installed}
            onChange={(field: any, newValue: any) => {
              setFieldValue(field, newValue);
            }}
            onBlur={setFieldTouched}
            error={errors.installed}
            touched={touched.installed}
            options={yesAndNo}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-right">
          <Button type="submit" color="success" disabled={isSubmitting}>
            {editItem ? "Salvar" : "Adicionar"}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default ItemForm;
