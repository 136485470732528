import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import moment from "moment";
import "./style.css";

const ObservationCard = ({
  typeOfButtonPrint,
  observationsList,
  editObservationListModal,
  deleteOrderList,
  position,
  index,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [titleIsVisible, setTitleIsVisible] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const singleItem = (size: number, label: string, value: any) => {
    return (
      <Col md={size} className="mb-3">
        <label className="small-text mb-0" style={{ display: "block" }}>
          {label}
        </label>
        <span className="small-text">{value ? value : "..."}</span>
      </Col>
    );
  };

  const chevDown = <i className="fa fa-chevron-down" aria-hidden="true"></i>;
  const chevUp = <i className="fa fa-chevron-up" aria-hidden="true"></i>;

  useEffect(() => {
    const buttonPrintType = sessionStorage.getItem("@ecoblu/typeOfButton");
    sessionStorage.setItem(
      "@ecoblu/observation-city",
      observationsList.city.value
    );
    if (buttonPrintType === "resumido") {
      const city = sessionStorage.getItem("@ecoblu/city");

      if (observationsList.city.label === city) {
        setTitleIsVisible(false);
      }
    }
  }, [typeOfButtonPrint]);

  return (
    <Card>
      {observationsList.city?.value && titleIsVisible && (
        <h4
          className="d-none d-print-block"
          style={{ marginTop: 20 }}
        >{`${observationsList.city?.value}, ${observationsList.state?.value}`}</h4>
      )}
      <CardHeader className="small-header-padding-observation d-flex d-print-none">
        <div className="align-self-center mt-200">{position + 1}</div>
        <Button onClick={toggle} className="card-header-buttons btn-ghost-dark">
          {isOpen ? chevUp : chevDown}
        </Button>
        {observationsList !== [] && observationsList.city?.value && (
          <b className="card-header-observation ml-50">
            {/* Observação */}
            {`${observationsList.city?.value} - ${observationsList.state?.value}`}
          </b>
        )}
        <span className="ml-auto mr-0">
          {/* <label> */}
          <label className="small-text mb-0">Data da observação: </label>
          <span className="card-header-created-at">
            {moment(observationsList.createdAt).format("DD/MM/YYYY")}
          </span>
          {/* </label> */}
          {observationsList !== [] && (
            <Button
              color="info"
              onClick={editObservationListModal}
              className="card-header-buttons"
            >
              Editar
            </Button>
          )}{" "}
          <Button
            color="danger"
            onClick={() => deleteOrderList(index)}
            className="card-header-buttons"
          >
            Excluir
          </Button>
        </span>
      </CardHeader>

      <CardBody>
        {observationsList === undefined && (
          <Row>
            <Col className="text-center text-danger">
              Erro ao baixar a observação. A observação provavelmente foi
              excluída.
            </Col>
          </Row>
        )}
        {observationsList !== undefined && (
          <Fragment>
            <Row>
              {singleItem(9, "Local", observationsList.observationsTitle)}
              {/* {singleItem(
                3,
                "Data da observação",
                moment(observationsList.createdAt).format("DD/MM/YYYY")
              )} */}
            </Row>
            <Collapse isOpen={isOpen}>
              <hr />
              <Row>
                {singleItem(
                  12,
                  "Detalhes",
                  observationsList.observationsDescription
                )}
              </Row>
              <hr className="d-none d-print-block" />
            </Collapse>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default ObservationCard;
