import React, { Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import BrandForm from "./ProductFamilyForm";
import { Card, CardHeader, CardBody, Col, Spinner } from "reactstrap";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGetProductFamily } from "hooks";
import { CenteredSpinner } from "components";
import dispatch, { EditActions } from "actions";

const ProductFamilyDetails = ({ isModal = false, closeModal }) => {
  const history = useHistory();
  const isEdit =
    !isModal && idx(history, (_) => _.location.state.row.id) ? true : false;
  const id = idx(history, (_) => _.location.state.row.id);
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();
  const { loadingProductFamily, productFamily } = useGetProductFamily();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Insira o nome da família"),
      price: Yup.string().required("Insira o preço da família"),
    }),
    validate: (values) => {
      const errors = {};
      if (values.name && productFamily) {
        const lowerCaseName = values.name.toLowerCase();
        if (productFamily.indexOf(lowerCaseName) > -1)
          errors.name = "Nome já registrado";
      }
      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newBrand = {
        name: values.name,
        price: parseInt(values.price),
      };

      const docId = isEdit ? id : db.collection("productFamily").doc().id;
      newBrand["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newBrand["createdAt"] = values["createdAt"];
        newBrand["updatedAt"] = serverTimestamp;
      } else {
        newBrand["createdAt"] = serverTimestamp;
      }

      const docRef = db.collection("productFamily").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newBrand["name"],
        collection: "productFamily",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let batch = db.batch();
      if (isEdit) {
        log["action"] = "update";
        batch.update(docRef, newBrand);
      } else {
        batch.set(docRef, newBrand);
      }
      batch.set(logRef, log);

      batch
        .commit()
        .then(() => {
          console.log("Família salva com sucesso");
          setSubmitting(false);
          {
            isModal ? closeModal() : history.goBack();
          }
        })
        .catch(function(error) {
          alert("Erro ao salvar família");
          console.error("Erro ao salvar família: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "ProductFamilyForm",
    mapPropsToValues: () => {
      let data = {
        name: "",
        createdAt: "",
        price: "",
      };
      if (isEdit) {
        const row = history.location.state.row;
        data = {
          name: row.name,
          createdAt: row.createdAt,
          price: row.price,
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(BrandForm);

  if (isModal)
    return (
      <Fragment>
        {loadingProductFamily ? <CenteredSpinner /> : <MyEnhancedForm />}
      </Fragment>
    );
  return (
    <div className="animated">
      <Card>
        <CardHeader>Família</CardHeader>
        <CardBody>
          {loadingProductFamily ? <CenteredSpinner /> : <MyEnhancedForm />}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductFamilyDetails;
