import React, { Fragment, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import PawnForm from "./PawnForm";
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  Button,
  Col,
  Row,
} from "reactstrap";
import firebase from "config/firebase";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DeliveriesList from "./DeliveriesList";
import PackingListList from "./PackingListList";
import dispatch, { EditActions } from "actions";

const PawnDetails = ({ history, quickViewPawn }) => {
  const location = useLocation();
  const isQuickView = !!quickViewPawn;
  const currentPawn = isQuickView ? quickViewPawn : location?.state?.row;
  const id = currentPawn?.id;
  const isEdit = !!id;
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const [pawnModalCondition, setPawnModalCondition] = useState(false);
  const [pawnNumber, setPawnNumber] = useState(false);

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      auction: Yup.string().required("Selecione um empenho"),
      receivedAt: Yup.date().required("Insira a data de recebimento do pedido"),
      deliveryDays: Yup.number("Precisa ser um número")
        .required("Insira o número de dias para a entrega")
        .typeError("Precisa ser um número"),
    }),
    validate: (values) => {
      const errors = {};
      if (values.pawnItemsQuantity) {
        let totalPawned = 0;
        for (const key in values.pawnItemsQuantity) {
          let previousPawnsItemQuantity;
          try {
            previousPawnsItemQuantity = values?.previousPawnItems[key]
              ? values.previousPawnItems[key]
              : 0;
          } catch (error) {
            previousPawnsItemQuantity = 0;
          }
          const pawnItemQuantity = values.pawnItemsQuantity[key];
          totalPawned += pawnItemQuantity;
          if (isNaN(pawnItemQuantity))
            errors.pawnItemsQuantity = "Item sem quantidade";
          if (
            pawnItemQuantity + previousPawnsItemQuantity >
            values.auction?.data?.items[key]?.totalUnits
          ) {
            errors.pawnItemsQuantity =
              "Quantidade de itens supera a quantidade determinada no pregão.";
          }
        }
        if (totalPawned === 0) {
          errors.pawnItemsQuantity = "Nenhum item a ser empenhado";
        }
      }

      for (const key in values.pawnItemsQuantity) {
        if (key) {
          const pawnItemQuantity = values.pawnItemsQuantity[key];
          let totalPawned = 0;
          totalPawned += pawnItemQuantity;
          const voltageValue = values.auction?.data?.items[key]?.voltage;
          if (totalPawned > 0) {
            if (
              typeof voltageValue === "undefined" ||
              voltageValue.label === "Indefinido"
            ) {
              errors.voltageValue = "Item sem voltagem.";
            }
          }
        }
      }
      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newPawn = {
        auction: values.auction.data,
        pawnItemsQuantity: values.pawnItemsQuantity,
        receivedAt: values.receivedAt,
        deliveryDays: values.deliveryDays,
        number: values.number,
        observations1: values.observations1 ? values.observations1 : "",
        observations2: values.observations2 ? values.observations2 : "",
        customPawnNumber: values.customPawnNumber,
        deliveryStatus: values.deliveryStatus,
      };

      const docId = isEdit ? id : db.collection("pawns").doc().id;
      newPawn["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newPawn["createdAt"] = values["createdAt"];
        newPawn["updatedAt"] = serverTimestamp;
      } else {
        newPawn["createdAt"] = serverTimestamp;
      }

      const counterRef = db.collection("generalSettings").doc("counters");
      const docRef = db.collection("pawns").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newPawn["number"],
        collection: "pawns",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let action;
      if (isEdit) {
        let batch = db.batch();
        batch.update(docRef, newPawn);
        log["action"] = "update";
        batch.set(logRef, log);
        action = batch.commit();
      } else {
        action = db.runTransaction(function(transaction) {
          return transaction.get(counterRef).then(function(countersDoc) {
            const newPawnCount = countersDoc.data().pawns + 1;
            newPawn["number"] = newPawnCount;
            setPawnNumber(newPawnCount);
            log["reference"] = newPawnCount;
            transaction.set(docRef, newPawn);
            transaction.update(counterRef, { pawns: newPawnCount });
            transaction.set(logRef, log);
          });
        });
      }

      action
        .then(() => {
          console.log("Empenho salvo com sucesso");
          setSubmitting(false);
          setPawnModalCondition(true);
        })
        .catch(function(error) {
          alert("Erro ao salvar empenho");
          console.error("Erro ao salvar empenho: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "PawnForm",
    mapPropsToValues: () => {
      let data = {
        id: "",
        deliveryDays: "",
        receivedAt: "",
        pawnItemsQuantity: "",
        createdAt: "",
        number: "",
        observations1: "",
        observations2: "",
        customPawnNumber: "",
        auction: "",
        deliveryStatus: 0, // pendente
      };
      if (isEdit) {
        data = {
          id: currentPawn.id,
          deliveryDays: currentPawn.deliveryDays,
          receivedAt: currentPawn.receivedAt,
          pawnItemsQuantity: currentPawn.pawnItemsQuantity,
          createdAt: currentPawn.createdAt,
          number: currentPawn.number,
          observations1: currentPawn.observations1,
          observations2: currentPawn.observations2,
          customPawnNumber: currentPawn.customPawnNumber,
          setVoltageValue: currentPawn.setVoltageValue,
          deliveryStatus: currentPawn.deliveryStatus,
          auction: {
            data: currentPawn.auction,
            label: `${currentPawn.auction.number &&
              currentPawn.auction.number} - ${currentPawn.auction
              .governmentAgency && currentPawn.auction.governmentAgency.name}`,
          },
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(PawnForm);

  const handleBackPage = () => {
    setPawnModalCondition(false);
    history.goBack();
  };

  const handleExitModal = () => setPawnModalCondition(!pawnModalCondition);

  return (
    <div className="animated">
      <Modal isOpen={pawnModalCondition} toggle={handleExitModal}>
        <Col className="mb-3 mt-2">
          <Col md="12" className="mb-2 mt-3">
            <h3>Empenho criado com sucesso !</h3>
          </Col>
          <hr />
          <Col md="12" className="mb-1 mt-4">
            <h5>Número do pedido: {pawnNumber}</h5>
          </Col>
          <hr />
          <Row className="text-right mb-2">
            <Col>
              <Button
                className="mr-1"
                color="secondary"
                onClick={handleBackPage}
              >
                sair
              </Button>

              <Button color="primary" onClick={handleExitModal}>
                ok
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal>
      {isQuickView ? (
        <Fragment>
          <MyEnhancedForm isEdit={isEdit} isQuickView={isQuickView} />
          <PackingListList pawnId={currentPawn.id} />
          <DeliveriesList pawnId={currentPawn.id} />
        </Fragment>
      ) : (
        <Card>
          <CardHeader>
            {`Empenho ${isEdit ? "#" + currentPawn.number : ""}`}
          </CardHeader>
          <CardBody>
            <MyEnhancedForm isEdit={isEdit} id={id} isQuickView={isQuickView} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default PawnDetails;
