import React, { useEffect, useState } from "react";
import { CustomReactSelect } from "components";
import { Button, Col, Row } from "reactstrap";
import { useGetPawns } from "hooks";
import { formatOptions } from "utils/general";
import ItemList from "./ItemList";
import firebase from "config/firebase";
import dispatch, { LocationActions } from "actions";
import { useSelector } from "react-redux";
import { states, cities } from "utils/location";

const PLDeliveryForm = (props: any) => {
  const {
    packingListArray,
    editPackingListIndex,
    pawnsInPL,
    originalItems,
  } = props;
  const { loadingPawns, pawns } = useGetPawns(undefined, undefined, undefined, undefined, undefined, true);
  const [relatedProducts, setRelatedProducts] = useState<any>(null);
  const [pawnData, setPawnData] = useState<any>(undefined);
  const isEdit = !!packingListArray[editPackingListIndex];
  const db = firebase.firestore();
  const currentState = useSelector((state: any) => state.location.currentState);

  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = props;

  useEffect(() => {
    if (isEdit) {
      // priorLoadProducts(values.pawn.data.auction.items);
      // loadRelatedPackingLists(values.pawn.data.id);
      priorLoadPawn();
      dispatch(LocationActions.saveCurrentState, values.state.data);
    }

    return () => {
      dispatch(LocationActions.clearCurrentState);
    };
  }, []);

  const priorLoadPawn = () => {
    let pawn;
    try {
      values.pawn.get().then((pawnSnap: any) => {
        pawn = pawnSnap.data();
      });
    } catch (error) {
      pawn = values.pawn;
    }
    const pawnData = {
      data: pawn,
      value: pawn.number && pawn.number,
      label:
        pawn.number &&
        `Empenho: ${pawn.number}${pawn.customPawnNumber ? ` | Número:  ${pawn.customPawnNumber}` : ""
        } | Pregão: ${pawn.auction.number} | Órgão: ${pawn.auction.governmentAgency.name
        } | Empresa: ${pawn.auction.company.name}`,
    };
    setFieldValue("pawnData", pawnData);
    setPawnData(pawn);
    priorLoadProducts(pawnData.data.auction.items);
    loadRelatedPackingLists(pawnData.data.id);
  };

  const priorLoadProducts = (items: any) => {
    loadProducts(items).then((prodSnapshots: any) => {
      let products: any = {};
      prodSnapshots.forEach((prodSnap: any) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setRelatedProducts(products);
    });
  };

  const loadProducts = (itemsWithProducts: any) => {
    const promises: any = [];
    let packingListItemsQuantity: any = {};

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      promises.push(item.product.get());
      packingListItemsQuantity[item.id] = 0;
    });
    if (!isEdit)
      setFieldValue("packingListItemsQuantity", packingListItemsQuantity);

    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const setPackingListItemsQuantity = (id: string, quantity: number) => {
    setFieldValue("packingListItemsQuantity", {
      ...values.packingListItemsQuantity,
      [id]: quantity,
    });
  };

  const loadRelatedPackingLists = (pawnId: string) => {
    db.collection("deliveries")
      .where("pawn.id", "==", pawnId)
      .get()
      .then((querySnapshot) => {
        let relatedDeliveries = querySnapshot.docs.map((doc) => doc.data());
        calculateDeliveredItems(relatedDeliveries);
      })
      .catch((error) => {
        console.log("erro: ", error);
      });
  };

  const calculateDeliveredItems = (relatedDeliveries: any) => {
    let previousPackingListItems: any = {};
    relatedDeliveries.forEach((relatedDelivery: any) => {
      if (relatedDelivery.id !== values.id) {
        let deliveryItemsQuantity = relatedDelivery.deliveryItemsQuantity;
        for (const deliveryItemId in deliveryItemsQuantity) {
          if (deliveryItemsQuantity.hasOwnProperty(deliveryItemId)) {
            if (previousPackingListItems[deliveryItemId]) {
              previousPackingListItems[deliveryItemId] +=
                deliveryItemsQuantity[deliveryItemId];
            } else {
              previousPackingListItems[deliveryItemId] =
                deliveryItemsQuantity[deliveryItemId];
            }
          }
        }
      }
    });
    // packingListArray.forEach((relatedPackingList: any) => {
    //   if (relatedPackingList.id != values.id) {
    //     let packingListItemsQuantity =
    //       relatedPackingList.packingListItemsQuantity;
    //     for (const packingListItemId in packingListItemsQuantity) {
    //       if (packingListItemsQuantity.hasOwnProperty(packingListItemId)) {
    //         if (previousPackingListItems[packingListItemId]) {
    //           previousPackingListItems[packingListItemId] +=
    //             packingListItemsQuantity[packingListItemId];
    //         } else {
    //           previousPackingListItems[packingListItemId] =
    //             packingListItemsQuantity[packingListItemId];
    //         }
    //       }
    //     }
    //   }
    // });
    setFieldValue("previousPackingListItems", previousPackingListItems);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md="12" className="mb-3">
          <CustomReactSelect
            label="Empenho | Número | Pregão | Órgão | Empresa"
            field="pawnData"
            value={values.pawnData}
            onChange={(field: any, newValue: any) => {
              setRelatedProducts(null);
              setFieldValue(field, newValue);
              priorLoadProducts(newValue.data.auction.items);
              loadRelatedPackingLists(newValue.data.id);
              setFieldValue("address", newValue.data.observations1);
            }}
            onBlur={setFieldTouched}
            error={errors.pawnData}
            touched={touched.pawnData}
            options={formatOptions(pawns, "pawn")}
            isQuickView={!!isEdit}
            autoFocus
          />
        </Col>
      </Row>

      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Estado"
            field="state"
            value={values.state}
            onChange={(field: any, newValue: any) => {
              dispatch(LocationActions.saveCurrentState, newValue.data);
              setFieldValue(field, newValue);
              setFieldValue("city", "");
            }}
            onBlur={setFieldTouched}
            error={errors.state}
            touched={touched.state}
            options={formatOptions(states, "states")}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Cidade"
            field="city"
            value={values.city}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            error={errors.city}
            touched={touched.city}
            options={
              currentState && formatOptions(cities(currentState), "cities")
            }
            isDisabled={currentState ? false : true}
          />
        </Col>
      </Row>

      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="address" style={{ display: "block" }}>
            Local de Entrega do Romaneio
          </label>
          <input
            id="address"
            type="text"
            value={values.address}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.address && touched.address && (
            <div className="error-div">{errors.address}</div>
          )}
        </Col>
        <Col md="6" className="mb-6">
          <label htmlFor="observations" style={{ display: "block" }}>
            Observações / Instruções de entrega
          </label>
          <input
            id="observations"
            type="text"
            value={values.observations}
            onChange={(evt) => {
              handleChange(evt);
            }}
            onBlur={handleBlur}
          />
          {errors.observations && touched.observations && (
            <div className="error-div">{errors.observations}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <ItemList
            items={
              relatedProducts &&
              values.pawnData &&
              values.pawnData.data.auction.items
            }
            packingListArray={packingListArray}
            pawnData={values.pawnData}
            relatedProducts={relatedProducts}
            setPackingListItemsQuantity={setPackingListItemsQuantity}
            values={values}
            pawnsInPL={pawnsInPL}
            originalItems={originalItems}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {errors.packingListItemsQuantity && (
            <div className="error-div">{errors.packingListItemsQuantity}</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="text-right">
          <Button
            type="submit"
            color="success"
            disabled={isSubmitting || values.city === "" || values.state === ""}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default PLDeliveryForm;
