import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetUsers } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CenteredSpinner } from "components";
import { statusOptions, userRolesOptions } from "utils/general";

const UserList = ({ history }) => {
  const { SearchBar } = Search;
  const [count, setCount] = useState(0);
  const { loadingUsers, users } = useGetUsers(count);

  const columns = [
    {
      sort: true,
      dataField: "name",
      text: "Nome",
    },
    {
      sort: true,
      dataField: "email",
      text: "E-mail",
    },
    {
      text: "Papel",
      formatter: (cell, row) =>
        userRolesOptions.find((role) => role.value === row.role)?.label,
    },
    {
      text: "Status",
      formatter: (cell, row) =>
        statusOptions.find((status) => status.value === row.status)?.label,
    },
    {
      isDummyField: true,
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-note action-button"
              onClick={() => {
                history.push({
                  pathname: "./usuarios/detalhes",
                  state: { row },
                });
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const searchBarRef = useRef()
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus()
  }, [])

  return (
    <>
      <div className="animated">
        <Card>
          <CardHeader>Usuários</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={users}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={12}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {loadingUsers ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default UserList;
