import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { money } from "utils/mask";
import { voltageTranslation } from "utils/general";
import moment from "moment";
import { CenteredSpinner } from "components";

const PackingListDetails = ({ data }: { data: any }) => {
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);

  const load = async () => {
    setLoading(true);
    let products: any[] = [];

    let promise_data = Object.keys(data.packingListArray).map(async (array) => {
      let pawn: any;
      if (data.packingListArray[array].pawn?.auction)
        pawn = data.packingListArray[array].pawn;
      if (!data.packingListArray[array].pawn?.auction)
        await data.packingListArray[array].pawn.get().then((snap: any) => {
          pawn = snap.data();
        });

      let promise_items = Object.keys(
        data.packingListArray[array].packingListItemsQuantity
      ).map(async (key) => {
        if (data.packingListArray[array].packingListItemsQuantity[key] > 0) {
          let product: any;
          if (pawn.auction?.items) {
            await pawn.auction.items[key].product.get().then((snap: any) => {
              product = snap.data();
            });
            product["voltage"] = pawn.auction.items[key].voltage;
            product["quantity"] =
              data.packingListArray[array].packingListItemsQuantity[key];
            product["middlePrice"] =
              product["quantity"] * pawn.auction.items[key].unitPrice;

            let add = true;
            let promise_product = products.map((item) => {
              if (
                item.id === product.id &&
                item.info === product.info &&
                item.brand.name === product.brand.name &&
                item.unitOfMeasurement.name ===
                  product.unitOfMeasurement.name &&
                item.voltage.label === pawn.auction.items[key].voltage.label
              ) {
                add = false;
                item.middlePrice += product["middlePrice"];
                item.quantity += product["quantity"];
              }
            });
            await Promise.all(promise_product);
            if (add) products.push(product);
          }
        }
      });
      await Promise.all(promise_items);
    });
    await Promise.all(promise_data);
    setProducts(products);
    setLoading(false);
  };

  useEffect(() => {
    if (data) load();
  }, [data]);

  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Romaneio nº",
    },
    {
      sort: true,
      dataField: "driver",
      text: "Motorista",
    },
    {
      sort: true,
      dataField: "createdAt.seconds",
      text: "Criado em",
      formatter: (cell: any, row: any) => {
        return <p>{moment(cell * 1000).format("DD/MM/YYYY, HH:mm")}</p>;
      },
    },
    {
      sort: true,
      dataField: "status",
      text: "Situação da entrega",
      formatter: (cell: any, row: any) => {
        return (
          <span className={`${["red", "yellow", "green"][cell]}-pill pill`}>
            {["Pendente", "Parcial", "Concluído"][cell]}
          </span>
        );
      },
    },
  ];

  const columnsProducts: any[] = [
    {
      sort: true,
      text: "Modelo",
      formatter: (cell: any, row: any) => (row.name ? row.name : ""),
    },
    {
      sort: true,
      text: "Marca",
      formatter: (cell: any, row: any) => (row.brand ? row.brand.name : ""),
    },
    {
      sort: true,
      text: "Capacidade",
      formatter: (cell: any, row: any) =>
        `${row.info ? row.info : ""} ${
          row.unitOfMeasurement ? row.unitOfMeasurement.name : ""
        }`,
    },
    {
      sort: true,
      dataField: "voltage.label",
      text: "Voltagem",
    },
    {
      sort: true,
      text: "Quantidade",
      formatter: (cell: any, row: any) => row.quantity,
    },
    {
      sort: true,
      text: "Preço Médio",
      formatter: (cell: any, row: any) => money(row.middlePrice / row.quantity),
    },
  ];

  return (
    <div className="animated">
      {data && (
        <Fragment>
          <div className="animated">
            <Row>
              <Col className="text-left">
                <label>Romaneio nº</label>
                <p>{data.number}</p>
              </Col>
              <Col className="text-left">
                <label>Motorista</label>
                <p>{data.driver}</p>
              </Col>
              <Col className="text-left">
                <label>Criado em</label>
                <p>
                  {moment(data.createdAt.seconds * 1000).format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </p>
              </Col>
              <Col className="text-left">
                <label>Situação da entrega</label>
                <p>
                  <span
                    className={`${
                      ["red", "yellow", "green"][data.status]
                    }-pill pill`}
                  >
                    {["Pendente", "Parcial", "Concluído"][data.status]}
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              {isLoading && <CenteredSpinner />}
              {!isLoading && products.length > 0 && (
                <>
                  <BootstrapTable
                    keyField="name"
                    data={products}
                    columns={columnsProducts}
                    bordered={false}
                    hover
                  />
                </>
              )}
            </Row>
            {!isLoading && products.length === 0 && (
              <Card>
                <CardHeader>Sem Itens no Romaneio</CardHeader>
              </Card>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PackingListDetails;
