import { useState } from "react";
import firebase from "config/firebase";
import { notify } from "services";

const useGetDelivery = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingDelivery, setLoadingDelivery] = useState(false);

  const getDelivery = async (id: string) => {
    setLoadingDelivery(true);

    const response = await db
      .collection("deliveries")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.data();
      })
      .catch((error) => {
        setLoadingDelivery(false);
        console.log("erro: ", error);
        notify({
          description:
            "Não foi possivel acessar a entrega. Por favor, tente novamente.",
          type: "danger",
        });
      });

    return response;
  };

  return { loadingDelivery, getDelivery };
};

export default useGetDelivery;
