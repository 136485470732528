import React, { useEffect, useState, Fragment } from "react";
import { Button, Col, Row } from "reactstrap";
import {
  useGetCompanies,
  useGetGovernmentAgencies,
  useGetUnitiesOfMeasurement,
  useGetBrands,
  useGetProductFamily
} from "hooks";
import { formatOptions } from "utils/general";
import { CustomReactSelect, ComponentModal } from "components";
import BrandDetails from "../../Brands/BrandDetails";
import { UnitOfMeasurementDetails } from "../../";
import dispatch, { EditActions } from "actions";
import CubageForm from "./CubageForm";

const ProductForm = (props: any) => {
  const [
    unitiesOfMeasurementModalIsOpen,
    setUnitiesOfMeasurementModalIsOpen,
  ] = useState<boolean>(false);
  const [unitiesOfMeasurementCount, setUnitiesOfMeasurementCount] = useState(0);
  const {
    loadingUnitiesOfMeasurement,
    unitiesOfMeasurement,
  } = useGetUnitiesOfMeasurement(unitiesOfMeasurementCount);

  const {loadingProductFamily, productFamily} = useGetProductFamily()

  const [brandModalIsOpen, setBrandModalIsOpen] = useState<boolean>(false);
  const [brandCount, setBrandCount] = useState(0);
  const { loadingBrands, brands } = useGetBrands(brandCount);

  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = props;

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading && !loadingUnitiesOfMeasurement && !loadingBrands)
      setLoading(false);
    if (!isLoading) dispatch(EditActions.setWarning, { warning: true });
  }, [values]);

  const closeUnitiesOfMeasurementModal = () => {
    setUnitiesOfMeasurementCount(unitiesOfMeasurementCount + 1);
    setUnitiesOfMeasurementModalIsOpen(false);
  };

  const closeBrandModal = () => {
    setBrandCount(brandCount + 1);
    setBrandModalIsOpen(false);
  };

  return (
    <Fragment>
      <ComponentModal
        name="Adicionar marca"
        component={brandModalIsOpen}
        closeModal={closeBrandModal}
      >
        <BrandDetails isModal={true} closeModal={closeBrandModal} />
      </ComponentModal>

      <ComponentModal
        name="Adicionar unidade de medida"
        component={unitiesOfMeasurementModalIsOpen}
        closeModal={closeUnitiesOfMeasurementModal}
      >
        <UnitOfMeasurementDetails
          isModal={true}
          closeModal={closeUnitiesOfMeasurementModal}
        />
      </ComponentModal>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col md="6" className="mb-3">
            <label htmlFor="name" style={{ display: "block" }}>
              Nome
            </label>
            <input
              id="name"
              placeholder="Nome"
              type="text"
              value={values.name}
              onChange={(evt) => {
                handleChange(evt);
              }}
              onBlur={handleBlur}
              autoFocus
            />
            {errors.name && touched.name && (
              <div className="error-div">{errors.name}</div>
            )}
          </Col>
          <Col md="6" className="mb-3">
            <CustomReactSelect
              label="Marca"
              field="brand"
              value={values.brand}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.brand}
              touched={touched.brand}
              options={formatOptions(brands)}
              onAdd={() => {
                setBrandModalIsOpen(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-3">
            <label htmlFor="info" style={{ display: "block" }}>
              Potência / Tamanho / Capacidade
            </label>
            <input
              id="info"
              placeholder="Potência"
              type="text"
              value={values.info}
              onChange={(evt) => {
                handleChange(evt);
              }}
              onBlur={handleBlur}
            />
            {errors.info && touched.info && (
              <div className="error-div">{errors.info}</div>
            )}
          </Col>
          <Col md="6" className="mb-3">
            <CustomReactSelect
              label="Unidade de medida"
              field="unitOfMeasurement"
              value={values.unitOfMeasurement}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.unitOfMeasurement}
              touched={touched.unitOfMeasurement}
              options={formatOptions(unitiesOfMeasurement)}
              onAdd={() => {
                setUnitiesOfMeasurementModalIsOpen(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
          <CustomReactSelect
              label="Família"
              field="productFamily"
              value={values.productFamily}
              onChange={(field: any, newValue: any) => {
                setFieldValue(field, newValue);
              }}
              onBlur={setFieldTouched}
              error={errors.productFamily}
              touched={touched.productFamily}
              options={formatOptions(productFamily)}
            />
          </Col>
           </Row>
           <CubageForm
          values={values}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
        <Row>
          <Col className="text-right">
            <Button type="submit" color="success" disabled={isSubmitting}>
              Salvar
            </Button>
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

export default ProductForm;
