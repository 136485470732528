import firebase from "config/firebase";

const getPawns = (startDate?: any, endDate?: any) => {
  const db = firebase.firestore();

  if (startDate && endDate) {
    let start = new Date(startDate), end = new Date(endDate)
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    return db.collection("pawns")
      .where("createdAt", ">=", start)
      .where("createdAt", "<=", end)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data())
      })
      .catch((error) => {
        return {}
      });
  } else {
    return db.collection("pawns")
      .orderBy("number")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data())
      })
      .catch((error) => {
        return {}
      });
  }
};

export default getPawns