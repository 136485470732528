import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetProducts } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DeleteModal, CenteredSpinner } from "components";
import { useSelector } from "react-redux";
import { notify } from "services";

const ProductList = ({ history }) => {
  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);
  const { loadingProducts, products } = useGetProducts(count);
  const userRole = useSelector((state) =>
    state.user.permissionProduct ? state.user.permissionProduct.value : false
  );

  const columns = [
    {
      sort: true,
      dataField: "name",
      text: "Nome",
    },
    {
      sort: true,
      dataField: "brand.name",
      text: "Marca",
    },
    {
      sort: false,
      dataField: "info",
      text: "Potência/Tamanho/Capacidade",
    },
    {
      sort: true,
      dataField: "unitOfMeasurement.name",
      text: "Unidade de Medida",
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-note action-button"
              onClick={() => {
                if (userRole)
                  history.push({
                    pathname: "./produtos/detalhes",
                    state: { row },
                  });
                else {
                  notify({
                    description:
                      "Você não tem permissão para criar ou editar um produto, contate o administrador.",
                    type: "danger",
                  });
                }
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const searchBarRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus();
  }, []);

  return (
    <>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        path="products"
        type="product"
        modalItem={modalItem}
        refreshList={() => setCount(count + 1)}
      />
      <div className="animated">
        <Card>
          <CardHeader>Produtos</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button
                            block
                            color="success"
                            onClick={() => {
                              if (userRole) history.push("./produtos/detalhes");
                              else {
                                notify({
                                  description:
                                    "Você não tem permissão para criar ou editar um produto, contate o administrador.",
                                  type: "danger",
                                });
                              }
                            }}
                          >
                            Novo produto
                          </Button>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {loadingProducts ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ProductList;
