export enum ActionType {
  delete = 0,
  deliver = 1,
}

interface UpdateProps {
  packingList: any;
  packingListSelectedId: number;
  type: "deliver" | "delete";
  deliveryId: string;
}

const updatePackingListData = ({
  packingList,
  packingListSelectedId,
  type,
  deliveryId,
}: UpdateProps) => {
  let packingListStatus = 2; //[pendente, parcial, fechado]
  let packingListArray = [...packingList.packingListArray];
  let selectedPackingListItemIndex = packingListArray.findIndex(
    (item: any, index: any) => item.id == packingListSelectedId
  );

  if (type == "deliver") {
    packingListArray[selectedPackingListItemIndex].status = 1; // entregue
    packingListArray[selectedPackingListItemIndex].deliveryId = deliveryId;
  }
  if (type == "delete") {
    packingListArray[selectedPackingListItemIndex].status = 0; // aberto
    packingListArray[selectedPackingListItemIndex].deliveryId = ""; // aberto
  }

  let packingListDeliveryStatusScore = 0;
  packingListArray.map((item) => {
    packingListDeliveryStatusScore += item.status ? item.status : 0;
  });

  if (packingListDeliveryStatusScore < packingListArray.length)
    packingListStatus = 1;

  if (packingListDeliveryStatusScore == 0) packingListStatus = 0;

  return { packingListStatus, packingListArray };
};

export default updatePackingListData;
