import React, { useEffect } from "react";
import { Table } from "reactstrap";
import { money } from "utils/mask";
import idx from "idx";
import "./style.css";
import dispatch, { ProductActions } from "actions";

const PLDeliveryItems = ({
  pawn,
  items,
  relatedProducts,
  values,
  setTotal,
  objectTotal,
}) => {
  const formatItems = () => {
    let formattedItems = [];
    if (items) {
      for (let [key, value] of Object.entries(items)) {
        if (pawn.pawnItemsQuantity[value.id] > 0) formattedItems.push(value);
      }
    }
    return formattedItems;
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      rows.push(
        <tr>
          <td title="Modelo">
            {idx(relatedProducts, (_) => _[row?.product?.id].name)}
          </td>
          <td title="Marca">
            {idx(relatedProducts, (_) => _[row?.product?.id].brand?.name)}
          </td>
          <td title="Capacidade">{`${idx(
            relatedProducts,
            (_) => _[row?.product?.id].info
          )} ${idx(
            relatedProducts,
            (_) => _[row?.product?.id].unitOfMeasurement?.name
          )}`}</td>
          <td title="Instalado?">{row?.installed?.label}</td>
          <td title="Voltagem">{row?.voltage?.label}</td>
          <td title="Valor unitário">{money(row?.unitPrice)}</td>
          <td title="Quantidade">
            {values?.packingListItemsQuantity[row?.id]}
          </td>
        </tr>
      );
    });
    return rows;
  };

  const productRows = () => {
    let totalWeight = 0;
    let totalCubedWeight = 0;
    let totalVolume = 0;
    let totalCubage = 0;
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      const itemsQuantity = values?.packingListItemsQuantity[row?.id];
      idx(relatedProducts, (_) => {
        totalWeight += _[row?.product?.id].total.totalWeight * itemsQuantity;
        totalCubedWeight +=
          _[row?.product?.id].total.totalCubedWeight * itemsQuantity;
        totalVolume += _[row?.product?.id].total.totalVolume * itemsQuantity;
        totalCubage += _[row?.product?.id].total.totalCubed * itemsQuantity;
      });
    });

    objectTotal.totalWeight += totalWeight;
    objectTotal.totalVolume += totalVolume;
    objectTotal.totalCubage += totalCubage;
    objectTotal.totalCubedWeight += totalCubedWeight;

    rows.push(
      <tr>
        <td title="Volumes">{totalVolume}</td>
        <td title="Peso Total">{totalWeight}</td>
        <td title="Cubagem Total">{totalCubage}</td>
        <td title="Peso Cubado">{totalCubedWeight}</td>
      </tr>
    );
    return rows;
  };

  useEffect(() => {
    setTotal(objectTotal);
  }, [relatedProducts, values?.packingListItemsQuantity]);

  return (
    <>
      <Table className="small-text my-0">
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Capacidade</th>
            <th>Instalado?</th>
            <th>Voltagem</th>
            <th>Valor unitário</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
      <Table className="small-text mt-4">
        <thead>
          <tr>
            <th>Volumes</th>
            <th>Peso Total</th>
            <th>Cubagem Total</th>
            <th>Peso Cubado</th>
          </tr>
        </thead>
        <tbody>{productRows()}</tbody>
      </Table>
    </>
  );
};

export default PLDeliveryItems;
