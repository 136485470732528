import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import PLDeliveryItems from "./PLDeliveryItems";
import moment from "moment";
import idx from "idx";
import "./style.css";
import { useSelector } from "react-redux";
import dispatch, { EditButtonActions } from 'actions';

const PLDeliveryCardDisplay = ({
  setTotal,
  pawn,
  packingList,
  relatedProducts,
  editPackingListModal,
  deletePackingList,
  pawnError,
  position,
  objectTotal,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const singleItem = (size: number, label: string, value: any) => {
    return (
      <Col md={size}>
        <label className="small-text mb-0" style={{ display: "block" }}>
          {label}
        </label>
        <span className="small-text">{value ? value : "..."}</span>
      </Col>
    );
  };

  const chevDown = <i className="fa fa-chevron-down" aria-hidden="true"></i>;
  const chevUp = <i className="fa fa-chevron-up" aria-hidden="true"></i>;

  return (
    <Card className="d-print-none ">
      <CardHeader className="small-header-padding-pl d-flex d-print-none">
        <span className="align-self-center mr-1232320">
          <div className="mr-auto">{position + 1}</div>
        </span>
        {!pawnError && (
          <Button
            onClick={toggle}
            className="card-header-button btn-ghost-dark"
          >
            {isOpen ? chevUp : chevDown}
          </Button>
        )}
        <span className="ml-auto mr-0">
          {!pawnError && (
            <Button
              color="info"
              onClick={() => {
                editPackingListModal();
                dispatch(EditButtonActions.setEditbutton, { isEdit: true })
              }}
              className="card-header-buttons"
            >
              Editar
            </Button>
          )}
          <Button
            color="danger"
            onClick={deletePackingList}
            className="card-header-buttons"
          >
            Excluir
          </Button>
        </span>
      </CardHeader>

      <CardBody>
        {pawnError && (
          <Row>
            <Col className="text-center text-danger">
              Erro ao baixar empenho. O empenho provavelmente foi excluído.
            </Col>
          </Row>
        )}
        {pawn && (
          <Fragment>
            <Row>
              {singleItem(
                9,
                "Empenho | Número | Pregão | Órgão | Empresa",
                `Empenho: ${pawn.number}${pawn.customPawnNumber
                  ? ` | Número:  ${pawn.customPawnNumber}`
                  : ""
                } | Pregão: ${pawn.auction.number} | Órgão: ${pawn.auction.governmentAgency.name
                } | Empresa: ${pawn.auction.company.name}`
              )}
              {singleItem(
                3,
                "Cidade",
                `${packingList.city}, ${packingList.state.value}`
              )}
            </Row>
            <Collapse isOpen={isOpen}>
              <Row>
                {singleItem(9, "Local de entrega", packingList.address)}
                {singleItem(
                  3,
                  "Data da entrega:",
                  `${packingList.deliveryDate !== "00/00/00"
                    ? moment(packingList.deliveryDate).format("DD/MM/YYYY")
                    : "Indefinido"
                  }`
                )}
              </Row>
              <Row>
                {singleItem(
                  6,
                  "Observações / Instruções de entrega",
                  packingList.observations
                )}
              </Row>
              <Row>
                <PLDeliveryItems
                  setTotal={setTotal}
                  objectTotal={objectTotal}
                  pawn={pawn}
                  items={relatedProducts && pawn?.auction?.items}
                  relatedProducts={relatedProducts}
                  values={packingList}
                />
              </Row>
            </Collapse>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default PLDeliveryCardDisplay;
