import { createReducer } from "typesafe-actions";
import { ModalActions } from "actions";

const {
    saveModalEvent,
} = ModalActions;

const initialState = {
    modalOpen: false,
};

const reducer = createReducer(initialState)

.handleAction(saveModalEvent, (state, { payload }) => ({
    ...state,
    modalOpen: payload.modalOpen,
}))


export default reducer;