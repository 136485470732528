import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetUnitiesOfMeasurement } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import dispatch, { UtilsActions } from "actions";
import { DeleteModal, CenteredSpinner } from "components";

const UnitOfMeasurementList = ({ history }) => {
  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);
  const {
    loadingUnitiesOfMeasurement,
    unitiesOfMeasurement,
  } = useGetUnitiesOfMeasurement(count);

  const columns = [
    {
      sort: true,
      dataField: "name",
      text: "Nome",
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-note action-button"
              onClick={() => {
                history.push({
                  pathname: "./unidades-de-medida/detalhes",
                  state: { row },
                });
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const searchBarRef = useRef()
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus()
  }, [])

  const goToDetails = () => {
    let unitiesOfMeasurementNames = [];
    for (const key in unitiesOfMeasurement) {
      if (unitiesOfMeasurement.hasOwnProperty(key)) {
        const unitOfMeasurement = unitiesOfMeasurement[key];
        unitiesOfMeasurementNames.push(unitOfMeasurement.name);
      }
    }
    dispatch(UtilsActions.saveUnitiesOfMeasurement, unitiesOfMeasurementNames);
    history.push("./unidades-de-medida/detalhes");
  };

  return (
    <>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        path="unitiesOfMeasurement"
        type="unitOfMeasurement"
        modalItem={modalItem}
        refreshList={() => setCount(count + 1)}
      />
      <div className="animated">
        <Card>
          <CardHeader>Unidades de medida</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={unitiesOfMeasurement}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button block color="success" onClick={goToDetails}>
                            Nova unidade de medida
                          </Button>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {loadingUnitiesOfMeasurement ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default UnitOfMeasurementList;
