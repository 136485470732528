import { combineReducers } from 'redux'

import user from './user'
import location from './location'
import utils from './utils'
import filter from './filter'
import delivery from './delivery'
import dashboard from './dashboard'
import modal from './modal'
import edit from './edit'
import editbutton from './editbutton';

export const reducers = combineReducers({
  user,
  location,
  utils,
  filter,
  delivery,
  dashboard,
  edit,
  modal,
  editbutton,
})
