import React, { useRef, Fragment } from "react";
import { Col } from "reactstrap";
import moment from "moment";

interface InputAndLabelProps {
  col: number;
  name: string;
  label: string;
  type: "text" | "email" | "password" | "date" | "number";
  placeHolder?: string;
  value: string | number;
  onChange?: (evt: any) => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  isQuickView?: boolean;
}

const InputAndLabel: React.FC<InputAndLabelProps> = ({
  col,
  name,
  label,
  type,
  placeHolder,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  isQuickView,
}) => {
  return (
    <Fragment>
      <Col md={col} className="mb-3">
        <label htmlFor={name} style={{ display: "block" }}>
          {label}
        </label>
        {isQuickView ? (
          <span>
            {type == "date" ? moment(value).format("DD/MM/YYYY") : value}
          </span>
        ) : (
          <Fragment>
            <input
              id={name}
              placeholder={placeHolder}
              type={type}
              min={0}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
            {error && <div className="error-div">{error}</div>}
          </Fragment>
        )}
      </Col>
    </Fragment>
  );
};

export default InputAndLabel;
