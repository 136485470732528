import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { useGetProductFamily } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import dispatch, { UtilsActions } from "actions";
import { DeleteModal, CenteredSpinner } from "components";
import ProductFamilyPrint from "./ProductFamilyPrint";
import { money } from "utils/mask";
import "./styles.css";

const ProductFamilyList = ({ history }) => {
  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);
  const { loadingProductFamily, productFamily } = useGetProductFamily(count);

  const columns = [
    {
      sort: true,
      dataField: "name",
      text: "Nome da família",
    },
    {
      sort: true,
      dataField: "price",
      text: "Preço",
      formatter: (cell, row) => money(cell),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-note action-button"
              onClick={() => {
                history.push({
                  pathname: "./familias/detalhes",
                  state: { row },
                });
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const tableRows = () => {
    let rows = [];
    productFamily.forEach((row) => {
      rows.push(
        <tr>
          <td title="Nome">{row.name}</td>
          <td title="Preço">{money(row.price)}</td>
        </tr>
      );
    });
    return rows;
  };

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const searchBarRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus();
  }, []);

  const goToDetails = () => {
    let productFamilyArray = [];
    for (const key in productFamily) {
      if (productFamily.hasOwnProperty(key)) {
        const family = productFamily[key];
        productFamilyArray.push(family.name.toLowerCase());
      }
    }
    dispatch(UtilsActions.saveProductFamily, productFamilyArray);
    history.push("./familias/detalhes");
  };

  return (
    <>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        path="productFamily"
        type="family"
        modalItem={modalItem}
        refreshList={() => setCount(count + 1)}
      />
      <div className="animated d-print-none">
        <Card>
          <CardHeader>Famílias</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={productFamily}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button block color="success" onClick={goToDetails}>
                            Nova família
                          </Button>
                          <Button
                            block
                            color="primary"
                            onClick={() => window.print()}
                          >
                            Imprimir lista
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        {loadingProductFamily ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div style={{ pageBreakInside: "avoid" }}>
        <Row className="mb-2 d-none d-print-block print-text">
          <h1>Lista de famílias</h1>
          <Table className="small-text my-0">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody>{tableRows()}</tbody>
          </Table>
        </Row>
      </div>
    </>
  );
};

export default ProductFamilyList;
