import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import DeliveryForm from "./DeliveryForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import firebase from "config/firebase";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import dispatch, { EditActions } from "actions";

const DeliveryDetails = ({ quickViewDelivery }) => {
  const history = useHistory();
  const location = useLocation();

  const isQuickView = !!quickViewDelivery;

  const currentDelivery = isQuickView
    ? quickViewDelivery
    : location?.state?.row;
  const id = currentDelivery?.id;
  const isEdit = !!id;
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      pawn: Yup.string().required("Selecione um empenho"),
      nfe: Yup.string().required("Preencha o número de série/NFe"),
      deliveryDate: Yup.string().required("Selecione a data da entrega"),
      deliveredTo: Yup.string().required("Preencha o nome do receptor"),
    }),
    validate: (values) => {
      const errors = {};
      if (values.deliveryItemsQuantity) {
        let totalDelivered = 0;
        for (const key in values.deliveryItemsQuantity) {
          let previousDeliveryItemQuantity =
            values.previousDeliveryItems[key] || 0;
          const deliveryItemQuantity = values.deliveryItemsQuantity[key];
          totalDelivered += deliveryItemQuantity;
          if (isNaN(deliveryItemQuantity))
            errors.deliveryItemsQuantity = "Item sem quantidade";
          if (
            deliveryItemQuantity + previousDeliveryItemQuantity >
            values.pawn.data.pawnItemsQuantity[key]
          ) {
            errors.deliveryItemsQuantity =
              "Quantidade de itens supera a quantidade determinada no empenho";
          }
        }
        if (totalDelivered === 0) {
          errors.deliveryItemsQuantity = "Nenhum item a ser entregue";
        }
      }
      return errors;
    },
    handleSubmit: (values, { setSubmitting }) => {
      const newDelivery = {
        pawn: values.pawn.data,
        deliveryItemsQuantity: values.deliveryItemsQuantity,
        nfe: values.nfe,
        deliveryDate: values.deliveryDate,
        deliveredTo: values.deliveredTo,
        number: values.number,
      };
      const docId = isEdit ? id : db.collection("deliveries").doc().id;
      newDelivery["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newDelivery["createdAt"] = values["createdAt"];
        newDelivery["updatedAt"] = serverTimestamp;
      } else {
        newDelivery["createdAt"] = serverTimestamp;
      }

      const counterRef = db.collection("generalSettings").doc("counters");
      const docRef = db.collection("deliveries").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: newDelivery["number"],
        collection: "deliveries",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let action;
      if (isEdit) {
        let batch = db.batch();
        batch.update(docRef, newDelivery);
        log["action"] = "update";
        batch.set(logRef, log);
        action = batch.commit();
      } else {
        action = db.runTransaction(function(transaction) {
          return transaction.get(counterRef).then(function(countersDoc) {
            const newDeliveryCount = countersDoc.data().deliveries + 1;
            newDelivery["number"] = newDeliveryCount;
            log["reference"] = newDeliveryCount;
            transaction.set(docRef, newDelivery);
            transaction.update(counterRef, { deliveries: newDeliveryCount });
            transaction.set(logRef, log);
          });
        });
      }

      action
        .then(() => {
          setSubmitting(false);
          history.goBack();
        })
        .catch(function(error) {
          alert("Erro ao salvar entrega");
          console.error("Erro ao salvar entrega: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "DeliveryForm",
    mapPropsToValues: () => {
      let data = {
        id: "",
        deliveryItemsQuantity: "",
        nfe: "",
        deliveryDate: "",
        deliveredTo: "",
        createdAt: "",
        number: "",
        pawn: "",
      };
      if (isEdit) {
        data = {
          id: currentDelivery.id,
          deliveryItemsQuantity: currentDelivery.deliveryItemsQuantity,
          nfe: currentDelivery.nfe,
          deliveryDate: currentDelivery.deliveryDate,
          deliveredTo: currentDelivery.deliveredTo,
          createdAt: currentDelivery.createdAt,
          number: currentDelivery.number,
          pawn: {
            data: currentDelivery.pawn,
            label:
              currentDelivery.pawn.number &&
              `Empenho: ${currentDelivery.pawn.number}${
                currentDelivery.pawn.customPawnNumber
                  ? ` | Número:  ${currentDelivery.pawn.customPawnNumber}`
                  : ""
              } | Pregão: ${currentDelivery.pawn.auction.number} | Órgão: ${
                currentDelivery.pawn.auction.governmentAgency.name
              } | Empresa: ${currentDelivery.pawn.auction.company.name}`,
          },
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(DeliveryForm);

  return (
    <div className="animated">
      {isQuickView ? (
        <MyEnhancedForm isEdit={isEdit} isQuickView={isQuickView} />
      ) : (
        <Card>
          <CardHeader>
            {`Entrega ${isEdit ? "#" + currentDelivery.number : ""}`}
          </CardHeader>
          <CardBody>
            <MyEnhancedForm isEdit={isEdit} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default DeliveryDetails;
