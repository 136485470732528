import React, { useState, useEffect } from "react";
import {
  adminPaths,
  sellerPaths,
  pledgerPaths,
  delivererPaths,
  financialPaths,
} from "../../../_nav";
import "./CustomSidebar.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { LeftModal } from "components";
import dispatch, { EditActions, DeliveryActions, FilterActions } from "actions";

const CustomSidebar = () => {
  const history = useHistory();
  const userRole = useSelector<any>((state: any) => state.user.role);
  const [userNav, setUserNav] = useState<any>([]);
  
  const [requestConfirm, setRequestConfirm] = useState(false)
  const [path, setPath] = useState("")
  const warning = useSelector((state: any) => state.edit.warning);
  
  useEffect(() => {
    getUserPath();
  }, []);

  const getUserPath = () => {
    switch (userRole) {
      case "admin":
        setUserNav(adminPaths);
        break;
      case "seller":
        setUserNav(sellerPaths);
        break;
      case "pledger":
        setUserNav(pledgerPaths);
        break;
      case "deliverer":
        setUserNav(delivererPaths);
        break;

      case "financial":
        setUserNav(financialPaths);
        break;

      case "collection":
        setUserNav(financialPaths);
        break;

      default:
        break;
    }
  };

  const navItem = (item: any) => {
    const active = history.location.pathname === item.url ? "active" : "";

    return (
      <li className="nav-item">
        <a
          aria-current="page"
          className={`nav-link ${active}`}
          onClick={() => {
            if (warning) {
              setRequestConfirm(true)
              setPath(item.url)
            } else {
              // para não manter uma entrega selecionada ao navegar entre opções do menu
              dispatch(DeliveryActions.savePackingList, undefined)
              
              // para não manter os filtros avançados dos empenhos ao navegar entre opções do menu
              dispatch(FilterActions.savePawnListFilters, {})
              dispatch(FilterActions.savePawnListReceivedAtDates, {})

              // navegar para endereço
              history.push(item.url)
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="nav-link-container">
            <img className={`custom-icon ${active}`} src={item.customIcon} />
            {item.name}
          </div>
        </a>
      </li>
    );
  };

  const navItems = () => {
    return userNav.map((item: any) => navItem(item));
  };

  return (
    <div>
      <ul className="nav">{navItems()}</ul>
      {requestConfirm &&
        <LeftModal
          handleConfirm={() => {
            history.push(path)
            dispatch(EditActions.setWarning, { warning: false });
            setRequestConfirm(false)
          }}
          handleClose={() => {
            setRequestConfirm(false)
          }}
          isOpen={requestConfirm}
        />
      }
    </div>
  );
};

export default CustomSidebar;
