const yesAndNo = [
    {
        value: true,
        label: "Sim"
    },
    {
        value: false,
        label: "Não"
    }
];

export default yesAndNo