import React, { useState, Fragment, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import dispatch, { ModalActions } from "actions";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { DeleteModal, CenteredSpinner, QuickViewModal } from "components";
import { useHistory } from "react-router-dom";
import PawnDetails from "../PawnDetails";
import firebase from "config/firebase";

const PawnListTable = (props) => {
  const { loadingPawns, pawns, addCount, children, isRestricted } = props;
  const history = useHistory();
  const { SearchBar } = Search;
  const [deleteItem, setDeleteItem] = useState(undefined);
  const [quickViewItem, setQuickViewItem] = useState(undefined);

  // const [isChecked, setChecked] = useState(true);

  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Pedido",
    },
    {
      sort: true,
      dataField: "customPawnNumber",
      text: "Número",
    },
    {
      sort: true,
      dataField: "auction.company.name",
      text: "Empresa",
    },
    {
      sort: true,
      dataField: "auction.number",
      text: "Pregão",
    },
    {
      sort: true,
      dataField: "auction.governmentAgency.name",
      text: "Órgão",
    },
    {
      sort: true,
      dataField: "auction.governmentAgency.city",
      text: "Cidade",
      formatter: (cell, row) => {
        return `${cell}, ${row.auction.governmentAgency.state.value}`;
      },
    },
    {
      sort: true,
      text: "Recebido em",
      formatter: (cell, row) => {
        return moment(row.receivedAt).format("DD/MM/YYYY");
      },
    },
    {
      sort: true,
      text: "Prazo",
      formatter: (cell, row) => {
        return moment(row.receivedAt)
          .add(row.deliveryDays, "days")
          .format("DD/MM/YYYY");
      },
    },
    {
      sort: true,
      dataField: "deliveryStatus",
      text: "Situação da entrega",
      formatter: (cell, row) => {
        return (
          <span
            className={`${
              ["red", "yellow", "green", "orange"][cell]
            }-pill pill`}
          >
            {["Pendente", "Parcial", "Concluído", "Em trânsito"][cell]}
          </span>
        );
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setQuickViewItem(row);
                dispatch(ModalActions.saveModalEvent, { modalOpen: true });
              }}
            />
            {!isRestricted && (
              <Fragment>
                <i
                  className="icon-note ml-2 action-button"
                  onClick={() => {
                    dispatch(ModalActions.saveModalEvent, { modalOpen: false });
                    history.push({
                      pathname: "./empenhos/detalhes",
                      state: { row },
                    });
                  }}
                />
                <i
                  className="icon-trash ml-2 action-button"
                  onClick={() => {
                    setDeleteItem(row);
                  }}
                />
              </Fragment>
            )}
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "number",
      order: "desc",
    },
  ];

  const searchBarRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus();
  }, []);

  return (
    <Fragment>
      <DeleteModal
        closeModal={() => setDeleteItem(undefined)}
        path="pawns"
        type="pawn"
        modalItem={deleteItem}
        refreshList={() => addCount()}
      />
      <QuickViewModal
        closeModal={() =>
          setQuickViewItem(undefined) &&
          dispatch(ModalActions.saveModalEvent, { modalOpen: false })
        }
        isOpen={quickViewItem}
        title={`Empenho #${quickViewItem?.number}`}
      >
        <PawnDetails quickViewPawn={quickViewItem} />
      </QuickViewModal>

      <ToolkitProvider keyField="id" data={pawns} columns={columns} search>
        {(props) => (
          <>
            <Row>
              <Col>
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar na tabela"
                  style={{ width: "100%" }}
                  ref={searchBarRef}
                />
              </Col>
              {!isRestricted && (
                <Col sm={3} className="mb-3">
                  <Button
                    block
                    color="success"
                    onClick={() => {
                      history.push("./empenhos/detalhes");
                      dispatch(ModalActions.saveModalEvent, {
                        modalOpen: false,
                      });
                    }}
                  >
                    Novo Empenho
                  </Button>
                </Col>
              )}
            </Row>

            {children}

            <Row className="mt-4">
              {loadingPawns ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    hover
                    pagination={pagination}
                    defaultSorted={defaultSorted}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </ToolkitProvider>
    </Fragment>
  );
};

export default PawnListTable;
