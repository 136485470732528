import * as UserActions from "./user";
import * as LocationActions from "./location";
import * as UtilsActions from "./utils";
import * as FilterActions from "./filter";
import * as DeliveryActions from "./delivery";
import * as DashboardActions from "./dashboard";
import * as ModalActions from "./modal"
import * as EditActions from "./edit";
import * as EditButtonActions from "./editbutton";

import { getType } from "typesafe-actions";
import store from "store";

const dispatchAction = (type: any, payload?: any) => {
  return store.dispatch({ type: getType(type), payload });
};

export {
  UserActions,
  LocationActions,
  UtilsActions,
  FilterActions,
  DeliveryActions,
  DashboardActions,
  ModalActions,
  EditActions,
  EditButtonActions,
  dispatchAction as default,
};
