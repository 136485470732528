import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetPawns = (
  count?: any,
  startDate?: any,
  endDate?: any,
  isRange?: boolean,
  status?: number,
  dropdown?: boolean
) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [pawns, setPawns] = useState([] as any);
  const [loadingPawns, setLoadingPawns] = useState(false);


  useEffect(() => {
    if (!isRange) {
      setLoadingPawns(true);
      db
        .collection("pawns")
        .where("deliveryStatus", "in", !!status ? status === 4 ? [0, 1, 2, 3] : [status] : [0, 1])
        .orderBy("number", "desc")
        .get()
        .then((querySnapshot) => {
          setPawns(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingPawns(false);
        })
        .catch((error) => {
          setLoadingPawns(false);
          console.log("erro: ", error);
        });
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [count, status]);

  useEffect(() => {
    if (dropdown) {
      setLoadingPawns(true);
      db
        .collection("pawns")
        .where("deliveryStatus", "in", [0, 1, 3])
        .orderBy("number", "desc")
        .get()
        .then((querySnapshot) => {
          setPawns(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingPawns(false);
        })
        .catch((error) => {
          setLoadingPawns(false);
          console.log("erro: ", error);
        });
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [count, dropdown]);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      setLoadingPawns(true);
      db.collection("pawns")
        .where("receivedAt", ">=", startDate.format("YYYY-MM-DD"))
        .where("receivedAt", "<=", endDate.format("YYYY-MM-DD"))
        .get()
        .then((querySnapshot) => {
          setPawns(querySnapshot.docs.map((doc) => doc.data()));
          setLoadingPawns(false);
        })
        .catch((error) => {
          setLoadingPawns(false);
          console.log("erro: ", error);
        });
    }
  }, [startDate, endDate]);

  return { loadingPawns, pawns };
};

export default useGetPawns;
