import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PrintPLDeliveryItems from "./PrintPLDeliveryItems";
import "./style.css";

const PLDeliveryCardPrint = ({
  typeOfButtonPrint,
  pawn,
  packingList,
  relatedProducts,
  isLast,
}: any) => {
  const [titleIsVisible, setTitleIsVisible] = useState(false);
  const [observationIsVisible, setObservationIsVisible] = useState(false);

  useEffect(() => {
    const city = sessionStorage.getItem("@ecoblu/city");
    const observation = sessionStorage.getItem("@ecoblu/observation");
    const observationCity = sessionStorage.getItem("@ecoblu/observation-city");

    if (observationCity !== packingList.city && observationCity !== null) {
      setTitleIsVisible(true);
      sessionStorage.setItem("@ecoblu/city", observationCity);
    } else if (
      packingList.city !== city &&
      observationCity !== packingList.city
    ) {
      setTitleIsVisible(true);
      sessionStorage.setItem("@ecoblu/city", packingList.city);
    }

    if (packingList.observations !== observation) {
      setObservationIsVisible(true);
      sessionStorage.setItem("@ecoblu/observation", packingList.observations);
    }
  }, []);

  return (
    <Fragment>
      {pawn && (
        <div style={{ pageBreakInside: "avoid" }}>
          <Row className="mb-2 d-none d-print-block print-text">
            <Col>
              {titleIsVisible && (
                <h3>
                  {packingList.city}, {packingList.state.value}
                </h3>
              )}
              <br />
              <b>Endereço:</b> {packingList.address}
              <br />
              {observationIsVisible && <p>"Obs:" {packingList.observations}</p>}
            </Col>
          </Row>
          <PrintPLDeliveryItems
            pawn={pawn}
            items={relatedProducts && pawn?.auction?.items}
            relatedProducts={relatedProducts}
            values={packingList}
          />
          {!isLast && <hr className="my-4 d-none d-print-block" />}
        </div>
      )}
    </Fragment>
  );
};

export default PLDeliveryCardPrint;
