import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetAuctions } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DeleteModal, CenteredSpinner, TogleSwitch } from "components";
import { money } from "utils/mask";
import { Object } from "core-js";
import moment from "moment";
import firebase from "config/firebase";

const AuctionList = ({ history }) => {
  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);
  const { loadingAuctions, auctions } = useGetAuctions(count);
  const switchOptions = ["Pendente/Parcial", "Concluído", "Todos", "Vencido"];
  const [optionSelected, setSelected] = useState(switchOptions[0]);

  useEffect(() => {
    if (auctions.length > 0) {
      setTotalValue();
    }
  }, [auctions]);

  const setTotalValue = () => {
    auctions.map((auction) => {
      const items = auction.items;
      let value_money = 0;
      Object.values(items).map((item) => {
        value_money += item.totalUnits * item.unitPrice;
      });

      Object.assign(auction, { totalMoneyValue: value_money });
    });
  };

  useEffect(() => {
    setAuctionDate();
  }, [auctions]);

  const setAuctionDate = () => {
    auctions.map((auction) => {
      if (auction.creationAuctionDate === undefined) {
        Object.assign(auction, {
          creationAuctionDate: moment(auction.createdAt.toDate()).format(
            "YYYY-MM-DD"
          ),
        });
      }
    });
  };

  const goToDetails = (row) => {
    let products = {};

    preLoadProducts(row.items).then((prodSnapshots) => {
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      history.push({
        pathname: "./pregoes/detalhes",
        state: { row, products },
      });
    });
  };

  const preLoadProducts = (itemsWithProducts) => {
    const promises = [];

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      promises.push(item.product.get());
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Número",
    },
    {
      sort: true,
      dataField: "governmentAgency.name",
      text: "Órgão",
    },
    {
      sort: true,
      dataField: "totalMoneyValue",
      text: "Valor total do pregão",
      headerAlign: "left",
      formatter: (cell, row, key) => {
        return (
          <p className="text-left" key={key}>
            {money(row.totalMoneyValue)}
          </p>
        );
      },
    },
    {
      sort: true,
      dataField: "creationAuctionDate",
      text: "Data pregão",
      headerAlign: "left",
      formatter: (cell, row, key) => {
        return (
          <p className="text-left" key={key}>
            {moment(row.creationAuctionDate).format("DD/MM/YYYY")}
          </p>
        );
      },
    },
    {
      sort: true,
      dataField: "statusAuction",
      text: "Status do pregão",
      formatter: (cell, row) => {
        return (
          <span className={`${["red", "yellow", "green", "purple"][cell]}-pill pill`}>
            {["Pendente", "Parcial", "Concluído", "Vencido"][cell]}
          </span>
        );
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row, key) => {
        return (
          <p className="text-right" key={key}>
            <i
              className="icon-note action-button"
              onClick={() => {
                goToDetails(row);
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const keyField = "id";

  const searchBarRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus();
  }, []);

  return (
    <>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        path="auctions"
        type="auction"
        modalItem={modalItem}
        refreshList={() => setCount(count + 1)}
      />
      <div className="animated">
        <Card>
          <CardHeader>Pregões</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="number"
                  data={auctions.filter((item) =>
                    optionSelected === switchOptions[0]
                      ? item.statusAuction === 0 || item.statusAuction === 1
                      : optionSelected === switchOptions[1]
                        ? item.statusAuction === 2
                        : optionSelected === switchOptions[3] ? item.statusAuction === 3
                          : item
                  )}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button
                            block
                            color="success"
                            onClick={() => {
                              history.push("./pregoes/detalhes");
                            }}
                          >
                            Novo Pregão
                          </Button>
                        </Col>
                        <TogleSwitch
                          values={switchOptions}
                          selected={optionSelected}
                          handleChange={(option) => setSelected(option)}
                        />
                      </Row>
                      <Row className="mt-4">
                        {loadingAuctions ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              keyField={keyField}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default AuctionList;
