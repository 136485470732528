import { PackingListDetails } from "screens";

interface FormatterInterface {
  (arr: any[], type?: string): any[];
}

const formatOptions: FormatterInterface = (arr, type?) => {
  let formatedArr = [];
  if (arr)
    for (let index = 0; index < arr.length; index++) {
      let item: any = arr[index];
      let value;

      let label;
      switch (type) {
        case "auction":
          value = label = `${item.number &&
            item.number} - ${item.governmentAgency &&
            item.governmentAgency.name}`;
          value = item.number && item.number;
          break;

        case "pawn":
          value = item.number && item.number;
          // empenho/pregão/órgão/empresa
          label =
            item.number &&
            `Empenho: ${item.number}${
              item.customPawnNumber
                ? ` | Número:  ${item.customPawnNumber}`
                : ""
            } | Pregão: ${item.auction.number} | Órgão: ${
              item.auction.governmentAgency.name
            } | Empresa: ${item.auction.company.name}`;
          break;

        case "packingList":
          value = item.id;
          let cities: any[] = [];
          item.packingListArray.map((packingList: any) => {
            if (
              cities.indexOf(
                `${packingList.city} - ${packingList.state.value}`
              ) == -1
            ) {
              cities.push(`${packingList.city} - ${packingList.state.value}`);
            }
          });

          label =
            item.number &&
            `Romaneio: ${item.number} | 
            Motorista: ${item.driver} | 
            Romaneios: ${item.packingListArray.length} | 
            Cidades: ${cities.join(", ")}`;
          break;

        case "cities":
          value = label = item;
          break;

        case "states":
          label = item.name && item.name;
          value = item.initials && item.initials;
          break;

        case "products":
          label = value =
            item.name &&
            `${item.brand.name} - ${item.name} ${item.info} ${item.unitOfMeasurement.name}`;
          break;

        default:
          value = label = item.name && item.name;
          break;
      }

      formatedArr.push({
        data: item,
        label,
        value,
      });
    }

  return formatedArr;
};

export default formatOptions;
