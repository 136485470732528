import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetAuctions } from "hooks";
import { CenteredSpinner } from "components";
import Filters from "./Filters";
import FiltersPrint from "./FiltersPrint";
import ReportTable from "./ReportTable";
import {
  formatCompaniesOptions,
  formatProductsOptions,
  formatTradingTypesInvolved,
  formatBrandsOptions,
} from "./formatters";

import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";
import firebase from "config/firebase";
import uniqid from "uniqid";

const ProductListReport = () => {
  const db = firebase.firestore();
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState("startDate");

  const [company, setCompany] = useState({ value: "", label: "Todas" });
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [brand, setBrand] = useState({ value: "", label: "Todas" });
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [product, setProduct] = useState({ value: "", label: "Todos" });
  const [productsOptions, setProductsOptions] = useState([]);
  const [tradingType, setTradingType] = useState({ value: "", label: "Todos" });
  const [tradingTypesOptions, setTradingTypesOptions] = useState([]);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const { loadingAuctions, auctions } = useGetAuctions(
    "",
    startDate,
    endDate,
    true
  );

  const resetFilters = () => {
    setCompany({ value: "", label: "Todas" });
    setBrand({ value: "", label: "Todas" });
    setProduct({ value: "", label: "Todos" });
    setTradingType({ value: "", label: "Todos" });
  };

  useEffect(() => {
    setLoading(true);
    setReport([]);
    resetFilters();
    let companiesInvolved = {};
    let relatedProducts = {}; // produtos (modelo, marca, etc) para serem baixados depois
    let tradingTypesInvolved = {};
    let totalAuctionsItems = {}; // itens dos auctions (instalado, preço)
    let optionsContract = [{ value: "", label: "Todos" }];
    auctions.map((auction) => {
      companiesInvolved[auction.company.id] = auction.company;
      tradingTypesInvolved[auction.tradingType.value] = auction.tradingType;

      Object.keys(auction.items).forEach((key) => {
        let auctionItem = auction.items[key];
        relatedProducts[auctionItem.product.id] = auctionItem.product;
      });
    });

    setCompaniesOptions(formatCompaniesOptions(companiesInvolved));
    setTradingTypesOptions(formatTradingTypesInvolved(tradingTypesInvolved));
    priorLoadProducts(relatedProducts);
  }, [auctions]);

  const priorLoadProducts = (items) => {
    loadProducts(items).then((prodSnapshots) => {
      let products = {};
      prodSnapshots.forEach((prodSnap) => {
        const product = prodSnap.data();
        products[product.id] = product;
      });
      setProductsOptions(formatProductsOptions(products));
      setBrandsOptions(formatBrandsOptions(products));
    });
  };

  const loadProducts = (relatedProducts) => {
    const promises = [];
    Object.keys(relatedProducts).forEach((key) => {
      let product = relatedProducts[key];
      promises.push(product.get());
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    formatReport();
  }, [auctions]);

  const formatReport = () => {
    auctions.forEach((auction) => {
      Object.values(auction.items).forEach((item) => {
        db.collection("products")
          .where("id", "==", item.product.id)
          .get()
          .then((querySnapshot) => {
            let data = querySnapshot.docs.map((doc) => doc.data());
            data.forEach((product) => {
              setReport((prevReport) => [
                ...prevReport,
                {
                  id: uniqid(),
                  companyId: auction.company.id,
                  brand: product.brand.name,
                  brandId: product.brand.id,
                  productId: product.id,
                  tradingTypeId: auction.tradingType.value,
                  number: auction.number,
                  model: product.name,
                  brand: product.brand.name,
                  capacity: product.info,
                  quantity: item.totalUnits,
                  price: item.unitPrice,
                  unitOfMeasurement: product.unitOfMeasurement.name,
                  listPrice: product.productFamily
                    ? product.productFamily.price
                    : "-",
                  priceDifference: product.productFamily
                    ? item.unitPrice - product.productFamily.price
                    : "-",
                },
              ]);
            });
          })
          .catch((error) => {
            console.log("erro", error);
            setError(true);
          });
      });
    });
    setLoading(false);
  };

  const isLoading = loadingAuctions || loading;

  let reportsFiltered = report.filter(
    (reportFiltered) =>
      (!brand.value || reportFiltered.brandId === brand.value) &&
      (!company.value || reportFiltered.companyId === company.value) &&
      (!product.value.id || reportFiltered.productId === product.value.id) &&
      (!tradingType.value || reportFiltered.tradingTypeId === tradingType.value)
  );

  return (
    <div className="animated">
      <Card>
        <CardHeader>Relatório de Produtos</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                orientation={"vertical"}
                openDirection={"down"}
                displayFormat={"DD-MM-YYYY"}
                isOutsideRange={() => false}
                startDatePlaceholderText="Data inicial"
                endDatePlaceholderText="Data final"
                phrases={ptBrPhrases}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Filters
                company={company}
                setCompany={setCompany}
                companiesOptions={companiesOptions}
                brandsOptions={brandsOptions}
                productsOptions={productsOptions}
                tradingTypesOptions={tradingTypesOptions}
                brand={brand}
                setBrand={setBrand}
                product={product}
                setProduct={setProduct}
                tradingType={tradingType}
                setTradingType={setTradingType}
              />
            </Col>
          </Row>
          <Row className="d-none d-print-block">
            <Col>
              <FiltersPrint
                company={company}
                brand={brand}
                product={product}
                tradingType={tradingType}
              />
            </Col>
          </Row>
          <Row className="d-print-none">
            <Col>
              <Button className="px-0" color="link" onClick={resetFilters}>
                apagar filtros
              </Button>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => window.print()}>
                Imprimir
              </Button>
            </Col>
          </Row>

          {error && (
            <Row className="mt-4">
              <Col className="text-center">
                <h4>Não foi possível concluir sua solicitação</h4>
                <br />
                Por favor tente novamente
              </Col>
            </Row>
          )}
          {!error && (
            <Row className="mt-4">
              {isLoading ? (
                <CenteredSpinner />
              ) : (
                <Col>
                  <ReportTable data={reportsFiltered} auctions={auctions} />
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductListReport;
