import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import firebase from "config/firebase";
import { useSelector } from "react-redux";
import { CenteredSpinner } from "components";
import updatePackingListData from "../PackingListDelivery/updatePackingListData";
import { notify } from "services";

interface DeleteModalPropsInterface {
  closeModal: any;
  delivery: any;
  refreshList: any;
}

const DeleteModal = (props: DeleteModalPropsInterface) => {
  const { closeModal, delivery, refreshList } = props;
  const [packingList, setPackingList] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [didDelete, setDidDelete] = useState(false);
  const user = useSelector<any>((state) => state.user);
  const id = delivery?.id;
  const [message, setMessage] = useState("");
  const [isOldDelivery, setIsOldDelivery] = useState(false);

  const db = firebase.firestore();

  useEffect(() => {
    if (!!delivery) {
      setMessage(
        `Tem certeza que deseja excluir a entrega ${delivery?.number}?`
      );
      setVerified(false);
      setDidDelete(false);
      setPackingList(null);

      const isOldDelivery = !!!delivery.packingList;
      setIsOldDelivery(isOldDelivery);
      if (!isOldDelivery) loadPackingList();
    }
  }, [delivery]);

  const loadPackingList = () => {
    setLoading(true);
    delivery.packingList.packingListRef
      .get()
      .then((snap: any) => {
        setPackingList(snap.data());
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("erro: ", error);
        notify({
          description:
            "Não foi possivel baixar os dados da entrega. Por favor, tente novamente.",
          type: "danger",
        });
        closeModal();
      });
  };

  const handleConfirm = () => {
    if (!verified) {
      if (isOldDelivery) return deleteDelivery();

      if (packingList) {
        deleteDelivery();
      } else {
        notify({
          description:
            "Não foi possivel excluir essa entrega. Por favor, tente novamente.",
          type: "danger",
        });
        closeModal();
      }
    } else {
      if (didDelete) refreshList();
      closeModal();
    }
  };

  const deleteDelivery = () => {
    setLoading(true);
    let batch = db.batch();

    const docRef = db.collection("deliveries").doc(id);
    const logRef = db.collection("logs").doc();
    batch.delete(docRef);

    const log = {
      action: "delete",
      reference: delivery.number,
      collection: "deliveries",
      doc: id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user,
    };
    batch.set(logRef, log);

    if (!isOldDelivery) {
      const { packingListArray, packingListStatus } = updatePackingListData({
        packingList,
        packingListSelectedId: delivery.packingList.packingListSelectedId,
        type: "delete",
        deliveryId: id,
      });

      const packingListRef = db.collection("packingLists").doc(packingList.id);

      batch.update(packingListRef, {
        status: packingListStatus,
        packingListArray,
      });
    }

    batch
      .commit()
      .then(() => {
        console.log("Entrega excluída com sucesso");
        setVerified(true);
        setLoading(false);
        setDidDelete(true);
        setMessage("Entrega excluída com sucesso");
      })
      .catch((error: any) => {
        alert("Erro ao salvar unidade de medida");
        console.error("Erro ao salvar unidade de medida: ", error);
      });
  };

  return (
    <Modal isOpen={!!delivery} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Aviso</ModalHeader>
      <ModalBody>{loading ? <CenteredSpinner /> : <p>{message}</p>}</ModalBody>
      {!loading && (
        <ModalFooter>
          {!verified && (
            <Button color="secondary" onClick={closeModal}>
              Não
            </Button>
          )}
          <Button color="primary" onClick={handleConfirm}>
            {verified ? "Ok" : "Sim"}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DeleteModal;
