import React, { useState, useEffect, useRef } from "react";
import firebase from "config/firebase";
import ReactDOM from "react-dom";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { useGetPackingLists } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  DeleteModal,
  CenteredSpinner,
  TogleSwitch,
  QuickViewModal,
} from "components";
import PackingListDetailsInfo from "./Details/PackingListDetailsInfo";
import moment, { localeData } from "moment";

const PawnList = ({ history }) => {
  const statusValue = (status) => {
    if (status === "Pendente/Parcial") return 0;
    if (status === "Concluído") return 2;
    if (status === "Todos") return 3;
  };

  const { SearchBar } = Search;
  const [modalItem, setModalItem] = useState(undefined);
  const [count, setCount] = useState(0);

  const switchOptions = ["Pendente/Parcial", "Concluído", "Todos"];
  const [optionSelected, setSelected] = useState(switchOptions[0]);
  const [quickViewItem, setQuickViewItem] = useState(undefined);

  const { loadingPackingLists, packingLists } = useGetPackingLists(
    count,
    undefined,
    undefined,
    undefined,
    statusValue(optionSelected)
  );

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const changeStatusDelivery = (data) => {
    const db = firebase.firestore();
    if (data) {
      data.packingListArray.forEach((delivery) => {
        if (delivery.pawn.id) {
          db.collection("pawns")
            .doc(delivery.pawn.id)
            .update({ deliveryStatus: 0 });
        }
      });
    }
  };

  const columns = [
    {
      sort: true,
      dataField: "number",
      text: "Romaneio nº",
    },
    {
      sort: true,
      dataField: "driver",
      text: "Motorista",
    },
    {
      sort: true,
      dataField: "createdAt.seconds",
      text: "Criado em",
      formatter: (cell, row) => {
        return <p>{moment(cell * 1000).format("DD/MM/YYYY, HH:mm")}</p>;
      },
    },
    {
      sort: true,
      dataField: "status",
      text: "Situação da entrega",
      formatter: (cell, row) => {
        return (
          <span className={`${["red", "yellow", "green"][cell]}-pill pill`}>
            {["Pendente", "Parcial", "Concluído"][cell]}
          </span>
        );
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Ações",
      headerAlign: "right",
      formatter: (cell, row) => {
        return (
          <p className="text-right">
            <i
              className="icon-eye mr-2 action-button"
              onClick={() => {
                setQuickViewItem(row);
              }}
            />
            <i
              className="icon-note action-button"
              onClick={() => {
                history.push({
                  pathname: "./romaneios/detalhes",
                  state: { row },
                });
              }}
            />
            <i
              className="icon-trash ml-2 action-button"
              onClick={() => {
                setModalItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const defaultSorted = [
    {
      dataField: "number",
      order: "desc",
    },
  ];

  const searchBarRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(searchBarRef.current).focus();
  }, []);

  return (
    <>
      <DeleteModal
        closeModal={() => setModalItem(undefined)}
        path="packingLists"
        type="packingList"
        modalItem={modalItem}
        refreshList={() => setCount(count + 1)}
        deliveryStatus={changeStatusDelivery}
      />
      <QuickViewModal
        closeModal={() => setQuickViewItem(undefined)}
        isOpen={quickViewItem}
        title={`Romaneio #${quickViewItem?.number}`}
        print
      >
        <PackingListDetailsInfo data={quickViewItem} />
      </QuickViewModal>
      <div className="animated">
        <Card>
          <CardHeader>Romaneios</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={packingLists.filter((item) =>
                    optionSelected === switchOptions[0]
                      ? item.status === 0 || item.status === 1
                      : optionSelected === switchOptions[1]
                      ? item.status === 2
                      : item
                  )}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar na tabela"
                            style={{ width: "100%" }}
                            ref={searchBarRef}
                          />
                        </Col>
                        <Col sm={3} className="mb-3">
                          <Button
                            block
                            color="success"
                            onClick={() => {
                              history.push("./romaneios/detalhes");
                            }}
                          >
                            Novo Romaneio
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <TogleSwitch
                            values={switchOptions}
                            selected={optionSelected}
                            handleChange={(option) => setSelected(option)}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {loadingPackingLists ? (
                          <CenteredSpinner />
                        ) : (
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              hover
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                            />
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PawnList;
