import { createReducer } from "typesafe-actions";
import { EditActions } from "actions";

const {
    setWarning,
} = EditActions;

const initialState = {
    warning: false
};

const reducer = createReducer(initialState)
.handleAction(setWarning, (state, { payload }) => ({
    ...state,
    warning: payload.warning
}));

export default reducer;
