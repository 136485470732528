import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UserForm from "./UserForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { statusOptions, userRolesOptions, yesAndNo } from "utils/general";

const UserDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const isEdit = idx(location, (_) => _.state.row.uid) ? true : false;
  const uid = idx(location, (_) => _.state.row.uid);
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Insira o nome do usuário"),
      email: Yup.string().required("Insira o nome email do usuário"),
      role: Yup.string().required("Insira o papel do usuário"),
      status: Yup.string().required("Insira o status do usuário"),
      permissionProduct: Yup.string().required(
        "Insira a permissão para criação de produto"
      ),
    }),
    handleSubmit: (values, { setSubmitting }) => {
      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const editUser = {
        name: values.name,
        role: values.role.value,
        status: values.status.value,
        permissionProduct: values.permissionProduct,
        updatedAt: serverTimestamp,
      };

      const docRef = db.collection("users").doc(uid);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "update",
        reference: editUser["name"],
        collection: "users",
        doc: uid,
        createdAt: serverTimestamp,
        user,
      };

      let batch = db.batch();
      batch.update(docRef, editUser);
      batch.set(logRef, log);
      batch
        .commit()
        .then(() => {
          console.log("Usuário salvo com sucesso");
          setSubmitting(false);
          history.go(0);
        })
        .catch(function(error) {
          alert("Erro ao salvar usuário");
          console.error("Erro ao salvar usuário: ", error);
          setSubmitting(false);
        });
    },
    displayName: "UserForm",
    mapPropsToValues: () => {
      let data = {
        name: "",
        email: "",
        createdAt: "",
        status: "",
        role: "",
        permissionProduct: "",
      };
      if (isEdit) {
        const row = history.location.state.row;
        data = {
          name: row.name,
          email: row.email,
          createdAt: row.createdAt,
          status: statusOptions.find((status) => status.value === row.status),
          role: userRolesOptions.find((role) => role.value === row.role),
          permissionProduct: row.permissionProduct,
        };
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(UserForm);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Usuário</CardHeader>
        <CardBody>
          <MyEnhancedForm />
        </CardBody>
      </Card>
    </div>
  );
};

export default UserDetails;
