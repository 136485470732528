import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { CenteredSpinner } from "components";
import { money } from "utils/mask";
import firebase from "config/firebase";

import "./style.css";

import getDeliveries from "./Load/getDeliveries";
import getPawns from "./Load/getPawns";
import getPackingList from "./Load/getPackingList";
import dispatch, { DashboardActions } from "actions";
import { useSelector } from "react-redux";

// React DateRangePicker
import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";

import moment from "moment";
import "moment/locale/pt-br";

const Dashboard = () => {
  const db = firebase.firestore();
  moment.locale("pt-br");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const [updateDashboardValue, setUpdateDashboardValue] = useState();

  const [isLoading, setLoading] = useState(false);

  const tradingTypesValues = useSelector(
    (state) => state.dashboard.tradingTypesValues
  );
  const updateAt = useSelector((state) => state.dashboard.updateAt);

  const [deliveries, setDeliveries] = useState([]);
  const [packingLists, setPackingLists] = useState([]);
  const [pawns, setPawns] = useState([]);

  const deliveredItemsSum = (delivery) => {
    let deliveryFinalSum = 0;
    const deliveryItemsQuantity = delivery.deliveryItemsQuantity;
    Object.keys(deliveryItemsQuantity).map((key) => {
      const unitPrice = delivery?.pawn?.auction?.items[key]?.unitPrice;
      const quantity = deliveryItemsQuantity[key];
      const finalPrice = quantity * unitPrice;
      deliveryFinalSum += finalPrice;
    });
    return deliveryFinalSum;
  };

  const sumItemsToDelivery = (value) => {
    let sum = 0,
      quantity = 0;
    if (value.status !== 2) {
      value.packingListArray.map((item) => {
        if (item?.status !== 1) {
          Object.keys(item.packingListItemsQuantity).map((key) => {
            quantity = item.packingListItemsQuantity[key];
            if (!isNaN(quantity)) sum += quantity;
          });
        }
      });
    }

    return sum;
  };

  const sumItemsValueToDelivery = (value, pawns) => {
    let sum = 0,
      finalPrice = 0;
    if (value.status !== 2) {
      value.packingListArray.map((item) => {
        if (item?.status !== 1) {
          Object.keys(item.packingListItemsQuantity).map((key) => {
            let unitPrice = 0;
            Object.values(pawns).map((pawn) => {
              if (pawn.auction.items[key]) {
                unitPrice = pawn.auction.items[key].unitPrice;
              }
            });
            finalPrice = item.packingListItemsQuantity[key];
            if (!isNaN(finalPrice) && !isNaN(unitPrice))
              sum += finalPrice * unitPrice;
          });
        }
      });
    }
    return sum;
  };

  const commissionCard = (color, new_value, text, onlyNumber) => {
    let value = new_value ? new_value : 0;
    return (
      <Row className="no-gutters mt-2" key={value}>
        <Col
          xs={3}
          className={`text-center text-white commission-card-left py-2 bg-${color}`}
        >
          <i className="fa fa-money" aria-hidden="true"></i>
        </Col>
        <Col xs={9} className="bg-white commission-card-right py-2 pl-3">
          {!isLoading ? (
            <h5 className={`text-${color} mb-0`}>
              {onlyNumber ? value : money(value)}
            </h5>
          ) : (
            <CenteredSpinner />
          )}
          {!isLoading && text}
        </Col>
      </Row>
    );
  };

  const updateButton = () => {
    return (
      <Row className="d-print-none" key={1}>
        <Col sm={3} className="mb-3">
          <Button
            block
            color={"success"}
            onClick={() => {
              update();
              setStartDate();
              setEndDate();
            }}
          >
            {"Atualizar"}
          </Button>
        </Col>

        <Col className="button-update">
          <h6>
            Atualizado em: <p>{valueUpdate?.date}</p>
          </h6>
        </Col>
      </Row>
    );
  };

  const update = () => {
    dispatch(DashboardActions.saveTradingTypesValues, {
      tradingTypesValues: undefined,
    });
    setDeliveries([]);
    setPawns([]);
    setPackingLists([]);

    setLoading(true);
    getDeliveries(startDate, endDate)
      .then((deliveries) => {
        getPawns(startDate, endDate)
          .then((pawns) => {
            getPackingList(startDate, endDate)
              .then((packingLists) => {
                setDeliveries(deliveries);
                setPawns(pawns);
                setPackingLists(packingLists);
                let newTradingTypesValues = {
                  date: moment(updateAt).format("D [de] MMMM [de] Y"),
                  pregaoEletronico: 0,
                  presencial: 0,
                  tomadaDePreco: 0,
                  compraDireta: 0,
                  itemsToDelivery: 0,
                  itemsValueToDelivery: 0,
                };
                Object.values(deliveries).map((value) => {
                  const tradingType = value?.pawn?.auction?.tradingType?.value;
                  const deliveredSum = deliveredItemsSum(value);
                  newTradingTypesValues[tradingType] += deliveredSum;
                });

                Object.values(packingLists).map((value) => {
                  newTradingTypesValues[
                    "itemsToDelivery"
                  ] += sumItemsToDelivery(value);
                  newTradingTypesValues[
                    "itemsValueToDelivery"
                  ] += sumItemsValueToDelivery(value, pawns);
                });
                dispatch(DashboardActions.saveTradingTypesValues, {
                  tradingTypesValues: newTradingTypesValues,
                });
                setLoading(false);

                if (!startDate && !endDate) {
                  const docRef = db
                    .collection("generalSettings")
                    .doc("dashboard");
                  docRef.set(newTradingTypesValues);
                }
              })
              .catch(() => {
                console.log("error 3");
              });
          })
          .catch(() => {
            console.log("error 2");
          });
      })
      .catch((error) => {
        console.log("error 1", error);
      });
  };

  useEffect(() => {
    if (!!startDate && !!endDate) {
      update();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = () => {
    return db
      .collection("generalSettings")
      .doc("dashboard")
      .get()
      .then((doc) => {
        setUpdateDashboardValue(doc.data());
      })
      .catch((error) => {
        console.log("Erro ao obter os dados:", error);
      });
  };

  const valueUpdate = { ...updateDashboardValue };
  return (
    <div className="animated fadeIn">
      {/* <Button onClick={voltagePacking}>Delivery</Button> */}
      <Row className="header">
        <Col md={6}>{updateButton()}</Col>
        <Col className="text-right">
          <DateRangePicker
            startDate={startDate}
            startDateId="startDate"
            endDate={endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            orientation={"vertical"}
            openDirection={"down"}
            displayFormat={"DD-MM-YYYY"}
            isOutsideRange={() => false}
            startDatePlaceholderText="Data inicial"
            endDatePlaceholderText="Data final"
            phrases={ptBrPhrases}
          />
        </Col>
      </Row>
      <div>
        <Row>
          <Col md={4}>
            {commissionCard(
              "info",
              !endDate
                ? valueUpdate?.pregaoEletronico
                : tradingTypesValues?.pregaoEletronico,
              "Pregão eletrônico"
            )}
          </Col>
          <Col md={4}>
            {commissionCard(
              "warning",
              !endDate
                ? valueUpdate?.presencial
                : tradingTypesValues?.presencial,
              "Presencial"
            )}
          </Col>
          <Col md={4}>
            {commissionCard(
              "success",
              !endDate
                ? valueUpdate?.tomadaDePreco
                : tradingTypesValues?.tomadaDePreco,
              "Tomada de preço"
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {commissionCard(
              "danger",
              !endDate
                ? valueUpdate?.compraDireta
                : tradingTypesValues?.compraDireta,
              "Compra Direta"
            )}
          </Col>
          <Col md={4}>
            {commissionCard(
              "pink",
              !endDate
                ? valueUpdate?.itemsToDelivery
                : tradingTypesValues?.itemsToDelivery,
              "Quantidade de Itens à Entregar",
              true
            )}
          </Col>
          <Col md={4}>
            {commissionCard(
              "purple",
              !endDate
                ? valueUpdate?.itemsValueToDelivery
                : tradingTypesValues?.itemsValueToDelivery,
              "Valor dos Itens à Entregar"
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
