import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { CenteredSpinner, QuickViewModal } from "components";
import { useHistory } from "react-router-dom";
import AuctionDetails from "../../AuctionDetails";
import { money } from "utils/mask";

const ReportDetails = ({
  product,
  auctions,
  filteredAuctionsItems,
}: {
  product: any;
  auctions: any;
  filteredAuctionsItems: any;
}) => {
  const isQuickView = !!product;
  const history = useHistory();

  const [auctionsRelated, setAuctions] = useState<any[]>([]);
  const [auction, setAuction] = useState<any>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [state_, setState] = useState<{ row: any; products: any }>({
    row: "",
    products: "",
  });

  const load = async () => {
    setLoading(true);
    let auctionsRelated: any[] = [];

    let promise_values = auctions.map(async (auction: any) => {
      let add = false;
      let items: any[] = [];
      let auctionItemsQuantity: any[] = [];
      let promise_keys_1 = Object.keys(auction.items).map((key: any) => {
        let item = auction.items[key];
        if (item.product.id === product.id) {
          items[key] = item;
          auctionItemsQuantity[key] = item.totalUnits;
          add = true;
        }
      });
      if (add) {
        auctionsRelated = [
          ...auctionsRelated,
          {
            ...auction,
            items,
          },
        ];
      }
      await Promise.all(promise_keys_1);
    });
    await Promise.all(promise_values);
    setAuctions(auctionsRelated);
    setLoading(false);
  };

  useEffect(() => {
    if (product && auctions) {
      load();
    }
  }, [product, auctions]);

  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell: any, row: any) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
  ];

  const valueTotal = (items: any) => {
    let value_money = 0;
    Object.values(items).map((item: any) => {
      value_money += item.totalUnits * item.unitPrice;
    });
    return money(value_money);
  };

  const preLoadProducts = (itemsWithProducts: any) => {
    const promises: any[] = [];

    Object.keys(itemsWithProducts).forEach((key) => {
      let item = itemsWithProducts[key];
      if (item?.product) {
        try {
          promises.push(item.product.get());
        } catch {}
      }
    });
    return Promise.all(promises).catch((error) => {
      alert("Erro ao baixar produtos");
      console.log("erro: ", error);
    });
  };

  const columnsAuction = [
    {
      sort: true,
      dataField: "number",
      text: "Número",
    },
    {
      sort: true,
      dataField: "governmentAgency.name",
      text: "Órgão",
    },
    {
      dataField: "",
      text: "Valor total do pregão",
      headerAlign: "left",
      formatter: (cell: any, row: any, key: any) => {
        return (
          <p className="text-left" key={key}>
            {valueTotal(row.items)}
          </p>
        );
      },
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
      headerAlign: "center",
      formatter: (cell: any, row: any) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                // setAuction(row)
                let products: any = {};

                preLoadProducts(row.items).then((prodSnapshots: any) => {
                  prodSnapshots.forEach((prodSnap: any) => {
                    const product = prodSnap.data();
                    products[product.id] = product;
                  });
                  // history.push({
                  //   pathname: "./pregoes/detalhes",
                  //   state: { row, products },
                  // });
                  setState({ row, products });
                  setAuction(row);
                });
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <div className="animated">
      {!!auction && (
        <QuickViewModal
          closeModal={() => setAuction(undefined)}
          isOpen={!!auction}
          title={`Pregão #${auction?.number}`}
        >
          <AuctionDetails
            history={{ location: { state: state_ }, notEdit: true }}
          />
        </QuickViewModal>
      )}
      {isQuickView && (
        <Fragment>
          <div className="animated">
            <Card>
              <BootstrapTable
                keyField="model"
                data={[product]}
                columns={columns}
                bordered={false}
                hover
              />
            </Card>
            {isLoading && <CenteredSpinner />}
            {!isLoading && auctions && (
              <Card>
                <CardHeader>Pregões</CardHeader>
                <BootstrapTable
                  keyField="number"
                  data={auctionsRelated}
                  columns={columnsAuction}
                  bordered={false}
                  hover
                />
              </Card>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ReportDetails;
