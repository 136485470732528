import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import ProductForm from "./ProductForm";
import { Card, CardHeader, CardBody } from "reactstrap";
import firebase from "config/firebase";
import idx from "idx";
import { useSelector } from "react-redux";
import dispatch, { EditActions } from "actions";

const ProductDetails = ({ history }) => {
  const isEdit = idx(history, (_) => _.location.state.row.id) ? true : false;
  const id = idx(history, (_) => _.location.state.row.id);
  const user = useSelector((state) => state.user);
  const db = firebase.firestore();

  const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Insira o nome do produto"),
      brand: Yup.string().required("Escolha uma marca"),
      info: Yup.string().required("Insira a potência / tamanho / capacidade"),
      unitOfMeasurement: Yup.string().required("Escolha uma unidade de medida"),
      productFamily: Yup.string().required("Escolha uma família de produtos"),
    }),
    handleSubmit: (values, { setSubmitting }) => {
      let newProduct = {
        name: values.name,
        brand: values.brand.data,
        info: values.info,
        unitOfMeasurement: values.unitOfMeasurement.data,
        productFamily: values.productFamily.data,
        total: {
          totalWeight: values.total?.totalWeight? parseFloat(values.total?.totalWeight) : 0,
          totalCubedWeight: values.total?.totalCubedWeight ? parseFloat(values.total?.totalCubedWeight) : 0,
          totalVolume:values.total?.totalVolume ?  parseFloat(values.total?.totalVolume) : 0,
          totalCubed: values.total?.totalCubed ? parseFloat(values.total?.totalCubed) : 0,
        },
      };

      if (
        values.volume1?.height &&
        values.volume1?.width &&
        values.volume1?.depth &&
        values.volume1?.weight &&
        values.volume1?.volume &&
        values.volume1?.cubedweight
      ) {
        newProduct = {
          ...newProduct,
          volume1: {
            height: values.volume1?.height,
            width: values.volume1?.width,
            depth: values.volume1?.depth,
            weight: values.volume1?.weight,
            volume: values.volume1?.volume,
            cubedweight: values.volume1?.cubedweight,
          },
        };
      }

      if (
        values.volume2?.height &&
        values.volume2?.width &&
        values.volume2?.depth &&
        values.volume2?.weight &&
        values.volume2?.volume &&
        values.volume2?.cubedweight
      ) {
        newProduct = {
          ...newProduct,
          volume2: {
            height: values.volume2?.height,
            width: values.volume2?.width,
            depth: values.volume2?.depth,
            weight: values.volume2?.weight,
            volume: values.volume2?.volume,
            cubedweight: values.volume2?.cubedweight,
          },
        };
      }


      const docId = isEdit ? id : db.collection("products").doc().id;
      newProduct["id"] = docId;

      let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      if (isEdit) {
        newProduct["createdAt"] = values["createdAt"];
        newProduct["updatedAt"] = serverTimestamp;
      } else {
        newProduct["createdAt"] = serverTimestamp;
      }

      const docRef = db.collection("products").doc(docId);
      const logRef = db.collection("logs").doc();

      const log = {
        action: "create",
        reference: `${newProduct["brand"]["name"]} - ${newProduct["name"]} ${newProduct["info"]} ${newProduct["unitOfMeasurement"]["name"]}`,
        collection: "products",
        doc: docId,
        createdAt: serverTimestamp,
        user,
      };

      let batch = db.batch();
      if (isEdit) {
        log["action"] = "update";
        batch.update(docRef, newProduct);
      } else {
        batch.set(docRef, newProduct);
      }
      batch.set(logRef, log);

      batch
        .commit()
        .then(() => {
          console.log("Produto salvo com sucesso");
          setSubmitting(false);
          history.goBack();
        })
        .catch(function(error) {
          alert("Erro ao salvar produto");
          console.error("Erro ao salvar produto: ", error);
          setSubmitting(false);
        });
      dispatch(EditActions.setWarning, { warning: false });
    },
    displayName: "ProductForm",
    mapPropsToValues: () => {
      let data = {
        name: "",
        info: "",
        createdAt: "",
        brand: "",
        unitOfMeasurement: "",
        productFamily: "",
        volume1: {
          height: undefined,
          width: undefined,
          depth: undefined,
          weight: undefined,
          volume: undefined,
          cubedweight: undefined,
        },
        volume2: {
          height: undefined,
          width: undefined,
          depth: undefined,
          weight: undefined,
          volume: undefined,
          cubedweight: undefined,
        },
        total: {
          totalWeight: 0,
          totalCubedWeight: 0,
          totalVolume: 0,
          totalCubed: 0,
        },
      };
      if (isEdit) {
        const row = history.location.state.row;
        data = {
          name: row.name,
          info: row.info,
          createdAt: row.createdAt,
          brand: {
            label: row.brand.name,
            data: row.brand,
          },
          unitOfMeasurement: {
            label: row.unitOfMeasurement.name,
            data: row.unitOfMeasurement,
          },
          volume1: {
            height: row.volume1?.height,
            width: row.volume1?.width,
            depth: row.volume1?.depth,
            weight: row.volume1?.weight,
            volume: row.volume1?.volume,
            cubedweight: row.volume1?.cubedweight,
          },
          volume2: {
            height: row.volume2?.height,
            width: row.volume2?.width,
            depth: row.volume2?.depth,
            weight: row.volume2?.weight,
            volume: row.volume2?.volume,
            cubedweight: row.volume2?.cubedweight,
          },
          total: {
            totalWeight: row.total?.totalWeight,
            totalCubedWeight: row.total?.totalCubedWeight,
            totalVolume: row.total?.totalVolume,
            totalCubed: row.total?.totalCubed,
          },
        };

        if (row.productFamily) {
          data = {
            ...data,
            productFamily: {
              label: row.productFamily.name,
              data: row.productFamily,
            },
          };
        }
      }
      return data;
    },
  });

  const MyEnhancedForm = formikEnhancer(ProductForm);

  return (
    <div className="animated">
      <Card>
        <CardHeader>Produto</CardHeader>
        <CardBody>
          <MyEnhancedForm />
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductDetails;
