import React from "react";
import { toast } from "react-toastify";
import { Notification } from "components";

interface NotifyProps {
  title?: string;
  description: string;
  type?: "info" | "success" | "warning" | "error" | "danger";
}

const notify = ({ title, description, type = "info" }: NotifyProps) => {
  const toastTypes = [
    { type: "info", fn: toast.info },
    { type: "success", fn: toast.success },
    { type: "warning", fn: toast.warn },
    { type: "danger", fn: toast.error },
  ];

  const toastIndex = toastTypes.findIndex((x) => x.type === type);

  const Component = <Notification title={title} description={description} />;

  return toastTypes[toastIndex > -1 ? toastIndex : 0].fn(Component, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export default notify;
