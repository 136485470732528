import React, { Fragment, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { voltageTranslation } from "utils/general";
import { QuickViewModal } from "components";
import { money } from "utils/mask";
import { promises } from "fs";
import ReportDetails from "./Details/ReportDetails";
import FilteredPawnReport from "./Details/FilteredPawnReport";

const PawnReport = ({ data, pawns, pawnDetail, isEditPawn, setIsEditPawn }) => {
  const [quickViewPawn, setQuickViewItem] = useState(undefined);


  const columns = [
    {
      sort: true,
      dataField: "model",
      text: "Modelo",
    },
    {
      sort: true,
      dataField: "brand",
      text: "Marca",
    },
    {
      sort: true,
      dataField: "info",
      text: "Capacidade",
      formatter: (cell, row) => `${cell} ${row.unitOfMeasurement}`,
    },
    {
      sort: true,
      dataField: "voltage",
      text: "Voltagem",
      formatter: (cell, row) => voltageTranslation(cell),
    },
    {
      sort: true,
      dataField: "quantity",
      text: "Quantidade",
    },
    {
      sort: true,
      dataField: "middlePrice",
      text: "Preço Médio",
      formatter: (cell, row) => money(cell),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "Empenhos",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <p className="text-md-center">
            <i
              className="icon-eye action-button"
              onClick={() => {
                setQuickViewItem(row);
              }}
            />
          </p>
        );
      },
    },
  ];

  return (
    <Fragment>
      <FilteredPawnReport
        pawnDetail={pawnDetail}
        isEditPawn={isEditPawn}
        setIsEditPawn={setIsEditPawn}
      />
      <QuickViewModal
        closeModal={() => setQuickViewItem(undefined)}
        isOpen={quickViewPawn}
        title={`Produto`}
      >
        <ReportDetails product={quickViewPawn} pawns={pawns} />
      </QuickViewModal>
      <BootstrapTable
        keyField="key"
        data={data}
        columns={columns}
        bordered={false}
        hover
      />
    </Fragment>
  );
};

export default PawnReport;
