import React from "react";
import { Table } from "reactstrap";
import { money } from "utils/mask";
import idx from "idx";
import "./style.css";

const PLDeliveryItems = ({
  pawn,
  items,
  relatedProducts,
  values,
  isDelivered,
}) => {
  const formatItems = () => {
    let formattedItems = [];
    if (items) {
      for (let [key, value] of Object.entries(items)) {
        if (pawn.pawnItemsQuantity[value.id] > 0) formattedItems.push(value);
      }
    }
    return formattedItems;
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      rows.push(
        <tr>
          <td title="Modelo">
            {idx(relatedProducts, (_) => _[row.product.id].name)}
          </td>
          <td title="Marca">
            {idx(relatedProducts, (_) => _[row.product.id].brand?.name)}
          </td>
          <td title="Capacidade">{`${idx(
            relatedProducts,
            (_) => _[row.product.id].info
          )} ${idx(
            relatedProducts,
            (_) => _[row.product.id].unitOfMeasurement?.name
          )}`}</td>
          <td title="Instalado?">{row?.installed?.label}</td>
          <td title="Voltagem">{row?.voltage?.label}</td>
          <td title="Valor unitário">{money(row.unitPrice)}</td>
          <td title="Quantidade">{values?.packingListItemsQuantity[row.id]}</td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <Table className={`small-text my-0 ${isDelivered && "delivered"}`}>
      <thead>
        <tr>
          <th>Modelo</th>
          <th>Marca</th>
          <th>Capacidade</th>
          <th>Instalado?</th>
          <th>Voltagem</th>
          <th>Valor unitário</th>
          <th>Quantidade</th>
        </tr>
      </thead>
      <tbody>{tableRows()}</tbody>
    </Table>
  );
};

export default PLDeliveryItems;
