import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Collapse,
  Row,
} from "reactstrap";
const PLDeliveryData = ({ total }: any) => {
  return (
    <>
      <hr />
      <h5>Totais do romaneio:</h5>
      <Row>
        <Col md="3" className="mt-2">
          <label htmlFor="volume" style={{ display: "block" }}>
            Volume:
          </label>
          <p>{total?.totalVolume}</p>
        </Col>
        <Col md="3" className="mt-2">
          <label htmlFor="name" style={{ display: "block" }}>
            Peso Total:
          </label>
          <p>{total?.totalWeight}</p>
        </Col>
        <Col md="3" className="mt-2">
          <label htmlFor="totalCubage" style={{ display: "block" }}>
            Cubagem Total:
          </label>
          <p>{total?.totalCubage}</p>
        </Col>
        <Col md="3" className="mt-2">
          <label htmlFor="cubedweight" style={{ display: "block" }}>
            Peso Cubado:
          </label>
          <p>{total?.totalCubedWeight}</p>
        </Col>
      </Row>
    </>
  );
};

export default PLDeliveryData;
