import React, { useState } from "react";
import {
  Nav,
  NavItem,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
import { AppSidebarToggler } from "@coreui/react";
import { useHistory } from "react-router-dom";

const DefaultHeader = (props) => {
  const history = useHistory();
  const { onLogout } = props;
  const [dropdownOpen, setOpenDropdownOpen] = useState(false);
  const userName = useSelector((state) => state.user.name);
  const userRole = useSelector((state) => state.user.role);

  return (
    <React.Fragment>
      <AppSidebarToggler
        className="d-lg-none white-toggler"
        display="md"
        mobile
      />
      <span className="brand-text">GESTOR DE EMPENHOS</span>
      <AppSidebarToggler
        className="d-md-down-none white-toggler"
        display="lg"
      />
      <Nav className="ml-auto" navbar>
        <ButtonDropdown
          className="mr-4"
          isOpen={dropdownOpen}
          toggle={() => {
            setOpenDropdownOpen(!dropdownOpen);
          }}
        >
          <DropdownToggle caret color="primary">
            {userName}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Opções</DropdownItem>
            {userRole == "admin" && (
              <DropdownItem
                onClick={() => {
                  history.push("/empresas");
                }}
              >
                Empresas
              </DropdownItem>
            )}
            <DropdownItem onClick={onLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </Nav>
    </React.Fragment>
  );
};

export default DefaultHeader;
