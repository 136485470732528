const formatCompaniesOptions = (companiesInvolved) => {
  let companiesOptions = [{ value: "", label: "Todas" }];
  Object.values(companiesInvolved).forEach((product) => {
    companiesOptions.push({ value: product.id, label: product.name });
  }, []);
  return companiesOptions;
};

const formatVoltagesInvolved = (voltagesInvolved) => {
  let voltagesOptions = [{ value: "", label: "Todas" }];
  Object.values(voltagesInvolved).forEach((voltage) => {
    voltagesOptions.push({ value: voltage?.value, label: voltage?.label });
  }, []);
  return voltagesOptions;
};

const formatProductsOptions = (productsInvolved, brand) => {
  let productsOptions = [
    {
      value: "",
      label: "Todos",
    },
  ];

  Object.values(productsInvolved).forEach((product) => {
    if (!!brand) {
      if (product.brand.id === brand)
        productsOptions.push({
          value: product,
          label: `${product.name}, ${product.brand.name}, ${product.info} ${product.unitOfMeasurement.name}`,
        });
    } else {
      productsOptions.push({
        value: product,
        label: `${product.name}, ${product.brand.name}, ${product.info} ${product.unitOfMeasurement.name}`,
      });
    }
  });
  return productsOptions;
};

const formatBrandsOptions = (products, company) => {
  const brandsIds = [];
  let brandsOptions = [
    {
      value: "",
      label: "Todas",
    },
  ];
  Object.values(products).forEach((product) => {
    if (!!company) {
      brandsIds.push(product.brand.id);
      brandsOptions.push({
        value: product.brand.id,
        label: product.brand.name,
      });
    } else {
      brandsOptions.push({
        value: product.brand.id,
        label: product.brand.name,
      });
    }
  });

  return brandsOptions;
};

const formatStatusOptions = () => {
  let statusOptions = [
    {
      value: 4,
      label: "Todos",
    },
    {
      value: 0,
      label: "Pendente",
    },
    {
      value: 1,
      label: "Parcial",
    },
    {
      value: 3,
      label: "Em trânsito",
    },
    {
      value: 2,
      label: "Concluido",
    },
  ];
  return statusOptions;
};

const formatPawnsOptions = (newPawn, product) => {
  let pawnsOptions = [{ value: "", label: "Nenhum" }];
  Object.values(newPawn).forEach((add) => {
    pawnsOptions.push({
      value: add.id,
      label: `
      Empenho: ${add.number} | 
      Número: ${add.customPawnNumber} | 
      Produto: ${product}
      `,
    });
  }, []);
  return pawnsOptions;
};

export {
  formatCompaniesOptions,
  formatProductsOptions,
  formatVoltagesInvolved,
  formatBrandsOptions,
  formatPawnsOptions,
  formatStatusOptions,
};
