import React, { useState } from "react";
import { Col, Row, Input, Table } from "reactstrap";
import { money } from "utils/mask";
import idx from "idx";
import { voltageOptions } from "utils/general";
import "./styles.css";
import { useSelector } from "react-redux";

const ItemList = ({
  isEdit,
  items,
  relatedProducts,
  setPawnItemsQuantity,
  setVoltageValue,
  values,
  isQuickView,
}) => {
  const [optionValue, setOpionValue] = useState("");

  const modalOpen = useSelector((state) => state.modal.modalOpen);

  const formatItems = () => {
    let formattedItems = [];
    if (items)
      for (let [key, value] of Object.entries(items)) {
        formattedItems.push(value);
      }
    return formattedItems;
  };

  const handleAddVoltageToProduct = (id, voltage) => {
    let hasNumberVoltage = /\d/.test(voltage)
      ? voltage.replace(/\D/g, "")
      : voltage.toLowerCase();
    values.auction.data.items[id].voltage = {
      value: hasNumberVoltage,
      label: voltage,
    };
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      let selected;
      try {
        selected = values.auction.data.items[row.id].voltage.label;
      } catch (error) {
        selected = "Indefinido";
      }
      const rowData = (
        <tr>
          <td title="Modelo">
            {idx(relatedProducts, (_) => _[row.product.id].name)}
          </td>
          <td title="Marca">
            {idx(relatedProducts, (_) => _[row.product.id].brand.name)}
          </td>
          <td title="Capacidade">
            {`${idx(relatedProducts, (_) => _[row.product.id].info)} ${idx(
              relatedProducts,
              (_) => _[row.product.id].unitOfMeasurement.name
            )}`}
          </td>
          <td title="Instalado?">{row.installed.label}</td>
          <td title="Voltagem">
            <select
              className="voltage-selector"
              name="voltage"
              disabled={modalOpen}
              onChange={(e) => {
                handleAddVoltageToProduct(row.id, e.target.value);
                setVoltageValue(row.id, e.target.value);
              }}
            >
              {voltageOptions.map((volts) => (
                <option value={volts.label} selected={volts.label === selected}>
                  {volts.label === "Indefinido" ? "Selecione" : volts.label}
                </option>
              ))}
            </select>
          </td>
          <td title="Quantidade já empenhada">
            {idx(values.previousPawnItems, (_) => _[row.id])
              ? values.previousPawnItems[row.id]
              : 0}
          </td>
          <td title="Quantidade total no pregão">{row.totalUnits}</td>
          <td title="Valor unitário">{money(row.unitPrice)}</td>
          <td title="Quantidade neste empenho">
            {isQuickView ? (
              <span>{values.pawnItemsQuantity[row.id]}</span>
            ) : (
              <Input
                type="number"
                min={0}
                style={{ maxWidth: "90px" }}
                value={values.pawnItemsQuantity[row.id]}
                onChange={(evt) => {
                  setPawnItemsQuantity(row.id, parseInt(evt.target.value));
                }}
              />
            )}
          </td>
        </tr>
      );
      !isQuickView && rows.push(rowData);
      isQuickView && !!values.pawnItemsQuantity[row.id] && rows.push(rowData);
    });
    return rows;
  };

  return (
    <div className="animated">
      <Row className="mt-4">
        <Col>
          <h3>Itens do Empenho</h3>
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Capacidade</th>
            <th>Instalado?</th>
            <th>Voltagem</th>
            <th>Qtd. já empenhada</th>
            <th>Qtd. total no pregão</th>
            <th>Valor unitário</th>
            <th>Qtd. neste empenho</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
    </div>
  );
};

export default ItemList;
