import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import PLList from "./PLList/PLList";
import { notify } from "services";
import dispatch, { EditActions, EditButtonActions } from "actions";
import firebase from "firebase";
import PlDeliveryData from "./PLList/DisplayPLDelivery/PLDeliveryData";
import { useSelector } from "react-redux";


interface ObservationDTO {
  observationsTitle: string;
  observations: string;
  id: number | string;
  index: number;
}

interface PawnDTO {
  id: string;
  deliveryStatus: number;
  oldDeliveryValue?: number;
}

const PackingListForm = (props: any) => {
  const { formik, createObservation, pawnsInPL } = props;
  const [loadListing, setLoadListing] = useState(false);
  const [typeOfButtonPrint, setTypeOfButtonPrint] = useState("resumido");
  const [newPawns, setNewPawns] = useState<PawnDTO[]>([]);
  const [total, setTotal] = useState({
    totalWeight: 0,
    totalCubedWeight: 0,
    totalVolume: 0,
    totalCubage: 0,
  });
  const db = firebase.firestore();
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = formik;

  const objectTotal = {
    totalWeight: 0,
    totalCubedWeight: 0,
    totalVolume: 0,
    totalCubage: 0,
  };

  const isEdit = useSelector((state: any) => state.editbutton.isEdit);

  useEffect(() => {
    if (
      values &&
      (!values.listing || values.listing.length === 0) &&
      !loadListing
    ) {
      let listing: any = [];
      let id = 0;
      if (values.packingListArray)
        values.packingListArray.map((item: any, index: any) => {
          listing = [
            ...listing,
            { type: "packingListArray", id: id + 1, pos: index },
          ];
          id += 1;
        });
      if (values.observationsListArray)
        values.observationsListArray.map((item: any, index: any) => {
          listing = [
            ...listing,
            { type: "observationsListArray", id: id, pos: index },
          ];
          id += 1;
        });
      setFieldValue("listing", listing);
      setLoadListing(true);
    }
  }, [values]);

  const updateListing = (newList: any) => {
    dispatch(EditActions.setWarning, { warning: true });
    setFieldValue("listing", newList);
  };

  const updatePackingList = (newPackingListArray: any) => {
    dispatch(EditActions.setWarning, { warning: true });
    setFieldValue("packingListArray", newPackingListArray);
  };

  const updateObservationsListArray = (newObservationsListArray: any) => {
    dispatch(EditActions.setWarning, { warning: true });
    setFieldValue("observationsListArray", newObservationsListArray);
  };

  const savePackingList = async (packingList: any, index?: number) => {
    let n = Math.max.apply(
      Math,
      values.listing.map((o: any) => o.id)
    );
    n = n === Infinity || n === -Infinity ? 0 : n;

    let listing = !isEdit ? [
      ...values.listing,
      {
        type: "packingListArray",
        id: n + 1,
        pos: values.packingListArray.length,
      },
    ] : [...values.listing];

    setFieldValue("listing", listing);
    if (typeof index === "number") {
      let newPackingListArray = [...values.packingListArray];
      await packingList.pawn.get().then((pawnSnap: any) => {
        const data = pawnSnap.data();
        data["oldDeliveryStatus"] = data.deliveryStatus;
        data["deliveryStatus"] = 3;
        setNewPawns([...newPawns, data]);
        newPackingListArray[index] = {
          address: packingList.address,
          city: packingList.city,
          id: packingList.id,
          observations: packingList.observations,
          packingListItemsQuantity: packingList.packingListItemsQuantity,
          pawn: data,
          state: packingList.state,
        };
      });
      setFieldValue("packingListArray", newPackingListArray);
      // return;
    } else {
      let data;
      try {
        await packingList.pawn.get().then((pawnSnap: any) => {
          data = pawnSnap.data();
        });
      } catch (e) {
        data = packingList.pawn;
      }

      data["oldDeliveryStatus"] = data.deliveryStatus;
      data["deliveryStatus"] = 3;
      setNewPawns([...newPawns, data]);

      setFieldValue("packingListArray", [
        ...values.packingListArray,
        {
          address: packingList.address,
          city: packingList.city,
          id: packingList.id,
          observations: packingList.observations,
          packingListItemsQuantity: packingList.packingListItemsQuantity,
          pawn: data,
          state: packingList.state,
        },
      ]);
    }
  };

  const deletePackingList = (index: number) => {
    if (!values.packingListArray[index].status) {
      let newPackingListArray = [...values.packingListArray];

      try {
        const pawn = newPackingListArray[index].pawn;
        const status =
          pawn.oldDeliveryStatus >= 0
            ? pawn.oldDeliveryStatus
            : pawn.deliveryStatus;
        const indexOfExcludedPawn = newPawns.findIndex(
          (pawns: PawnDTO) => pawns.id === pawn.id
        );
        if (indexOfExcludedPawn >= 0) {
          const editedPawnIndex = (newPawns[indexOfExcludedPawn][
            "deliveryStatus"
          ] = status);
          setNewPawns(editedPawnIndex);
        } else {
          pawn["deliveryStatus"] = status;
          setNewPawns([...newPawns, pawn]);
        }
      } catch (_) {
        console.log("Erro: " + _);
      }

      newPackingListArray.splice(index, 1);

      setFieldValue("packingListArray", newPackingListArray);

      let newListing: any[] = [];
      values.listing.map((item: any) => {
        if (!(item.type === "packingListArray" && item.pos === index)) {
          newListing = [
            ...newListing,
            {
              type: item.type,
              id: item.id,
              pos:
                item.pos > index && item.type === "packingListArray"
                  ? item.pos - 1
                  : item.pos,
            },
          ];
        }
      });
      setFieldValue("listing", newListing);
    } else {
      notify({
        description: "Não é possível excluir um romaneio já entregue.",
        type: "danger",
      });
    }
  };

  const saveObservationList = async (
    observation: ObservationDTO,
    index?: number
  ) => {
    let n = Math.max.apply(
      Math,
      values.listing.map(function (o: any) {
        return o.id;
      })
    );
    n = n === Infinity || n === -Infinity ? 0 : n;


    let listing = !isEdit ? [
      ...values.listing,
      {
        type: "observationsListArray",
        id: n + 1,
        pos: values.observationsListArray.length,
      },
    ] : [...values.listing];

    if (values.observationsListArray !== []) {
      let newOrderArray = [...values.observationsListArray];

      if (typeof index === "number") {
        newOrderArray[index] = observation;
        setFieldValue("observationsListArray", newOrderArray);
        setFieldValue("listing", listing);
        return;
      }

      newOrderArray.push(observation);
      setFieldValue("observationsListArray", newOrderArray);
      setFieldValue("listing", listing);
      return;
    }
    setFieldValue("observationsListArray", [observation]);
    setFieldValue("listing", listing);
    return;
  };

  const deleteObservationList = (index: number) => {
    let newOrderArray = [...values.observationsListArray];
    newOrderArray.splice(index, 1);
    setFieldValue("observationsListArray", newOrderArray);

    // let newListing = [...values.listing.filter((item: any) => !(item.type === "observationsListArray" && item.pos === index))]
    let newListing: any[] = [];
    values.listing.map((item: any) => {
      if (!(item.type === "observationsListArray" && item.pos === index)) {
        newListing = [
          ...newListing,
          {
            type: item.type,
            id: item.id,
            pos:
              item.pos > index && item.type === "observationsListArray"
                ? item.pos - 1
                : item.pos,
          },
        ];
      }
    });
    setFieldValue("listing", newListing);
  };

  const inTransitStatus = (pawns: PawnDTO[]): void => {
    if (pawns.length > 0) {
      pawns.map(
        async (pawn): Promise<void> => {
          await db
            .collection("pawns")
            .doc(pawn.id)
            .set(pawn);
        }
      );
    }
  };

  const save = () => {
    setFieldValue("toPrint", false);
    dispatch(EditActions.setWarning, { warning: false });
    inTransitStatus(newPawns);
    handleSubmit();
  };

  const print = (typeOfPrint: string) => {
    if (typeOfPrint === "padrao") {
      sessionStorage.setItem("@ecoblu/typeOfButton", typeOfPrint);
      setTypeOfButtonPrint(typeOfPrint);
    } else {
      sessionStorage.setItem("@ecoblu/typeOfButton", typeOfPrint);
      setTypeOfButtonPrint(typeOfPrint);
    }
    setFieldValue("toPrint", true);
    dispatch(EditActions.setWarning, { warning: false });
    handleSubmit();
  };

  return (
    <Fragment>
      <div className="d-none d-print-block">
        <Row>
          <Col className="text-center">
            <h3>{values.driver}</h3>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h3>Romaneio {values.number}</h3>
          </Col>
        </Row>
      </div>
      <form>
        <Row className="d-print-none">
          <Col md="6" className="mb-3">
            <label htmlFor="driver" style={{ display: "block" }}>
              Motorista / Placa
            </label>
            <input
              id="driver"
              placeholder="Nome"
              type="text"
              value={values.driver}
              autoFocus
              onChange={(evt) => {
                if (values.driver || evt.target.value)
                  dispatch(EditActions.setWarning, { warning: true });
                handleChange(evt);
              }}
              onBlur={handleBlur}
            />
            {errors.driver && touched.driver && (
              <div className="error-div">{errors.driver}</div>
            )}
            <h1>{values?.sumTotalProducts}</h1>
          </Col>
        </Row>

        {values.listing && (
          <PLList
            typeOfButtonPrint={typeOfButtonPrint}
            listing={values.listing}
            packingListArray={values.packingListArray}
            observationsListArray={values.observationsListArray}
            savePackingList={savePackingList}
            deletePackingList={deletePackingList}
            updatePackingList={updatePackingList}
            updateListing={updateListing}
            updateObservationsListArray={updateObservationsListArray}
            pawnsInPL={pawnsInPL}
            saveOrderList={saveObservationList}
            deleteOrderList={deleteObservationList}
            setTotal={setTotal}
            objectTotal={objectTotal}
          />
        )}
        <PlDeliveryData total={total} />
        <Row className="d-print-none">
          <Col>
            <div>
              <Button
                color="info"
                disabled={isSubmitting}
                onClick={() => print("padrao")}
              >
                Imprimir
              </Button>
              <Button
                id="secondPrintButton"
                color="info"
                disabled={isSubmitting}
                onClick={() => print("resumido")}
              >
                Imprimir 2
              </Button>
            </div>
          </Col>

          <Col className="text-right">
            <Button color="success" disabled={isSubmitting} onClick={save}>
              Salvar
            </Button>
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

export default PackingListForm;
