import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { CustomReactSelect, ToggleSwitchWithFourItems } from "components";

import ptBrPhrases from "utils/general/ptBrPhrases";
import { DateRangePicker } from "react-dates";
import { states, cities } from "utils/location";

interface StateDTO {
  name: string;
  initials: string;
}

interface LocationProps {
  value: string;
  label: string;
}

const ColCustomReactSelect = (props: any) => {
  const { filters, col, label, field, setFilterField, options } = props;
  return (
    <Col md={col} className="mb-3">
      <CustomReactSelect
        label={label}
        field={field}
        value={filters[field] ? filters[field] : ""}
        onChange={(field: any, value: any) => {
          setFilterField(field, value);
        }}
        options={field === 'city' ?
          options.sort((a: any, b: any) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
          : options}
      />
    </Col>
  );
};

const PawnListFilters = (props: any) => {
  const {
    pawns,
    filters,
    setFilterField,
    switchOptions,
    optionSelected,
    handleChangeSelected,
    clearFilters,
    receivedAtDates,
    setReceivedAtDates,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [focusedInput, setFocusedInput] = useState<any>();

  const getNestedObject = (nestedObj: any, pathArr: any) => {
    return pathArr.reduce(
      (obj: any, key: any) =>
        obj && obj[key] !== "undefined" ? obj[key] : undefined,
      nestedObj
    );
  };

  const createUniqueItemsArray = useCallback((path: any, type?: any) => {
    let arr: any = [];
    let arrOptions: any = [];
    pawns.map((pawn: any) => {
      let item = getNestedObject(pawn, path);
      if (arr.indexOf(item) < 0) {
        arr.push(item);
        switch (type) {
          case "deliveryStatus":
            arrOptions.push({
              value: item,
              label: ["Pendente/Parcial", "Concluído", "Todos"][item],
            });
            break;

          default:
            arrOptions.push({ value: item, label: item });
            break;
        }
      }
    });
    return arrOptions;
  }, [pawns]);

  const [numberOptions, setNumberOptions] = useState<any[]>([]);
  const [customPawnNumberOptions, setCustomPawnNumberOptions] = useState<any[]>(
    []
  );
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [auctionOptions, setAuctionOptions] = useState<any[]>([]);
  const [governmentAgencyOptions, setGovernmentAgencyOptions] = useState<any[]>(
    []
  );
  const [statesOptions, setStatesOptions] = useState<LocationProps[]>([]);
  const [cityOptions, setCityOptions] = useState<LocationProps[]>([]);
  const [deliveryStatusOptions, setDeliveryStatusOptions] = useState<any[]>([]);

  const [oldStateValue, setOldStateValue] = useState<string>('');

  useEffect(() => {
    if (isOpen && pawns.length > 0) {
      setNumberOptions(createUniqueItemsArray(["number"]));
      setCustomPawnNumberOptions(createUniqueItemsArray(["customPawnNumber"]));
      setCompanyOptions(createUniqueItemsArray(["auction", "company", "name"]));
      setAuctionOptions(createUniqueItemsArray(["auction", "number"]));
      setGovernmentAgencyOptions(
        createUniqueItemsArray(["auction", "governmentAgency", "name"])
      );

      let arrayOfStates: LocationProps[] = states
        .map((state: StateDTO) => ({ value: state.initials, label: state.initials }))

      setStatesOptions(arrayOfStates)

      setDeliveryStatusOptions(
        createUniqueItemsArray(["deliveryStatus"], "deliveryStatus")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, pawns]);

  useEffect(() => {

    if (filters['state'] !== undefined) {

      if (oldStateValue !== filters['state'].value && oldStateValue !== '') {
        delete filters['city'];
      }

      const stateInputValue = filters['state'];
      const filtredCompanyCity = cities({ initials: stateInputValue.value });

      let arrayOfCities: LocationProps[] = filtredCompanyCity
        .map((city: string) => ({ value: city, label: city }))

      setCityOptions(arrayOfCities)
      setOldStateValue(filters['state'].value)
    }

  }, [oldStateValue, filters])


  return (
    <Fragment>
      <Row>
        <Col sm={5}>
          <ToggleSwitchWithFourItems
            values={switchOptions}
            selected={optionSelected}
            handleChange={(option: any) => handleChangeSelected(option)}
          />
        </Col>
        <Col className="text-left">
          <Button
            color="link"
            onClick={() => {
              toggle();
            }}
            className="mb-2"
          >
            {isOpen ? "Esconder filtro avançado" : "Exibir filtro avançado"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse isOpen={isOpen}>
            <Row>
              <ColCustomReactSelect
                filters={filters}
                col="4"
                label="Pedido:"
                field="number"
                setFilterField={setFilterField}
                options={numberOptions}
              />
              <ColCustomReactSelect
                filters={filters}
                col="4"
                label="Número:"
                field="customPawnNumber"
                setFilterField={setFilterField}
                options={customPawnNumberOptions}
              />
              <ColCustomReactSelect
                filters={filters}
                col="4"
                label="Empresa:"
                field="company"
                setFilterField={setFilterField}
                options={companyOptions}
              />
            </Row>
            <Row>
              <ColCustomReactSelect
                filters={filters}
                col="4"
                label="Pregão:"
                field="auction"
                setFilterField={setFilterField}
                options={auctionOptions}
              />
              <ColCustomReactSelect
                filters={filters}
                col="3"
                label="Órgão:"
                field="governmentAgency"
                setFilterField={setFilterField}
                options={governmentAgencyOptions}
              />
              <ColCustomReactSelect
                filters={filters}
                col="2"
                label="Estado:"
                field="state"
                value={filters['state'] ? filters['state'].value : ''}
                setFilterField={setFilterField}
                options={statesOptions}
              />
              <ColCustomReactSelect
                filters={filters}
                col="3"
                label="Cidade:"
                field="city"
                value={filters['city'] ? filters['city'].value : ''}
                setFilterField={setFilterField}
                options={cityOptions}
              />
            </Row>
            <Row>
              <ColCustomReactSelect
                filters={filters}
                col="6"
                label="Situação da entrega:"
                field="deliveryStatus"
                value={filters['deliveryStatus'] ? filters['deliveryStatus'].value : ''}
                setFilterField={setFilterField}
                options={deliveryStatusOptions}
              />
              <Col xs={6}>
                <label className="d-block">Recebido em:</label>
                <DateRangePicker
                  startDate={receivedAtDates.startDate}
                  startDateId="startDate"
                  endDate={receivedAtDates.endDate}
                  endDateId="endDate"
                  onDatesChange={({ startDate, endDate }) => {
                    setReceivedAtDates({ startDate, endDate });
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  orientation={"vertical"}
                  openDirection={"down"}
                  displayFormat={"DD-MM-YYYY"}
                  isOutsideRange={() => false}
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  phrases={ptBrPhrases}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button color="link" onClick={clearFilters} className="mb-2">
                  Limpar filtros
                </Button>
              </Col>
            </Row>
          </Collapse>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PawnListFilters;
