import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetGovernmentAgencies = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [governmentAgencies, setGovernmentAgencies] = useState([] as any);
  const [governmentAgenciesCnpj, setGovernmentAgenciesCnpj] = useState(
    [] as any
  );
  const [loadingGovernmentAgencies, setLoadingGovernmentAgencies] = useState(
    false
  );

  useEffect(() => {
    setLoadingGovernmentAgencies(true);
    db.collection("governmentAgencies")
      .get()
      .then((querySnapshot) => {
        const governmentAgencies = querySnapshot.docs.map((doc) => doc.data());
        setGovernmentAgencies(governmentAgencies);
        setGovernmentAgenciesCnpj(
          governmentAgencies.map((governmentAgency) => governmentAgency.cnpj)
        );
        setLoadingGovernmentAgencies(false);
      })
      .catch((error) => {
        setLoadingGovernmentAgencies(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return {
    loadingGovernmentAgencies,
    governmentAgencies,
    governmentAgenciesCnpj,
  };
};

export default useGetGovernmentAgencies;
