import React from "react";
import { CustomReactSelect } from "components";
import { Col, Row } from "reactstrap";

const Filters = (props: any) => {
  const {
    company,
    setCompany,
    companiesOptions,
    brand,
    setBrand,
    brandsOptions,
    product,
    setProduct,
    productsOptions,
    voltage,
    setVoltage,
    voltagesOptions,
    installed,
    setInstalled,
    installedOptions,
    status,
    setStatus,
    statusOptions,
  } = props;
  return (
    <div className="mt-4">
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Empresa"
            field="company"
            value={company}
            onChange={(field: any, newValue: any) => {
              setCompany(newValue);
            }}
            options={companiesOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Marca"
            field="brand"
            value={brand}
            onChange={(field: any, newValue: any) => {
              setBrand(newValue);
            }}
            options={brandsOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Produto"
            field="product"
            value={product}
            onChange={(field: any, newValue: any) => {
              setProduct(newValue);
            }}
            options={productsOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Voltagem"
            field="voltage"
            value={voltage}
            onChange={(field: any, newValue: any) => {
              setVoltage(newValue);
            }}
            options={voltagesOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Instalado?"
            field="installed"
            value={installed}
            onChange={(field: any, newValue: any) => {
              setInstalled(newValue);
            }}
            options={installedOptions}
          />
        </Col>
        <Col md="6" className="mb-3">
          <CustomReactSelect
            label="Situação de Entrega"
            field="status"
            value={status}
            onChange={(field: any, newValue: any) => {
              setStatus(newValue);
            }}
            options={statusOptions}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
