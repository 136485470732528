import React, { useState, useEffect } from "react";
import { Col, Row, Input, Table } from "reactstrap";
import { money } from "utils/mask";
import idx from "idx";

const ItemList = ({
  items,
  packingListArray,
  pawnData,
  relatedProducts,
  setPackingListItemsQuantity,
  values,
  pawnsInPL,
  originalItems,
}) => {
  const formatItems = () => {
    let formattedItems = [];
    if (items)
      for (let [key, value] of Object.entries(items)) {
        if (values.pawnData.data.pawnItemsQuantity[value.id] > 0)
          formattedItems.push(value);
      }
    return formattedItems;
  };

  const quantityInPl = (id) => {
    let quantity = idx(pawnsInPL, (_) => _[values.pawnData.data.id][id]) || 0;
    if (originalItems[id]) {
      // if (originalItems[id] && quantity >= originalItems[id]) {
      quantity -= originalItems[id];
    }
    let qtd = 0;
    for (const key of Object.keys(packingListArray)) {
      if (pawnData.data.number === packingListArray[key].pawn.number) {
        qtd += packingListArray[key].packingListItemsQuantity[id];
      }
    }

    return quantity;
  };

  const tableRows = () => {
    let data = formatItems();
    let rows = [];
    data.forEach((row) => {
      rows.push(
        <tr>
          <td title="Modelo">
            {idx(relatedProducts, (_) => _[row.product.id].name)}
          </td>
          <td title="Marca">
            {idx(relatedProducts, (_) => _[row.product.id].brand?.name)}
          </td>
          <td title="Capacidade">{`${idx(
            relatedProducts,
            (_) => _[row.product.id].info
          )} ${idx(
            relatedProducts,
            (_) => _[row.product.id].unitOfMeasurement?.name
          )}`}</td>
          <td title="Instalado?">{row?.installed?.label}</td>
          <td title="Voltagem">{row?.voltage?.label}</td>
          <td title="Quantidade entregue">
            {idx(values.previousPackingListItems, (_) => _[row.id]) || 0}
          </td>
          <td title="Quantidade em romaneio">{quantityInPl(row.id)}</td>
          <td title="Quantidade total no empenho">
            {values.pawnData?.data?.pawnItemsQuantity[row.id]}
          </td>
          <td title="Valor unitário">{money(row.unitPrice)}</td>
          <td title="Quantidade nesta entrega">
            <Input
              type="number"
              min={0}
              style={{ maxWidth: "90px" }}
              value={values?.packingListItemsQuantity[row.id]}
              onChange={(evt) => {
                setPackingListItemsQuantity(row.id, parseInt(evt.target.value));
              }}
            />
          </td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <div className="animated">
      <Row className="mt-4">
        <Col>
          <h3>Itens da Entrega</h3>
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Capacidade</th>
            <th>Instalado?</th>
            <th>Voltagem</th>
            <th>Qtd. já entregue</th>
            <th>Qtd. em romaneio</th>
            <th>Qtd. total no empenho</th>
            <th>Valor unitário</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </Table>
    </div>
  );
};

export default ItemList;
