import { useState, useEffect } from "react";
import firebase from "config/firebase";

const useGetUnitiesOfMeasurement = (props?: any) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [unitiesOfMeasurement, setUnitiesOfMeasurement] = useState([] as any);
  const [unitiesOfMeasurementNames, setUnitiesOfMeasurementNames] = useState(
    [] as any
  );
  const [
    loadingUnitiesOfMeasurement,
    setLoadingUnitiesOfMeasurement,
  ] = useState(false);

  useEffect(() => {
    setLoadingUnitiesOfMeasurement(true);
    db.collection("unitiesOfMeasurement")
      .get()
      .then((querySnapshot) => {
        const unitiesOfMeasurement = querySnapshot.docs.map((doc) =>
          doc.data()
        );
        setUnitiesOfMeasurement(unitiesOfMeasurement);
        setUnitiesOfMeasurementNames(
          unitiesOfMeasurement.map(
            (unitOfMeasurement) => unitOfMeasurement.name
          )
        );
        setLoadingUnitiesOfMeasurement(false);
      })
      .catch((error) => {
        setLoadingUnitiesOfMeasurement(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, [props]);

  return {
    loadingUnitiesOfMeasurement,
    unitiesOfMeasurement,
    unitiesOfMeasurementNames,
  };
};

export default useGetUnitiesOfMeasurement;
