import React, { Fragment } from "react";
import Select from "react-select";

interface CustomReactSelectInterface {
  field: string;
  label: string;
  onChange?: any;
  onBlur?: any;
  value: any;
  error?: any;
  touched?: any;
  options: any;
  isDisabled?: any;
  onAdd?: any;
  isQuickView?: boolean;
  autoFocus?: boolean;
  id?: string;
}

const CustomReactSelect = (props: CustomReactSelectInterface) => {
  const {
    field,
    label,
    onChange,
    onBlur,
    value,
    error,
    touched,
    options,
    isDisabled,
    onAdd,
    isQuickView,
    id,
    autoFocus = false,
  } = props;
  const handleChange = (newValue: any) => {
    onChange(field, newValue);
  };

  const handleBlur = () => {
    onBlur && onBlur(field, true);
  };

  return (
    <Fragment>
      <div className="d-flex">
        <label>{label}</label>
        {!!onAdd && (
          <i
            className="fa fa-plus-circle ml-auto"
            aria-hidden="true"
            style={{ color: "#4dbd74", fontSize: "20px", cursor: "pointer" }}
            onClick={onAdd}
          />
        )}
      </div>
      {isQuickView ? (
        <span>{value?.label}</span>
      ) : (
        <Fragment>
          <Select
            id={id}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            isDisabled={isDisabled}
            autoFocus={autoFocus}
          />
          {!!error && touched && <div className="error-div">{error}</div>}
        </Fragment>
      )}
    </Fragment>
  );
};

export default CustomReactSelect;
