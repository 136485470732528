import { useState, useEffect } from "react";
import firebase from "config/firebase";
import { notify } from "services";

const useGetDeliveriesByPawn = (pawnId: string) => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);
  const [pawnDeliveries, setPawnDeliveries] = useState([] as any);

  useEffect(() => {
    setLoadingDeliveries(true);
    db.collection("deliveries")
      .where("pawn.id", "==", pawnId)
      .get()
      .then((querySnapshot) => {
        setPawnDeliveries(querySnapshot.docs.map((doc) => doc.data()));
        setLoadingDeliveries(false);
      })
      .catch((error) => {
        setLoadingDeliveries(false);
        console.log("erro: ", error);
        notify({
          description: "Não foi possivel acessar as entregas relacionadas.",
          type: "danger",
        });
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  }, []);

  return { loadingDeliveries, pawnDeliveries };
};

export default useGetDeliveriesByPawn;
