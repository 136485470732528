import { createReducer } from "typesafe-actions";
import { DashboardActions } from "actions";

const {
    saveDeliveries,
    saveUpdateAt,
    savePawns,
    savePackingList,
    saveTradingTypesValues,
} = DashboardActions;

const initialState = {
    deliveries: {},
    updateAt: undefined,
    pawns: undefined,
    packingLists: undefined,
    tradingTypesValues: undefined,
};

const reducer = createReducer(initialState)
.handleAction(saveDeliveries, (state, { payload }) => ({
    ...state,
    deliveries: payload.deliveries,
    updateAt: payload.date
}))
.handleAction(savePawns, (state, { payload }) => ({
    ...state,
    pawns: payload.pawns
}))
.handleAction(savePackingList, (state, { payload }) => ({
    ...state,
    packingLists: payload.packingLists
}))
.handleAction(saveTradingTypesValues, (state, { payload }) => ({
    ...state,
    tradingTypesValues: payload.tradingTypesValues
}));

export default reducer;
